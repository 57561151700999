import React, { Component } from "react";
import {
  classes,
  setState,
  animate,
  hasProperty,
  GetCDNImage,
  clearStyleProps,
} from "utils";
import { TweenMax } from "gsap";
import Store from "../../../store";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";

import { SidebarRow, SidebarOption } from "../components";

export default class Materials extends Component {
  state = {
    data: null,
    categories: [],
    currentCategory: null,
    activeOption: null,
    pos: 0,
  };

  constructor() {
    super(...arguments);
    this.binds = {
      selectCategory: this.selectCategory.bind(this),
      closeCategory: this.closeCategory.bind(this),
      setOption: this.setOption.bind(this),
      watchOptions: this.watchOptions.bind(this),
    };
  }

  async setOption(option) {
    //option.price = 0
    const activeOption = Store.action("addMaterialLayoutOption", {
      option: option,
      old_option: this.state.activeOption,
      partName: this.props.extraName,
    });

    if (activeOption) {
      this.props.onMaterialCategory(this.state.currentCategory);
    } else {
      this.props.onMaterialCategory(null);
    }

    await this.setState({
      activeOption,
    });
    const el = document.querySelector(".conf_sidebar_option.active");
    if (el) {
      el.focus();
    }
  }

  componentDidMount() {
    const categories = Object.keys(this.props.data);
    this.setState({
      categories,
      currentCategory: categories.length === 1 ? categories[0] : null,
    });

    let selected = Store.action("getSelectedOptionFor", {
      partName: this.props.extraName,
      is_design: false,
    });

    this.setState({
      activeOption: selected,
    });

    Store.watch("options", this.binds.watchOptions);
  }

  componentWillUnmount() {
    Store.watchOff("options", this.binds.watchOptions);
  }

  watchOptions(options) {
    for (let option of options) {
      if (option === this.state.activeOption) return;
    }
    this.setState({
      activeOption: null,
    });
  }

  async componentWillReceiveProps(newprops) {
    if (newprops.extraName === this.props.extraName) return;

    let selected = Store.action("getSelectedOptionFor", {
      partName: newprops.extraName,
      is_design: false,
    });

    const categories = Object.keys(newprops.data);
    this.setState({
      categories,
      currentCategory: categories[0],
      activeOption: selected,
    });

    clearStyleProps(this.animator, "all");
  }

  async selectCategory(category) {
    await setState(this, {
      currentCategory: category,
    });
    const el = document.querySelector(".conf_sidebar_scroll");
    el.classList.add("hidden");
    const ell = document.querySelector(".conf_sidebar_inner_scroll");
    ell.classList.remove("hidden");
    this.props.onOpenInnerSection();
    this.refs.back.classList.remove("hidden");
    this.refs.back.focus();
    animate(this.animator, 0, {
      x: "-100%",
    });
  }

  async closeCategory() {
    this.props.onCloseInnerSection();
    const el = document.querySelector(".conf_sidebar_scroll");
    el.classList.remove("hidden");
    const ell = document.querySelector(".conf_sidebar_inner_scroll");
    ell.classList.add("hidden");
    this.refs.back.classList.add("hidden");
    await animate(this.animator, 0, {
      x: "0%",
    });
    const i = this.state.categories.indexOf(this.state.currentCategory);

    this.setState({
      currentCategory: null,
    });
    const btn = document.querySelectorAll("button.conf_sidebar_row")[i].focus();
  }

  getInnerContent() {
    return _groupBy(this.props.data[this.state.currentCategory], "category");
  }

  async switchTab(e) {
    const l = this.props.data[this.state.currentCategory].length;
    const els = Array.from(document.querySelectorAll(".conf_sidebar_option"));
    let pos = this.state.pos;
    switch (e.key) {
      case "ArrowLeft":
      case "ArrowUp":
        if (pos == 0) {
          pos = l - 1;
        } else {
          pos = pos - 1;
        }
        await this.setState({
          pos: pos,
        });

        els[pos].click();
        els[pos].focus();
        break;
      case "ArrowRight":
      case "ArrowDown":
        if (pos == l - 1) {
          pos = 0;
        } else {
          pos = pos + 1;
        }
        await this.setState({
          pos: pos,
        });
        els[pos].click();
        els[pos].focus();
        break;
    }
  }

  render() {
    return (
      <div
        className="conf_sidebar_tab_content"
        role="tabpanel"
        aria-labelledby={
          this.state.categories.length === 1 ? "btn_screws" : "tab_btn_0"
        }
        tabindex="0"
      >
        <div
          className="conf_sidebar_content_animator"
          ref={(el) => (this.animator = el)}
        >
          {this.state.categories.length === 1 ? (
            <div className="conf_sidebar_inner_scroll">
              {this.props.extraLabel.includes("screws") ? (
                <div>
                  <p className="roman">{this.props.translations.screws_1}</p>
                  <br />
                  <p className="roman">{this.props.translations.screws_2}</p>
                </div>
              ) : (
                <div className="conf_inner_section">
                  <p
                    id="custom-legend"
                    className="h11 light regular conf-m-bottom text-left"
                  >
                    {this.props.translations.mat_choose_color}
                    {/hexa/.test(window.location.href) && (
                      <span className="h11 block">
                        <br />
                        
                        <span className={"bold" + (this.state.categories.length === 1 ? "p-right block" : "")}>{this.props.translations.mat_disclaim}</span><br /><br />{this.props.translations.mat_text}
                        <br /><br />
                      </span>
                    )}
                    
                     
                    
                  </p>
                  <fieldset
                    role="radiogroup"
                    aria-labelledby="custom-legend"
                    onKeyDown={this.switchTab.bind(this)}
                  >
                    {this.props.data[this.state.currentCategory].map((v, i) => (
                      <SidebarOption
                        name={v.title}
                        key={v.id.toString() + i}
                        img={v.img}
                        focus={i == this.state.pos}
                        active={v === this.state.activeOption}
                        onClick={() => this.binds.setOption(v)}
                      />
                    ))}
                  </fieldset>
                </div>
              )}
            </div>
          ) : (
            <div className="conf_sidebar_scroll">
              <h2 className="regular h11 light conf-m-bottom">
                {this.props.translations.mat_choose_custom}
              </h2>
              {/kit/.test(window.location.href) && (
                      <span className="h11 block padding-right">
                        <br />
                        
                        <span className="bold">{this.props.translations.mat_text_custom_shield}</span>
                        <br /><br />
                      </span>
                    )}
             
              <ul>
                {this.state.categories.map((v, i) => (
                  <li key={i}>
                    <SidebarRow
                      controls={v}
                      expanded={this.state.currentCategory}
                      currentColor={this.props.currentColor}
                      label={this.props.translations[v]}
                      name={v}
                      key={v + i}
                      onClick={this.binds.selectCategory}
                    />
                  </li>
                ))}
              </ul>
              <p className="only-desktop regular h11 conf-m-top p-right">
                {this.props.translations.color_printing_body}
                <br />
                <br />
                {this.props.translations.laser_engraved_body}
                <br />
                <br />
                {this.state.categories.indexOf("3d_printing") > -1
                  ? this.props.translations["3d_printing_body"]
                  : ""}
              </p>
            </div>
          )}
          {this.state.categories.length > 1 && (
            <div className="conf_sidebar_innersection" id={this.state.currentCategory}>
              <button
                ref="back"
                id="mback"
                type="button"
                className="conf_sidebar_inner_head h11 c-light regular hidden"
                onClick={this.binds.closeCategory}
              >
                <img
                  src={"/arrow.svg"}
                  className="conf_sidebar_inner_head_arrow"
                  alt="Go back"
                />
                {this.props.translations[this.state.currentCategory]}
              </button>

              <div className="conf_sidebar_inner_scroll">
                <div className="conf_sidebar_intro">
                  <button
                    className="conf_sidebar_inner_head_mobile h11 bold c-dark regular upcase"
                    onClick={this.binds.closeCategory}
                  >
                    <img
                      src={"/arrow_b.svg"}
                      className="conf_sidebar_inner_head_arrow"
                      alt="Go back"
                    />
                    BACK
                  </button>

                  <p
                    className="h11 light regular conf-m-bottom"
                    id="custom-legend-mat"
                  >
                    {this.props.translations.mat_choose_shield}
                    <span className="only-mobile">
                      {
                        this.props.translations[
                          this.state.currentCategory + "_body"
                        ]
                      }
                    </span>
                  </p>
                </div>
                <div className="conf_inner_section">
                  <fieldset
                    ref="fset"
                    role="radiogroup"
                    onKeyDown={this.switchTab.bind(this)}
                    aria-labelledby="custom-legend-mat"
                  >
                    <legend>{this.props.translations.mat_choose_color}</legend>
                    {this.state.currentCategory !== null &&
                      _map(this.getInnerContent(), (content, section) =>
                        content.map((v, i) => (
                          <SidebarOption
                            name={v.title}
                            key={v.id.toString() + i}
                            img={v.img}
                            focus={i == this.state.pos}
                            active={
                              this.state.activeOption &&
                              v.id === this.state.activeOption.id
                            }
                            hidden={
                              window.location.href.includes("anc") &&
                              v.title == "Matte Black"
                            }
                            onClick={() => this.binds.setOption(v)}
                          />
                        ))
                      )}
                  </fieldset>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
