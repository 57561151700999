import _remove from 'lodash/remove'

export function addMaterialLayoutOption( store, { old_option, option, partName }) {
	const options = store.get('options')
	let activeOption = null

	const monograms = store.get('monogramText')
	monograms[partName] = [null,null]
	
	const customDesigns = store.get('designCustomFiles')
	if ( customDesigns[partName] ) {
		customDesigns[partName] = null
	}

	if ( ! old_option ) {

		options.push(option)
		activeOption = option

	} else {

		// Removing old option and its related designs
		_remove( options, v => {
			return v.component === partName && ( v.id === old_option.id || v.is_design )
		})

		if ( option !== old_option ) {
			activeOption = option
			options.push(option)
		} else {
			activeOption = null
		}
	}

	if ( activeOption ) {
		activeOption.component = partName
		activeOption.layout_type = "material"

		if ( activeOption.monogram_info ) {

			if ( activeOption.monogram_info.text_a_chars_limit > 0 )
				monograms[partName][0] = activeOption.monogram_info.text_a_placeholder
			
			if ( activeOption.monogram_info.text_b_chars_limit > 0 )
				monograms[partName][1] = activeOption.monogram_info.text_b_placeholder
		
		}
		
	}

	store.set('options', options)

	return activeOption
}