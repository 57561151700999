export default function PageLoad() {
    var dataLayerName = "dataLayer";
    const observer = new PerformanceObserver((list) => {
        list.getEntries().forEach((entry) => {
            let entity = {};
            entity = entry.toJSON();
            if (!entity) return;
            if (entity.timeOrigin === 0 || entity.loadEventStart === 0) return;
            var timingData = {
                page_load_time: entity.duration,
                page_download_time: entity.responseEnd - entity.responseStart,
                server_response_time:
                    entity.responseStart - entity.requestStart,
            };
            window[dataLayerName] &&
                window[dataLayerName].push({
                    event: "performance_timing",
                    timing: timingData,
                });
        });
    });

    observer.observe({ type: ["navigation"], buffered: true });
}
