import React, { Component } from 'react'
import { classes, setState, GetCDNImage } from 'utils'
import Box from './box'
import Scrollbar from 'smooth-scrollbar'

const Item = ({ img, name }) => (
	<li className="conf_box_item">
	
		<div className="conf_box_item_img noborder" style={{ backgroundImage: `url(${img})` }}></div>

		<div className="conf_box_item_cont h12 trajan regular">
			<span>{name}</span>
		</div>

	</li>
)

export default class WhatsIncluded extends Component {

	componentDidMount() {
		const scroll = Scrollbar.init(this.scroller, {
			damping: 0.14,
			continuousScrolling: true,
			alwaysShowTracks: true,
			renderByPixels: true,
		})
	}

	render() {
		return (
			<Box title={this.props.translations.cp_included} onClose={this.props.onClose} dark={true}
				 titleImg={GetCDNImage("conf/whats_included_light.svg")}>
		
				<div className="conf_accessories_scroll" ref={el => this.scroller = el}>
		
					<ul className="conf_accessories_flex">
		
						{
							this.props.data.map((option,i) => (
								<Item key={i}
							  		  img={option.img}
							  		  name={option.name} />
							))
						}
		
					</ul>
		
				</div>
		
			</Box>
		)
	}
}