import React, { Component } from 'react'
import { classes, parameterize } from 'utils'

export default ({ current, actionsEnabled = true, onColorClick, visible, colors }) => (
	<div className={classes("conf_color_bar", { visible })}>

		{ colors.map((color,i) => (

			<button key={color.toString() + i}
					aria-current={ current === i ? "true" : "false"}
					aria-label={"Choose color " + color.name}
					className={classes("conf_color_bar_btn " + parameterize(color.name), {
						current: current === i, disabled: !actionsEnabled })}
					onClick={() => onColorClick(i)}>

				

				{/*<span className="h13 regular c-mediumgray trajan conf_color_bar_name">{color.name}</span>*/}
			
			</button>
		))}
	
	</div>
)
