import { qs, qsa } from "../utils/lib";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class NewNavbar {
    constructor(el) {
        this.el = el
        this.nav = qs(".n_nav")
        this.text_links = qsa('.text-link_item', this.el)
        this.ann_bar = qs('.announcement_bar')
        this.dropdowns = qsa('.n_nav_navbar_menu_dropdown', this.el)
        this.smallDropdowns = qsa('.announcement_bar_right_button_dropdown', this.el)
        this.isHomepage = this.nav.classList.contains('pages-home')
        this.layer = this.nav.querySelector('.n_nav_layer')
        this.dropIsOpen = false
        this.active = undefined
        this.alt_link = document.querySelector('.js-alt-n')
        this.init()
    }
    init() {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 0) {
                if (!this.ann_bar.classList.contains('drop_open')) {
                    this.hideAnnouncementeBar()
                }
            } else {
                this.showAnnouncementeBar()
            }
            if (this.isHomepage) {
                this.functionOnScroll()
            } else {
                this.activeNavbar()
            }
        })
        this.functionOnScroll()
        this.layer.addEventListener('click', () => {
            this.closeDropdown(active)
            this.active = undefined
        })
        this.alt_link && this.alt_link.addEventListener('click', () => {
            if (typeof gtag !== "undefined") {
                gtag("event", "click", {
                  event_category: "button",
                  event_label: 'Alternative Navbar "View All Link"'
                });
              }
        })
        this.text_links.forEach((button, index) => {
            button.addEventListener('click', () => {

                this.activeLabel(index)
                
                if (this.dropdowns[index]) {
                    if (!this.dropdowns[index].classList.contains('open') && !this.dropdowns[index].classList.contains('none')) {
                        this.openDropdown(index)
                    } else {
                        this.closeDropdown(index)
                    }
                }
            })
        })
    }
    
    checkSmallDropIsOpen() {

    }
    functionOnScroll() {

        if (this.isHomepage) {
            if (window.scrollY > 0) {
                this.activeNavbar()
                this.labelsDark()
            } else {
                if (this.dropIsOpen) {
                    this.activeNavbar()
                } else {
                    this.nav && this.nav.classList.remove("active");
                    this.labelsWhite()
                }
            }
        } else {
            this.labelsDark()
        }

    }
    labelsWhite() {
        const icons = qsa('.n_nav_icon')
        gsap.to(icons, {
            color: "white",
            duration: .1,
        });
        this.text_links.forEach(text => {
            text.classList.remove('c-dark')
            text.classList.add('c-light')
        })   
    }
    labelsDark() {
        const icons = qsa('.n_nav_icon')
        gsap.to(icons, {
            color: "black",
            duration: .1,
        });
        this.text_links.forEach(text => {
            text.classList.remove('c-light')
            text.classList.add('c-dark')
        }) 
    }    
    activeNavbar() {
        
        this.nav && this.nav.classList.add("active");
    }
    openDropdown(index) {
        this.active = index
        this.activeNavbar()
        this.labelsDark()
        this.layer.classList.add('open')
        this.dropIsOpen = true
        this.dropdowns.forEach((dropdown,i) => {
            if (index == i) {
                dropdown.classList.add('open')
            } else {
                dropdown.classList.remove('open')
            }
        })
    }
    closeDropdown(index) {
        this.dropIsOpen = false
        this.layer.classList.remove('open')
        this.dropdowns[index].classList.remove('open')
        if (window.scrollY == 0 && this.isHomepage) {
            this.nav && this.nav.classList.remove("active");
            this.labelsWhite()
        } else {
            this.labelsDark()
            this.activeNavbar()
        }
        this.disableLabels()
        this.active = undefined
    }
    activeLabel(index) {
        this.text_links.forEach((l,i) => {
            if (i == index) {
                l.classList.add('active')
            } else {
                l.classList.remove('active')
            }
        })
    }
    disableLabels() {
        this.text_links.forEach((l,i) => {
            l.classList.remove('active')
        })
    }
    hideAnnouncementeBar() {
        this.el.classList.add('active')
    }
    showAnnouncementeBar() {
        this.el.classList.remove('active')
    }    

};
