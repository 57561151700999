import React from 'react'
const SearchEmpty = (props) => {
    return (
        <div className='col col-10'>
            <h2 className="c-light h4 cn-bd search_grid_inner_title">{props.sentences[0].replace('xxx',props.string)}</h2>
            <div className='row no_margin'>
                <p className='col col-auto c-gray h6'>{props.sentences[1]}</p>
            </div>
            <div className='row no_margin search_grid_inner_block'>
                {
                    props.products.map((p,i) => (
                        <div className="col col-4 col-mb-4 search_grid_inner_block_item" key={i}>
                            <a href={p.category_link} className="pr_card">
                                <div className="pr_card_w">
                                    <div className="pr_card_w_image relative">
                                        <div className="image_wrapper ratio-1x1">
                                            <img src={p.image} alt="" className="contain" />
                                        </div>
                                    </div>
                                    <div className="pr_card_w_body">
                                        <p className="cn-bd h6 text-center c-light capitalize">{p.category}</p>
                                    </div> 
                                </div>
                            </a>                
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
export default SearchEmpty