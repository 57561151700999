import { qs, qsa } from "../utils/lib"
const ProductBar = (wrapper) => {
    const product_bar = qs('.js-product-bar')
    const observer = new IntersectionObserver(handleIntersect);
    function handleIntersect(entries) {
        let prevRatio = 0
        entries.forEach((entry) => {
            if (entry.intersectionRatio > prevRatio) {
                product_bar && product_bar.classList.remove('visible')
            } else {
                product_bar && product_bar.classList.add('visible')
            }
        });
    }
      observer.observe(wrapper);
}
export default ProductBar