import React, { Component } from "react";
import {
    classes,
    animate,
    setState,
    fetchJSON,
    supportWebGL,
    GetCDNImage,
    showDisplaySign,
    IsIphoneX,
} from "utils";
import { Api } from "./api";
import Store from "./store";

import Scene from "./scene/index";

import SideBar from "./ui/sidebar/alt_sidebar";
import WebGLSorry from "./ui/webglsorry";
import ExtrasMobile from "./ui/extras_mobile";
import TopBar from "./ui/top_bar";
import BottomBar from "./ui/bottom_bar";
import MobileShipping from "./ui/mobile_shipping";
import Accessories from "./ui/accessories";
import WhatsIncluded from "./ui/whats_included";
import Loader from "./ui/loader";
import SwitchOS from "./ui/switch_os";
import ColorBar from "./ui/color_bar";
import HelperPopup from "./scene/helper";
import { NextColorMobile, PrevColorMobile } from "./ui/colors_mobile";

window.onhashchange = () => {
    window.location.reload();
};

// App main structure
export default class Configurator extends Component {
    state = {
        supportsWebGL: supportWebGL(),
        dataset: null,
        colors: [],
        activeExtra: 0,
        custom_parts: [],
        mobile_parts: [],
        customization_type: "",
        sidebarOpenOnMobile: false,
        mobileReviewOpen: false,
        accessoriesVisible: false,
        whatsIncludedVisible: false,
        switchOsVisible: false,
        loaded: false,
        sceneLoaded: false,
        colorBarHideTitle: false,
        isAddingToCart: false,
        isAddedToCart: false,
        cartError: false,
        dragVisible: true,
        t: JSON.parse(this.props.translations),
        ...Store.getProps([
            "custom_type",
            "currentModelColor",
            "colorBarVisible",
            "accessories",
            "price"
        ]),
        helperPopupVisible: false,
        helperVideos: this.props.helpvidoes,
        isWeeks: false,
        activeStep: 0,
    };

    constructor() {
        super(...arguments);
        this.onErrorBind = this.onError.bind(this);
    }

    setBindOnError() {
        const _that = this;
        const consoleError = console.error;
        console.error = function () {
            _that.onErrorBind(...arguments);
            consoleError(...arguments);
        };
    }

    componentDidMount() {
        //this.setBindOnError()
        //window.addEventListener('error', this.onErrorBind)
        showDisplaySign();

        document.body.classList.add("noscroll");
        document.documentElement.classList.add("noscroll");

        Store.linkState(this, [
            "currentModelColor",
            "custom_type",
            "colorBarVisible",
            "accessories",
            "price"
        ]);
        this.loadData();

        this.checkCartBind = this.checkCart.bind(this);

        Store.watch("currentModelColor", this.checkCartBind);
        Store.watch("custom_type", this.checkCartBind);
        Store.watch("accessories", this.checkCartBind);
        Store.watch("options", this.checkCartBind);
        Store.watch("monogramText", this.checkCartBind);

        this.resizeBodyBind = this.resizeBody.bind(this);
        this.resizeBody();
        window.addEventListener("resize", this.resizeBodyBind);
        this.importYTLibrary();
    }

    getIphoneXBottom() {
        if (navigator.userAgent.match("CriOS") && IsIphoneX()) {
            return 34;
        } else {
            return 0;
        }
    }

    resizeBody() {
        const height = window.innerHeight - this.getIphoneXBottom();
        document.body.style.height = height + "px";
        document.documentElement.style.height = height + "px";
        this.container.style.height = height + "px";
    }

    checkCart() {
        if (this.state.isAddedToCart || this.state.cartError) {
            this.setState({
                isAddedToCart: false,
                isAddingToCart: false,
                cartError: false,
            });
        }
    }

    componentWillUnmount() {
        Store.unlinkState(this);
        Store.watchOff("currentModelColor", this.checkCartBind);
        Store.watchOff("accessories", this.checkCartBind);
        Store.watchOff("options", this.checkCartBind);
        Store.watchOff("monogramText", this.checkCartBind);
        window.removeEventListener("error", this.onErrorBind);
    }

    async loadData() {
        const data = await Api.getProduct();
        await setState(this, {
            dataset: data,
            custom_parts: data.custom_parts,
            colors: data.colors,
            loaded: true,
        });

        let empty = _.filter(this.state.custom_parts, function (el) {
            return (
                (Object.keys(el.content).length === 0 ||
                    el.label == "screws-cfw3") &&
                el.content.constructor === Object
            );
        });
        if (empty.length > 0) {
            let new_data = _.filter(this.state.custom_parts, function (el) {
                return el.name != empty[0].name;
            });
            this.setState({
                mobile_parts: new_data,
            });
        } else {
            this.setState({
                mobile_parts: this.state.custom_parts,
            });
        }

        Store.set("price", this.state.dataset.price);
        Store.set(
            "shipping",
            this.state.dataset.colors[this.state.currentModelColor].shipping ||
                this.state.dataset.shipping
        );
        Store.set("currency", this.state.dataset.currency);
        Store.set("cameraProps", this.state.dataset.cameraProps);
        Store.set("defaultCameraProps", this.state.dataset.cameraProps);
        Store.set("data", data);
    }

    onError(e) {
        const message = typeof e === "string" ? e : e.message;

        if (
            /(babylon|webgl|bjs|rgb|texture|mix|2d|float)/gi.test(message) &&
            !/(load\simage|found|xhr)/gi.test(message)
        ) {
            /*
			this.setState({
				supportsWebGL: false
			})
			*/
        }
    }

    getTotalPrice() {
        
        if ( Store.computed("price") != Store.computed("price")) {
            this.animatePrice()
        } 
        return Store.computed("price");
    }

    animatePrice() {
        const el = this.refs.price 
        if ( el ) {
            this.refs.price.classList.remove("pulsing")
            setTimeout(() => {
                this.refs.price.classList.add("pulsing")
              }, 200)
        }
      
       
    }

    async onExtraMobile(extraIndex, step) {
        await setState(this, { activeExtra: extraIndex });
        this.setState({
            sidebarOpenOnMobile: !this.state.sidebarOpenOnMobile,
            activeStep: step,
        });

        Store.action("hideColorBar");
        if (this.state.sidebarOpenOnMobile == true) {
            animate("canvas", 0.4, {
                y: "-30%",
            });
        } else {
            animate("canvas", 0.4, {
                y: "0%",
            });
        }
    }
    onExtraClick(extraIndex) {
        this.setState({ activeExtra: extraIndex });
    }

    onCustomTypeClick(customization_type) {
        Store.set("custom_type", customization_type);
    }

    async closeSidebar() {
        await setState(this, {
            sidebarOpenOnMobile: false,
        });
        animate("canvas", 0.4, {
            y: "0%",
        });
        Store.action("showColorBar");
        Store.set("cameraProps", Store.get("defaultCameraProps"));
    }

    toggleMobileReview() {
        this.setState({
            mobileReviewOpen: !this.state.mobileReviewOpen,
        });
    }

    async onColorClick(index) {
        await setState(this, {
            colorBarHideTitle: true,
        });

        Store.set("currentModelColor", index);
        Store.set(
            "shipping",
            this.state.dataset.colors[this.state.currentModelColor].shipping ||
                this.state.dataset.shipping
        );
        setTimeout(() => {
            this.setState({
                colorBarHideTitle: false,
            });
        }, 800 * 4);
    }

    closeAccessories() {
        const el = document.querySelector(".acc_btn");
        el.focus();
        this.setState({
            accessoriesVisible: false,
        });
    }

    closeWhatsIncluded() {
        this.setState({
            whatsIncludedVisible: false,
        });
    }

    openWhatsIncluded() {
        this.setState({
            whatsIncludedVisible: true,
        });
    }

    async openAccessories() {
        await this.setState({
            accessoriesVisible: true,
        });
        const el = document.querySelector(".close_accessories");
        el.focus();
    }

    onSceneLoaded() {
        this.setState({
            sceneLoaded: true,
        });
    }

    disableDrag() {
        this.setState({
            dragVisible: false,
        });
    }

    onSwitchOSClose() {
        this.setState({
            switchOsVisible: false,
        });
    }

    onAddToCart() {
        if (/hexa/.test(window.location.href)) {
            const options = Store.get("options");
            if (options.length == 1) {
                Store.action("removeOption", { option: options[0] });
                return false;
            }
        }
        if (/kit/.test(window.location.href)) {
            const options = Store.get("options");
            if (options.length == 0) {
                return false;
            }
        }
        if (
            this.state.dataset &&
            this.state.dataset.switch_ios_android &&
            !this.state.switchOsVisible
        ) {
            this.setState({
                switchOsVisible: true,
            });
        } else {
            this.postToCart();
        }
        if (typeof gtag !== "undefined") {
            gtag("event", "add_to_cart", {
                event_category: "navigation-click",
                event_label: "cupl_buy",
            });
        }
    }

    goToCart() {
        return (window.location.href = Api.checkoutUrl());
    }

    postToCart() {
        if (this.state.cartError) {
            return window.location.reload();
        }

        if (this.state.isAddedToCart) {
            return this.goToCart();
        }

        const posting = Store.action("addToCart");

        posting.events.on("error", (err) => {
            this.setState({
                cartError: true,
            });
        });

        posting.events.on("finish", (err) => {
            if (err) {
                this.setState({
                    cartError: true,
                });
                return; // //console.log('there was an error while adding to cart', err)
            }

            this.setState(
                {
                    isAddedToCart: true,
                    isAddingToCart: false,
                },
                () => this.goToCart()
            );
        });

        posting.events.on("progress", (progress) => {
            //// //console.log('adding to cart progress: ', (progress * 100) + "%")
        });

        posting.start();

        this.setState({
            isAddingToCart: true,
        });
    }

    onSwitchOSAddToCart({ os }) {
        Store.set("operating_system", os);
        this.postToCart();
    }

    importYTLibrary() {
        if (!window.YT) {
            var tag = document.createElement("script");
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName("script")[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    }
    showHelper() {
        this.setState({
            helperPopupVisible: true,
        });
        if (typeof gtag !== "undefined")
            gtag("event", "cp_helper_button", {
                event_category: "click",
                event_label: "click",
            });
    }

    hideHelper() {
        this.setState({
            helperPopupVisible: false,
        });
    }
    getShipping() {
        let weeks = false;
        const actual_days = "" + Store.computed("shipping") + "";
        //console.log(Store.computed("shipping"));
        let days = actual_days.split(",");
        if (days[0] > 6) {
            days[0] = Math.round(days[0] / 7);
            days[1] = Math.round(days[1] / 7);
            weeks = true;
        }
        this.weeks(weeks);
        return days.join("-");
    }

    weeks(w) {
        if (this.state.isWeeks == w) {
            return false;
        }
        this.setState({
            isWeeks: w,
        });
    }

    setActiveStep(index) {
        this.setState({
            activeStep: index,
        });
    }
    render() {
        return !this.state.supportsWebGL ? (
            <WebGLSorry />
        ) : (
            <div className="configurator" ref={(el) => (this.container = el)}>
                {!(this.state.loaded && this.state.sceneLoaded) && <Loader />}

                {this.state.loaded && (
                    <div
                        className="configurator_main"
                        onClick={this.disableDrag.bind(this)}
                    >
                        {this.state.switchOsVisible && (
                            <SwitchOS
                                onClose={this.onSwitchOSClose.bind(this)}
                                onAddToCart={this.onSwitchOSAddToCart.bind(
                                    this
                                )}
                            />
                        )}

                        <div className="conf_content">
                            <TopBar
                                translations={this.state.t}
                                colorName={
                                    this.state.dataset.colors[
                                        this.state.currentModelColor
                                    ].name
                                }
                                name={this.state.dataset.name}
                                product_page={this.state.dataset.url}
                                onColorClick={this.onColorClick.bind(this)}
                                onCustomizationTypeClick={this.onCustomTypeClick.bind(
                                    this
                                )}
                                onExtraClick={this.onExtraClick.bind(this)}
                            />
                            <Scene
                                data={{
                                    model_path: this.state.dataset.model_path,
                                    materials: this.state.dataset.colors,
                                    custom_parts:
                                        this.state.dataset.custom_parts,
                                    custom_parts_materials: null,
                                    cameraProps: this.state.dataset.cameraProps,
                                    shadowEnabled:
                                        this.state.dataset.shadow_enabled,
                                }}
                                translations={this.state.t}
                                colorName={
                                    this.state.dataset.colors[
                                        this.state.currentModelColor
                                    ].name
                                }
                                name={this.state.dataset.name}
                                product_page={this.state.dataset.url}
                                onLoad={this.onSceneLoaded.bind(this)}
                            />
                            {/* <button
                                className="conf_help_wrapper"
                                onClick={this.showHelper.bind(this)}
                            >
                                <span className="conf_help_button">
                                    <svg
                                        width="13"
                                        height="19"
                                        viewBox="0 0 13 19"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M0.96875 6.56268C0.96875 3.01287 3.19208 0.666016 6.76875 0.666016C9.71708 0.666016 12.0371 2.4275 12.0371 5.54768C12.0371 7.84083 10.9523 8.64102 9.91041 9.55935C8.6806 10.6012 7.78375 11.3262 7.78375 13.6408H5.17375C5.17375 10.8375 5.77523 9.8225 7.05875 8.73768C8.07375 7.84083 9.01894 7.26083 9.01894 5.64435C9.01894 3.68416 7.49912 2.96453 6.6506 2.96453C4.64745 2.96453 3.72912 4.3662 3.72912 6.56268H0.974119H0.96875ZM4.80856 15.311H8.0469V18.3292H4.80856V15.311Z"
                                            fill="white"
                                        />
                                    </svg>
                                </span>
                                <p className="conf_help_text">
                                    {this.state.t.watch_tutorial}
                                </p>
                            </button>
                            {this.state.helperPopupVisible && (
                                <HelperPopup
                                    handleClick={this.hideHelper.bind(this)}
                                    videos={this.state.helperVideos}
                                />
                            )} */}
                            <ColorBar
                                current={this.state.currentModelColor}
                                colors={this.state.colors}
                                actionsEnabled={
                                    this.state.colors.length > 1 &&
                                    !this.state.colorBarHideTitle
                                }
                                onColorClick={this.onColorClick.bind(this)}
                                visible={this.state.colorBarVisible}
                            />

                            <SideBar
                                onCustomizationTypeClick={this.onCustomTypeClick.bind(
                                    this
                                )}
                                colors={this.state.colors}
                                current={this.state.currentModelColor}
                                onColorClick={this.onColorClick.bind(this)}
                                translations={this.state.t}
                                limited={this.props.limited}
                                currentColor={this.state.currentModelColor}
                                activeExtra={this.state.activeExtra}
                                data={this.state.custom_parts}
                                onExtraClick={this.onExtraClick.bind(this)}
                                openAccessories={this.openAccessories.bind(
                                    this
                                )}
                                openOnMobile={this.state.sidebarOpenOnMobile}
                                onMobileClose={this.closeSidebar.bind(this)}
                                showAccessories={
                                    this.state.dataset.accessories &&
                                    this.state.dataset.accessories.length > 0
                                }
                                setActiveStep={this.setActiveStep.bind(this)}
                                activeStep={this.state.activeStep}
                            />
                            <button
                                className={classes("hide_button", {
                                    active: this.state.sidebarOpenOnMobile,
                                })}
                                onClick={this.onExtraMobile.bind(
                                    this,
                                    0,
                                    this.state.activeStep
                                )}
                            >
                                Hide
                            </button>
                            <ExtrasMobile
                                parts={this.state.mobile_parts}
                                onClick={this.onExtraMobile.bind(this)}
                                accessoriesCount={Store.computed(
                                    "accessoriesCount"
                                )}
                                onAccessoriesClick={this.openAccessories.bind(
                                    this
                                )}
                                showAccessories={
                                    this.state.dataset.accessories &&
                                    this.state.dataset.accessories.length > 0
                                }
                                activeStep={this.state.activeStep}
                            />

                            <MobileShipping translations={this.state.t} />
                        </div>

                        {this.state.accessoriesVisible && (
                            <Accessories
                                translations={this.state.t}
                                data={this.state.dataset.accessories}
                                onClose={this.closeAccessories.bind(this)}
                            />
                        )}

                        {this.state.whatsIncludedVisible && (
                            <WhatsIncluded
                                translations={this.state.t}
                                data={this.state.dataset.whats_included}
                                onClose={this.closeWhatsIncluded.bind(this)}
                            />
                        )}

                        <BottomBar
                            preorder={this.props.preorder}
                            translations={this.state.t}
                            onAddToCart={this.onAddToCart.bind(this)}
                            isAddingToCart={this.state.isAddingToCart}
                            isAddedToCart={this.state.isAddedToCart}
                            cartError={this.state.cartError}
                        />

                        <div
                            className={
                                "mobile_add_to_cart " +
                                this.state.sidebarOpenOnMobile
                            }
                        >
                            <div className="mobile_add_to_cart_item">
                                <p className="roman c-mediumgray body4">
                                    {this.state.t.cp_ships_in}{" "}
                                    <span className="c-mediumgray roman body4">
                                        {this.getShipping()}{" "}
                                    </span>
                                    {this.state.isWeeks
                                        ? this.state.t.weeks
                                        : this.state.t.cp_days}
                                </p>
                            </div>
                            <div className="mobile_add_to_cart_item">
                                <p ref="price" className="h9 bold text-center">
                                    {this.state.dataset.currency}
                                    {this.getTotalPrice()}{" "}
                                </p>
                            </div>
                            {this.state.isAddingToCart ||
                            this.state.isAddedToCart ? (
                                <button
                                    onClick={this.onAddToCart.bind(this)}
                                    className="h9 bg-mainred c-light btn regular medium"
                                >
                                    Updating
                                </button>
                            ) : (
                                <button
                                    onClick={this.onAddToCart.bind(this)}
                                    className="h9 bg-mainred c-light btn regular medium"
                                >
                                    {this.props.preorder == "true"
                                        ? "PRE-ORDER"
                                        : this.state.t.cta_buy}
                                </button>
                            )}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
