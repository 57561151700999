import _remove from 'lodash/remove'

export function removeMaterialLayoutOption(store, { option }) {
	const partName = option.component
	const options = store.get('options')

	if ( !option.is_design ) {
		_remove(options, v => {
			return v.component === partName
		})
	} else {
		_remove(options, v => {
			return v.id === option.id
		})
	}

	store.set('options', options)
}