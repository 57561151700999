import { qs, qsa, fetchJSON } from "../utils/lib";
import Selectr from "mobius1-selectr";
export default class Cart {
  constructor(el) {
    this.el = el;
    this.btn_qty = qs(".navigation_navbar_icons_cart");
    this.qty_span = qs(".js-cart-qty");
    this.add_qty_btns = qsa(".js-qty-plus");
    this.remove_qty_btns = qsa(".js-qty-minus");
    this.currency = el.dataset.currency;
    this.total = qs(".subtotal_col_4");
    this.local_url = qs('body').dataset.currenturl;
    this.fetch_url = this.local_url + "/cart.js";
    this.add_qty_url = this.local_url + "/cart/addq";
    this.remove_qty_url = this.local_url + "/cart/removeq";
    this.remove_item_url = this.local_url + "/cart/remove";
    this.remove_btns = qsa(".remove_button");
    this.checkout_url = el.dataset.checkout;
    this.redirect = el.dataset.redirect;
    this.promo = el.dataset.promocode;
    this.user = el.dataset.user;
    this.checkout_btn = qs('.checkout_btn');
    this.locale = el.dataset.locale;
    this.notes = qs('#order_notes');
    this.selects = qsa('.cart_select')
    this.init();
  }
  init() {
    console.log(this.el)
    this.selects.forEach((el) => {
      const sel =  new Selectr(el, {
        searchable: false,
      });
      sel.on("selectr.change",(option) => {
       window.location = option.value
      })
    })
    fetchJSON(this.fetch_url, {
      method: "GET",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "xmlhttprequest",
      },
    }).then((result) => {
      this.cart = result.items
    });
    this.add_qty_btns.forEach((btn, i) => {
      btn.addEventListener("click", () => {
        this.updateCart(i, this.add_qty_url);
      });
    });
    if (window.innerWidth < 640) {
      this.checkout_btn && this.anchorBtn(this.checkout_btn)      
    }
    this.checkout_btn && this.checkout_btn.addEventListener("click", () => {
      this.gotoCheckout()
    })
    this.remove_qty_btns.forEach((btn, i) => {
      btn.addEventListener("click", () => {
        this.updateCart(i, this.remove_qty_url);
      });
    });

    this.remove_btns.forEach((btn, i) => {
      btn.addEventListener("click", () => {
        this.removeItem(i);
      });
    });
  }
  async logUserToCart(id) {


    const log_url =  this.local_url + "/cart/log"
    const result = await fetchJSON(log_url, {
             method: 'POST', 
             mode: 'same-origin',
             body: JSON.stringify({checkout: id, email: this.user}),
             credentials: 'same-origin',
             cache: 'no-store',
             headers: {
               'Content-Type': 'application/json',
               'X-Requested-With':'xmlhttprequest' /* XMLHttpRequest is ok too, it's case insensitive */
             }
     })
     const oldUrl = result
     let url = new URL(oldUrl);
     url.hostname = this.redirect;
     window.location = url.href
 }

  async gotoCheckout() {
    this.checkout_btn.innerHTML = "loading"
    const result = await fetchJSON(this.checkout_url, {
              method: 'POST', 
              mode: 'same-origin',
              body: JSON.stringify({
                cart: this.cart,
                user: this.user, 
                promo: this.promo,
                notes: decodeURIComponent(this.notes.value)}),
              credentials: 'same-origin',
              cache: 'no-store',
              headers: {
                'Content-Type': 'application/json',
                'X-Requested-With':'xmlhttprequest' 
              }
      })
      if ( this.user ) {
        this.logUserToCart(result + "&locale=" + this.locale )
      } else {
        const oldUrl = result;
          let url = new URL(oldUrl);
          url.hostname = this.redirect;
          let linker;
          if (typeof ga === 'function') {
            ga(function(tracker) {
              linker = tracker.get('linkerParam');
            });
          }
          if ( linker ) {
            window.location = url.href + "&locale=" + this.locale + "&" + linker
          } else {
            window.location = url.href + "&locale=" + this.locale 
          }
      }
          
  }

  updateCart(i, url) {
    const body = { index: i };
    const index = i;
    const product_qty = qsa(".value")[i];
    fetchJSON(url, {
      method: "POST",
      body: JSON.stringify(body),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "xmlhttprequest",
      },
    }).then((result) => {
      fetchJSON(this.fetch_url, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "xmlhttprequest",
        },
      }).then((result) => {
        if (result.items[index] == undefined || result.items[index] == 0) {
          this.removeItem(index);
        } else {
          this.qty_span.innerHTML = result.item_count;
          product_qty.innerHTML = result.items[index].quantity;
        }
        this.total.innerHTML = this.currency + " " + result.total_price.toFixed(2);
        this.cart = result.items
      });
    });
  }
  removeItem(i) {
    const body = { index: i };
    fetchJSON(this.remove_item_url, {
      method: "POST",
      body: JSON.stringify(body),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "xmlhttprequest",
      },
    }).then((result) => {
      fetchJSON(this.fetch_url, {
        method: "GET",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "xmlhttprequest",
        },
      }).then((result) => {
        location.reload();
        //Store.set('cart_count', result.item_count)
        //const popup = document.querySelector('.cart_notice')
        //popup.classList.add("open")
        //setTimeout(function () {
        //	popup.classList.remove("open")
        //}, 3000);
      });
    });
  }

  anchorBtn (el) {
      const trigger_btn = el.getBoundingClientRect().bottom + window.pageYOffset
      const trigger_opacity = qs('footer').getBoundingClientRect().top + window.pageYOffset
      window.addEventListener('scroll', () => {
          animatePill(el)
      })
      const animatePill = (el) => {
          if (window.scrollY + window.innerHeight >= trigger_btn) {
              el.classList.add('fixed')
          } else {
              el.classList.remove('fixed')
          }   
          if (window.scrollY + window.innerHeight >= trigger_opacity) {
            el.classList.add('not-visible')
        } else {
            el.classList.remove('not-visible')
        }               
      }
  }
}
