import React, { Component } from 'react'
import { classes, animate, GetCDNImage } from 'utils'

export default class WebGLSorry extends Component {

	render() {
		return (
			<div className="webglsorry c-light">

				<div className="webglsorry_container">

					<h1 className="c-light trajan h6">We are sorry.</h1>
				
					<h2 className="h9 regular c-mediumgray">
						Our Configurator uses WebGL,
						a new standard for 3D on the Web. Your Browser and operating system seem to be up to date, 
						but there is a problem running the 3D content.
						The most probable reason is that your graphics drivers or computer
						is incompatible with WebGL.
					</h2>

					<a href="https://www.v-moda.com">
						<img src={GetCDNImage("conf/webgl-btn.svg")} />
					</a>

				</div>

			</div>
		)
	}
}