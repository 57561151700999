import React, { Component } from "react";
import {
    classes,
    setState,
    animate,
    hasProperty,
    GetCDNImage,
    clearStyleProps,
} from "utils";
import { TweenLite } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Store from "../../../store";
import _groupBy from "lodash/groupBy";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";

import { SidebarRow, SidebarOption } from "../components";

import FileInput from "./file_input";

const plugins = [ScrollToPlugin];

const DesignNotSupported = () => (
    <div className="conf_sidebar_tab_content">
        <div className="conf_sidebar_content_animator">
            <span className="conf_design_not_supported c-dark h12 regular">
                Sorry! 3D printed and laser engraved customization are not
                available for this material. Please proceed without
                customization or return to the list and choose an alternative
                material.
            </span>
        </div>
    </div>
);

export default class Materials extends Component {
    state = {
        categories: [],
        content: [],
        currentCategory: null,
        activeOption: null,
        monogram_info: {},
        saved: true,
        pos: 0,
        monogramText: _cloneDeep(Store.get("monogramText")),
        labels: {
            designs: "V-MODA designs",
            monogram: "Monograms",
            "Custom Design": "Upload your own",
        },
        // Set to true if it's Free Customization Time!
        isFreeCustomization: false
    };

    constructor() {
        super(...arguments);
        this.binds = {
            selectCategory: this.selectCategory.bind(this),
            closeCategory: this.closeCategory.bind(this),
            setOption: this.setOption.bind(this),
            watchOptions: this.watchOptions.bind(this),
            monogramTextChange: this.monogramTextChange.bind(this),
            onFile: this.onFile.bind(this),
            onCustomDesignCheckboxChange:
                this.onCustomDesignCheckboxChange.bind(this),
            removeFileOption: this.removeFileOption.bind(this),
            updateMonogram: this.updateMonogram.bind(this),
        };
    }

    async setOption(option) {
        if (this.props.camera) {
            Store.set("cameraProps", this.props.camera);
        }
        

        const is3d = /3d/i.test(this.props.materialCategory);
        const isKit = document
            .querySelector("#product_title")
            .getAttribute("data-title")
            .toLowerCase()
            .includes("kit");
        const isM100 =
            document
                .querySelector("#product_title")
                .getAttribute("data-title")
                .toLowerCase() == "crossfade m-100";
        const isXFBT =
            document
                .querySelector("#product_title")
                .getAttribute("data-title")
                .toLowerCase() == "crossfade wireless";

        const isHexLite = document
            .querySelector("#product_title")
            .getAttribute("data-title")
            .toLowerCase()
            .includes("lite");

        if (isHexLite) {
            //option.price = 0
        }
        if (this.state.isFreeCustomization) {
            option.price = 0
        }
        await Store.action("addMaterialLayoutOption", {
            option: option,
            old_option: this.state.activeOption,
            partName: this.props.extraName,
        });
        const scroll = document.querySelector(".conf_sidebar");
        if (scroll) {
            scroll.scrollTo(0, scroll.scrollHeight);
        }
        const child = document.querySelector('.new_conf_option_accordion.active')
        if ( child ) {
            setTimeout(() => {
                child.scrollTo(0, 300);
            },200)
        }

        if (!option.monogram_info && option != this.state.activeOption) {
            this.updateMonogram();
        } 

        this.checkIsSaved();
        if (this.refs.input_1) {
            this.refs.input_1.value = "";
        }
        if (this.refs.input_2) {
            this.refs.input_2.value = "";
        }
    }

    onCustomDesignCheckboxChange(value) {
        Store.set("customDesignAccepted", value);
    }

    getCustomDesignAccepted() {
        return Store.get("customDesignAccepted");
    }

    preventNumbers(is, e) {
        const isNumber = isFinite(e.key) || e.keyCode == 8 || e.keyCode == 46;
        if (is && !isNumber) {
            e.preventDefault();
        }
    }

    monogramTextChange(index, e) {
        //console.log("il monogramma !");
        let test = /[a-z0-9]/i.test(e.target.value);
        if (!test) {
            e.target.value = "";
            return false;
        }
        this.state.monogramText[this.props.extraName] =
            this.state.monogramText[this.props.extraName] || {};
        this.state.monogramText[this.props.extraName][index] = e.target.value;
        this.setState(
            {
                monogramText: this.state.monogramText,
            },
            () => {
                this.checkIsSaved();
            }
        );
        this.updateMonogram();
    }

    checkIsSaved() {
        return false;
    }

    getCurrentMaterialOption() {
        const options = Store.get("options");
        const partName = this.props.extraName;

        for (let option of options) {
            if (option.component === partName && !option.is_design) {
                return option;
            }
        }

        return null;
    }

    isDesignSupported() {
        const option = this.getCurrentMaterialOption();
        return (
            option &&
            (typeof option.disable_design === "undefined" ||
                option.disable_design === false)
        );
    }

    updateMonogram() {
        //console.log("update monogram");
        Store.set("monogramText", this.state.monogramText);
    }

    onFile(file) {
        const data = this.state.data[this.state.currentCategory][0];
        const bump =
            "https://res.cloudinary.com/dnain5omf/image/upload/v1519825151/placeholder_custom_design_NRM2_zpcpbs.png";

        const isLaser =
            /laser/i.test(this.props.materialCategory) ||
            /color/i.test(this.props.materialCategory);

        const isS80 = /s-80/.test(window.location.href);

        const laserMaterial = {
            microSurface: 0,
            reflectivityColor: "#000000",
            reflectionColor: "#000000",
            albedoColor: "#ffffff",
            albedoTexture: isS80
                ? "/cd/cd-13.png"
                : "/cd/cd-13.png",
        };

        const colorMaterial = {
            microSurface: 0,
            reflectivityColor: "#000000",
            reflectionColor: "#000000",
            albedoColor: "#ffffff",
            albedoTexture: isS80
                ? "/cd/cd-13.png"
                : "/cd/cd-13.png",
        };

        const Material3D = {
            bumpTexture: bump,
        };

        let sku = "";
        let material = {};
        let custom_price = data.price;
        let shipping = data.shipping;
        const isKit = document
            .querySelector("#product_title")
            .getAttribute("data-title")
            .toLowerCase()
            .includes("kit");

        if (/laser/i.test(this.props.materialCategory)) {
            material = laserMaterial;
            sku = "laser_custom_design";
        }

        if (/color/i.test(this.props.materialCategory)) {
            material = colorMaterial;
            sku = "color_custom_design";
        }

        if (/3d/i.test(this.props.materialCategory)) {
            material = Material3D;
            sku = "3d_custom_design";
        }

        this.fileOption = {
            component: this.props.extraName,
            title: "Custom Design",
            custom_type: "design",
            color_variant: "",
            sku: sku,
            id: "custom_design",
            shipping: shipping,
            price: custom_price,
            file: file,
            is_image: isLaser,
            is_design: true,
            is_custom_design: true,
            material: material,
            model_3d: { url: null },
            model_3d_specular: { url: null },
        };

        this.setOption(this.fileOption);
    }

    removeFileOption(file) {
        if (!this.fileOption) return;

        Store.action("removeMaterialLayoutOption", { option: this.fileOption });
        this.fileOption = null;
    }
    componentDidUpdate(prevProps,prevState) {
        if ( prevProps == this.props ) {
            return false
        }

        const custom_type = this.props.materialCategory
        const data = _groupBy(this.props.data[custom_type], "design_type");
        this.setState({
            data,
        });
    }
    componentDidMount() {
        //console.log("design mounted");
        //console.log(this.props)
        const data = _groupBy(this.props.data["color_printing"], "design_type");
        //console.log(data);
        this.setState({
            data,
            content: [],
            categories: Object.keys(data),
            currentCategory: null,
        });

        this.checkSelectedOption();

        this.checkIsSaved();

        Store.watch("monogramText", (value) => {
            this.updateCurrentMonogram();
        });

        Store.watch("options", this.binds.watchOptions);

        if (Object.keys(data).length == 1) {
            this.selectCategory(Object.keys(data)[0]);
        }
    }

    showText() {
        return (
            this.state.activeOption &&
            this.state.activeOption.monogram_info &&
            Object.keys(this.state.activeOption.monogram_info).length > 0 &&
            (this.getMonogramCharsLimit("a") > 0 ||
                this.getMonogramCharsLimit("b") > 0)
        );
    }

    showPicker() {
        //console.log(this.state.activeOption);
        return (
            this.state.activeOption &&
            this.state.activeOption.albedo_textures &&
            this.state.activeOption.design_type === this.state.currentCategory
        );
    }

    getTextCTA() {
        const styles = this.getMonogramStyle();
        if (/laser/i.test(this.props.materialCategory)) {
            if (styles && Object.keys(styles).length) {
                return (
                    <button
                        className="h11 regular c-light upcase"
                        onClick={this.binds.updateMonogram}
                    >
                        {!this.state.saved && (
                            <img src={GetCDNImage("conf/reload.svg")} />
                        )}
                        {this.state.saved ? "Apply" : "Apply"}
                    </button>
                );
            } else {
                return (
                    <button
                        className="h11 regular c-light upcase"
                        onClick={this.binds.updateMonogram}
                    >
                        {!this.state.saved && (
                            <img src={GetCDNImage("conf/save.svg")} />
                        )}
                        {this.state.saved ? "Apply" : "Apply"}
                    </button>
                );
            }
        }
    }

    componentWillUnmount() {
        Store.watchOff("options", this.binds.watchOptions);
        Store.unlinkState(this);
    }

    checkSelectedOption() {
        //console.log(this.props.extraName, "checkSelectedOption");
        let selected = Store.action("getSelectedOptionFor", {
            partName: this.props.extraName,
            is_design: true,
        });
        //console.log(selected, "ecco la selezionata");

        this.setState({
            activeOption: selected,
            monogram_info: selected ? selected.monogram_info : {},
        });
    }

    updateCurrentMonogram() {
        const value = Store.get("monogramText");
        this.setState(
            {
                monogramText: _cloneDeep(value),
            },
            () => this.checkIsSaved()
        );
    }

    watchOptions() {
        this.checkSelectedOption();
        this.updateCurrentMonogram();
    }

    async componentWillReceiveProps(newprops) {
        if (newprops.data === this.props.data) return;

        let selected = Store.action("getSelectedOptionFor", {
            partName: newprops.extraName,
            is_design: true,
        });
        const custom_type = newprops.materialCategory
        //console.log(custom_type,'aaaa')
        const data = _groupBy(this.props.data[custom_type], "design_type");
        this.setState({
            data,
            categories: Object.keys(data),
            currentCategory: null,
            activeOption: selected,
        });

        this.props.onCloseInnerSection();

        if (this.animator) clearStyleProps(this.animator, "all");
    }

    async selectCategory(category) {
        this.props.onChange();
        await setState(this, {
            currentCategory: category,
        });
        const child = document.querySelector('.new_conf_option_accordion.active')
        if ( child ) {
            setTimeout(() => {
                child.scrollTo(0, 150);
            },200)
        }
        const scroll = document.querySelector(".conf_sidebar");
        if (scroll) {
            setTimeout(() => {
                scroll.scrollTo(0, scroll.scrollHeight);
            },200)
            
        }
    }

    async closeCategory() {
        if (this.state.activeOption) {
            const activeOption = Store.action("addMaterialLayoutOption", {
                option: this.state.activeOption,
                old_option: this.state.activeOption,
                partName: this.props.extraName,
            });
        }

        this.props.onCloseInnerSection();
        const el = document.querySelector(".conf_sidebar_scroll");
        if (el) {
            el.classList.remove("hidden");
        }
        const ell = document.querySelector(".conf_sidebar_inner_scroll");
        if (ell) {
            ell.classList.add("hidden");
        }
        if (this.animator) {
            await animate(this.animator, 0, {
                x: "0%",
            });
        }

        this.setState({
            currentCategory: null,
        });
    }

    getMonogramText(index) {
        return (
            (this.state.monogramText[this.props.extraName] || [])[index] || ""
        );
    }

    getContent() {
        
        return this.state.data[this.state.currentCategory];
    }

    getMonogramPlaceholder(type) {
        return this.state.activeOption && this.state.monogram_info
            ? this.state.monogram_info[`text_${type}_placeholder`] || ""
            : "";
    }

    getMonogramStyle() {
        const active = this.state.activeOption;
        return active && active.monogram_style ? active.monogram_style : null;
    }

    getMonogramCharsLimit(type) {
        return this.state.activeOption && this.state.monogram_info
            ? this.state.monogram_info[`text_${type}_chars_limit`] || 0
            : 0;
    }

    async albedoChange(
        i,
        color,
        title,
        text_color,
        gradient,
        text_color_2,
        gradient_from,
        gradient_to,
        gradient_dir,
        event
    ) {
        this.state.activeOption.albedo_textures.forEach((el, ix) => {
            if (ix == i) {
                el.active = "active";
            } else {
                el.active = "";
            }
        });
        let options = Store.get("options");
        const pos = options
            .map(function (e) {
                return e.id;
            })
            .indexOf(this.state.activeOption.id);
        let active = this.state.activeOption;
        let base_sku;
        if (window.location.href.toLowerCase().indexOf("hex") > 0) {
            base_sku = "HEXM-";
        } else {
            base_sku = "OV-CP-";
        }

        if (active.monogram_style) {
            base_sku = base_sku + "M-";
        }
        let new_sku =
            base_sku +
            active.title.toUpperCase().replace(" ", "") +
            "-" +
            title.toUpperCase();

        options[pos].sku = new_sku;
        console.log(new_sku,'aaa')
        if (color) {
            options[pos].material.albedoTexture = color;
            options[pos].material.reflectivityColor = "#000000";
        }
        if (  new_sku.includes("SPACEJERSEY") ) {
            if (text_color_2) {
                options[pos].monogram_style.color = text_color_2;
                options[pos].monogram_style.gradient = false;
            }
            if (text_color) {
                options[pos].monogram_style_2.color = text_color;
                options[pos].monogram_style_2.gradient = false;
            }
        } else {
            if (text_color) {
                options[pos].monogram_style.color = text_color;
                options[pos].monogram_style.gradient = false;
            }
            if (text_color_2) {
                options[pos].monogram_style_2.color = text_color_2;
                options[pos].monogram_style_2.gradient = false;
            }

        }
        
        if (gradient && (new_sku.includes("COLLEGEJERSEY"))) {

            options[pos].monogram_style.color = text_color_2;
            options[pos].monogram_style.gradient = false;
            options[pos].monogram_style_2.gradient = true;
            if (gradient_from) {
                options[pos].monogram_style_2.gradient_from = gradient_from;
            }
            if (gradient_to) {
                options[pos].monogram_style_2.gradient_to = gradient_to;
            }
            if (gradient_dir) {
                options[pos].monogram_style_2.gradient_dir = gradient_dir;
            } 
        } else if ( gradient ) {
            options[pos].monogram_style.gradient = true;
            if (gradient_from) {
                options[pos].monogram_style.gradient_from = gradient_from;
            }
            if (gradient_to) {
                options[pos].monogram_style.gradient_to = gradient_to;
            }
            if (gradient_dir) {
                options[pos].monogram_style.gradient_dir = gradient_dir;
            } 
        }

        Store.set("monogramText", this.state.monogramText);
        options[pos].color_variant = title;
        Store.set("options", options);
    }

    render() {
        return (
            <div>
                <p className="subtitle">Choose among our design options</p>
                {this.props.materialCategory && (
                    <div className="new_conf_sidebar_button_grid subtitle grid_design">
                        {this.state.categories.map((v, i) => (
                            <div className={"new_conf_sidebar_button_grid_col"}>
                                <button
                                    onClick={() => this.selectCategory(v)}
                                    className={classes("body2", {
                                        selected:
                                            this.state.currentCategory === v,
                                    })}
                                >
                                    {this.props.translations[v]}
                                    <br />{" "}
                                    {this.state.isFreeCustomization ? (
                                        <span className="c-dark bold">FREE</span>
                                    ) : (
                                        <span>{this.props.translations["price_" + v]}</span>
                                    )}
                                    
                                    
                                </button>
                            </div>
                        ))}
                    </div>
                )}
                {this.state.currentCategory === "custom_design" ? (
                    <FileInput
                        onFile={this.binds.onFile}
                        file={(this.state.activeOption || {}).file}
                        onCheckboxChange={
                            this.binds.onCustomDesignCheckboxChange
                        }
                        accepted={this.getCustomDesignAccepted()}
                        materialCategory={this.props.materialCategory}
                        onFileRemove={this.binds.removeFileOption}
                        translations={this.props.translations}
                    />
                ) : (
                    <div>
                        {this.state.currentCategory && this.getContent() && (
                            <section class="new_conf_sidebar_design_section">
                                <div className="new_conf_sidebar_grid  list_design">
                                    {this.getContent().map((v, i) => (
                                        <div
                                            onClick={() =>
                                                this.binds.setOption(v)
                                            }
                                            className="new_conf_sidebar_grid_col design"
                                        >
                                            <div
                                                className={classes(
                                                    "image_wrapper design",
                                                    {
                                                        current:
                                                            this.state
                                                                .activeOption
                                                                ?.title ===
                                                            v.title,
                                                    }
                                                )}
                                            >
                                                <img src={v.img} alt="" />
                                            </div>
                                            <span>{v.title}</span>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        )}
                        {this.showPicker() && (
                            <section class="new_conf_sidebar_design_section">
                                <legend className="regular medium c-dark 6">
                                    {this.props.translations.mat_choose_color}
                                </legend>
                                <div className="new_conf_sidebar_grid color">
                                    {this.state.activeOption.albedo_textures.map(
                                        (v, i) => (
                                            <div className="new_conf_sidebar_grid_col color">
                                                <div className="image_wrapper">
                                                    <button
                                                        className={
                                                            "new_conf_sidebar_color " +
                                                            v.bg +
                                                            " " +
                                                            (this.state.activeOption.albedo_textures.filter((el) => el.active == "active").length == 0 ? (i == 0 ? "active" : "") : v.active)
                                                        }
                                                        onClick={this.albedoChange.bind(
                                                            this,
                                                            i,
                                                            v.image,
                                                            v.name,
                                                            v.text_color,
                                                            v.gradient,
                                                            v.text_color_2,
                                                            v.gradient_from,
                                                            v.gradient_to,
                                                            v.gradient_dir
                                                        )}
                                                    ></button>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </section>
                        )}

                        {this.showText() && (
                            <div
                                className="new_conf_sidebar_textinput"
                                ref="texts"
                            >
                                <fieldset>
                                    {this.getMonogramCharsLimit("a") > 0 && (
                                        <input
                                            type="text"
                                            ref="input_1"
                                            placeholder="Type"
                                            pattern="![^a-zA-Z0-9]"
                                            onChange={(e) =>
                                                this.binds.monogramTextChange(
                                                    0,
                                                    e
                                                )
                                            }
                                            maxLength={this.getMonogramCharsLimit(
                                                "a"
                                            )}
                                        />
                                    )}

                                    {this.getMonogramCharsLimit("b") > 0 && (
                                        <input
                                            type="text"
                                            ref="input_2"
                                            placeholder="Type"
                                            onChange={(e) =>
                                                this.binds.monogramTextChange(
                                                    1,
                                                    e
                                                )
                                            }
                                            pattern="![^a-zA-Z0-9]"
                                            maxLength={this.getMonogramCharsLimit(
                                                "b"
                                            )}
                                        />
                                    )}

                                    {window.location.href
                                        .toLowerCase()
                                        .indexOf("hex") != -1 && (
                                        <p className="h12">
                                            <br />
                                            {this.props.translations.cp_notice}
                                           
                                        </p>
                                    )}
                                </fieldset>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}
