import React, { Component } from 'react'


export default class HelperPopup extends Component {

    state = {
        videos: JSON.parse(this.props.videos)
    }

    componentDidMount() {
        if (!window.YT) {
            var tag = document.createElement('script')
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0]
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        }
        let player;
        const video = window.innerWidth > 640 ? this.state.videos.src_video : this.state.videos.src_video_mobile
        window.YT.ready(function() {
            player = new window.YT.Player("js-player", {
              height: "390",
              width: "640",
              videoId: video
            });
          });
        
    }



    render() {
        return (
            <section className='helper_popup'>
                <div className='helper_popup_layer'></div>
                <div className='helper_popup_wrapper'>
                    <div className='helper_popup_wrapper_ratio'>
                        <div id="js-player"></div>
                    </div>
                </div>
                <button 
                    className='helper_popup_close' 
                    onClick={this.props.handleClick}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                </button>
            </section>
        )
    }
}