import React, { Component, Suspense } from 'react'
import { classes } from 'utils'
import MiniLoader  from '../ui/mini_loader'
import HelperPopup from './helper';

const Render = React.lazy(() => import('./render'));

export default class Scene extends Component {

 
	state = {
		miniLoaderVisible: false,
        helperPopupVisible: false
	}

	showLoader() {
		this.setState({
			miniLoaderVisible: true,
		})
	}	

	hideLoader() {
		this.setState({
			miniLoaderVisible: false,
		})
	}
   

	render() {
		return (
			<div className="conf_scene">

				{ this.state.miniLoaderVisible && <MiniLoader/> }
				 <Suspense fallback={<div>Loading...</div>}>
                    <Render {...this.props.data}
                        showLoader={this.showLoader.bind(this)}
                        hideLoader={this.hideLoader.bind(this)}
                        translations={this.props.translations}
                        colorName={this.props.colorName}
                        name={this.props.name}
                        product_page={this.props.product_page}
                        onLoad={this.props.onLoad} />
                </Suspense>	
			</div>
		)
	}
}