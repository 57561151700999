import React, { Component } from 'react'
import { classes } 			from 'utils'
import Store 				from '../store'

export default class Shipping extends Component {
	
	state = Store.getProps([
		"options"
	])
	state = {
		isWeeks: false,
		...Store.getProps([
			"options"
		])
	}

	componentDidMount() {
		Store.linkState(this, ["options"])
	}

	getShipping() {
		let weeks = false
		const actual_days = "" + Store.computed('shipping') + ""
		let days = actual_days.split(",")
		if ( days[0] > 6 ) {
			days[0] = Math.round(days[0] / 7)
			days[1] = Math.round(days[1] / 7)	
			weeks = true		
		}
		this.weeks(weeks)
		return days.join("-")
	}

	weeks(w) {
		if ( this.state.isWeeks == w ) {
			return false
		}
		this.setState({
			isWeeks: w
		})
	}

	render() {
		return (
			<div className="conf_mobile_shipping c-mediumgray regular medium h12">
				{this.props.translations.cp_ships_in}&nbsp;<span className="c-light">{this.getShipping()}{" "}
				{ this.state.isWeeks ? this.props.translations.weeks : this.props.translations.cp_days }</span>
			</div>
		)
	}
}