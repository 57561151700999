import React, { Component } from "react";
import {
  classes,
  setState,
  animate,
  hasProperty,
  GetCDNImage,
  parameterize,
} from "utils";
import Store from "../../store";
import MaterialLayout from "./materials/index";
import DesignLayout from "./design/index";
/*

We have 2 type of configurators

MATERIALS, DESIGN

MATERIALS: ( Materials Based view )
We have two tabs inside the sidebar, ( materials, design ), where designs are relative to materials,
because not every design can be applied to every material.
Materials are grouped by material type:
	3D Precious Metals
	Etc...
Designs are grouped by design type:
	Design ( images, logos )
	Monograms ( personalized texts )
There is an additional section inside design tab that is not related to the cms data:
	"Custom Design", where you can upload an image/pdf.   

DESIGN:
Design based view, with 1 tab only: "Design".
In this view, materials are grouped by design, because v-moda thinks that in some type of products,
designs are more important than materials ( es: Remix Speaker ).
So you firsly choose a design, and after you will see all materials available for that design, grouped by material-type

*/

export default class SideBarContent extends Component {
  state = {
    activeExtra: 0,

    activeTab: null,
    tabActiveContentKey: null,

    animationTime: 0.8,
  };

  constructor() {
    super(...arguments);
    this.binds = {};
  }

  async componentWillReceiveProps(newprops) {
    if (this.state.activeExtra === newprops.activeExtra) return;

    await setState(this, {
      activeExtra: newprops.activeExtra,
    });

    //this.checkMobileCloseBind = this.checkMobileClose.bind(this)
    //Store.watch('options', this.checkMobileCloseBind)
  }

  checkMobileClose() {
    if (window.innerWidth <= 768) {
      this.props.onMobileClose();
    }
  }

  componentWillUnmount() {
    //Store.watchOff('options', this.checkMobileCloseBind)
  }

  componentDidMount() {
    this.setState({
      activeExtra: this.props.activeExtra,
    });
  }

  render() {
    return (
      <div
        role="tabpanel"
        tabindex="0"
        aria-labelledby={
          "btn_" + parameterize(this.props.data[this.state.activeExtra].name)
        }
        className="conf_sidebar_content"
        id={parameterize(this.props.data[this.state.activeExtra].name)}
        ref="container"
      >
        {this.props.data[this.state.activeExtra].dominant_layout ===
        "material" ? (
          <MaterialLayout
            currentColor={this.props.currentColor}
            limited={this.props.limited}
            close={this.props.onMobileClose}
            translations={this.props.translations}
            extraLabel={this.props.data[this.state.activeExtra].label}
            extraName={this.props.data[this.state.activeExtra].name}
            camera={this.props.data[this.state.activeExtra].cameraProps}
            data={this.props.data[this.state.activeExtra].content}
          />
        ) : (
          <DesignLayout
            translations={this.props.translations}
            extraName={this.props.data[this.state.activeExtra].name}
            camera={this.props.data[this.state.activeExtra].cameraProps}
            data={this.props.data[this.state.activeExtra].content}
          />
        )}
      </div>
    );
  }
}
