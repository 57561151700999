import React, { Component } from "react";
import {
  classes,
  setState,
  animate,
  hasProperty,
  GetCDNImage,
  clearStyleProps,
} from "utils";
import { TweenLite } from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import Store from "../../../store";
import _groupBy from "lodash/groupBy";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";

import { SidebarRow, SidebarOption } from "../components";

import FileInput from "./file_input";

const plugins = [ScrollToPlugin];

const DesignNotSupported = () => (
  <div className="conf_sidebar_tab_content">
    <div className="conf_sidebar_content_animator">
      <span className="conf_design_not_supported c-dark h12 regular">
        Sorry! 3D printed and laser engraved customization are not available for
        this material. Please proceed without customization or return to the
        list and choose an alternative material.
      </span>
    </div>
  </div>
);

export default class Materials extends Component {
  state = {
    categories: [],
    content: [],
    currentCategory: null,
    activeOption: null,
    monogram_info: {},
    saved: false,
    pos: 0,
    monogramText: _cloneDeep(Store.get("monogramText")),
    labels: {
      designs: "V-MODA designs",
      monogram: "Monograms",
      "Custom Design": "Upload your own",
    },
  };

  constructor() {
    super(...arguments);
    this.binds = {
      selectCategory: this.selectCategory.bind(this),
      closeCategory: this.closeCategory.bind(this),
      setOption: this.setOption.bind(this),
      watchOptions: this.watchOptions.bind(this),
      monogramTextChange: this.monogramTextChange.bind(this),
      onFile: this.onFile.bind(this),
      onCustomDesignCheckboxChange:
        this.onCustomDesignCheckboxChange.bind(this),
      removeFileOption: this.removeFileOption.bind(this),
      updateMonogram: this.updateMonogram.bind(this),
    };
  }

  async setOption(option) {
    const is3d = /3d/i.test(this.props.materialCategory);
    const isKit = document
      .querySelector("#product_title")
      .getAttribute("data-title")
      .toLowerCase()
      .includes("kit");
    const isM100 =
      document
        .querySelector("#product_title")
        .getAttribute("data-title")
        .toLowerCase() == "crossfade m-100";
    const isXFBT =
      document
        .querySelector("#product_title")
        .getAttribute("data-title")
        .toLowerCase() == "crossfade wireless";

    const isHexLite = document
        .querySelector("#product_title")
        .getAttribute("data-title")
        .toLowerCase()
        .includes("lite");
  

    if ( isHexLite ) {
      //option.price = 0
    }

    option.price = 0
    
    const activeOption = Store.action("addMaterialLayoutOption", {
      option: option,
      old_option: this.state.activeOption,
      partName: this.props.extraName,
    });

    if (!option.monogram_info && option != this.state.activeOption) {
      this.updateMonogram();
    }

    this.checkIsSaved();
    if (this.refs.input_1) {
      this.refs.input_1.value = "";
    }
    if (this.refs.input_2) {
      this.refs.input_2.value = "";
    }

    const el = document.querySelector(".conf_sidebar_option.active");
    if (el) {
      el.focus();
    }

    if (this.showPicker()) {
      window.ScrollToPlugin = ScrollToPlugin;
      setTimeout(() => {
        const cont = document.querySelector(".conf_sidebar_innersection");
        TweenLite.to(cont, 1, {
          scrollTo: this.refs.color_picker.getBoundingClientRect().top - 400,
        });
      }, 500);
    }
    if (!this.showPicker() && this.showText()) {
      window.ScrollToPlugin = ScrollToPlugin;
      setTimeout(() => {
        const picker = document.querySelector(".conf_sidebar_textinput");
        const cont = document.querySelector(".conf_sidebar_innersection");
        TweenLite.to(cont, 1, {
          scrollTo: picker.getBoundingClientRect().top - 400,
        });
      }, 500);
    }
  }

  onCustomDesignCheckboxChange(value) {
    Store.set("customDesignAccepted", value);
  }

  getCustomDesignAccepted() {
    return Store.get("customDesignAccepted");
  }

  preventNumbers(is, e) {
    const isNumber = isFinite(e.key) || e.keyCode == 8 || e.keyCode == 46;
    if (is && !isNumber) {
      e.preventDefault();
    }
  }

  monogramTextChange(index, e) {
    let test = /[a-z0-9]/i.test(e.target.value);
    if (!test) {
      e.target.value = "";
      return false;
    }
    this.state.monogramText[this.props.extraName] =
      this.state.monogramText[this.props.extraName] || {};
    this.state.monogramText[this.props.extraName][index] = e.target.value;
    this.setState(
      {
        monogramText: this.state.monogramText,
      },
      () => {
        this.checkIsSaved();
      }
    );
    this.updateMonogram();
  }

  checkIsSaved() {
    if (this.state.activeOption && !this.state.activeOption.monogram_info)
      return;

    const c_monogram = this.state.monogramText[this.props.extraName];
    const store_monogram = Store.get("monogramText")[this.props.extraName];
    if (
      c_monogram[0] === store_monogram[0] &&
      c_monogram[1] === store_monogram[1]
    ) {
      !this.state.saved && this.setState({ saved: true });
    } else {
      this.state.saved && this.setState({ saved: false });
    }
  }

  getCurrentMaterialOption() {
    const options = Store.get("options");
    const partName = this.props.extraName;

    for (let option of options) {
      if (option.component === partName && !option.is_design) {
        return option;
      }
    }

    return null;
  }

  isDesignSupported() {
    const option = this.getCurrentMaterialOption();
    return (
      option &&
      (typeof option.disable_design === "undefined" ||
        option.disable_design === false)
    );
  }

  updateMonogram() {
    Store.set("monogramText", this.state.monogramText);
  }

  onFile(file) {
    const data = this.state.data[this.state.currentCategory][0];
    const bump =
      "https://res.cloudinary.com/dnain5omf/image/upload/v1519825151/placeholder_custom_design_NRM2_zpcpbs.png";

    const isLaser =
      /laser/i.test(this.props.materialCategory) ||
      /color/i.test(this.props.materialCategory);

    const isS80 = /s-80/.test(window.location.href) 
    

    const laserMaterial = {
      microSurface: 0,
      reflectivityColor: "#000000",
      reflectionColor: "#000000",
      albedoColor: "#ffffff",
      albedoTexture: isS80 ? "/custom_design_80.png" : GetCDNImage("conf/placeholder_custom_design.png"),
    };

    const colorMaterial = {
      microSurface: 0,
      reflectivityColor: "#000000",
      reflectionColor: "#000000",
      albedoColor: "#ffffff",
      albedoTexture: isS80 ? "/custom_design_80.png" : "/color_design_place.png",
    };

    const Material3D = {
      bumpTexture: bump,
    };
   


    let sku = "";
    let material = {};
    let custom_price = data.price;
    let shipping = data.shipping;
    const isKit = document
      .querySelector("#product_title")
      .getAttribute("data-title")
      .toLowerCase()
      .includes("kit");

    if (/laser/i.test(this.props.materialCategory)) {
      material = laserMaterial;
      sku = "laser_custom_design";
    }

    if (/color/i.test(this.props.materialCategory)) {
      material = colorMaterial;
      sku = "color_custom_design";
    }

    if (/3d/i.test(this.props.materialCategory)) {
      material = Material3D;
      sku = "3d_custom_design";
    }

    this.fileOption = {
      component: this.props.extraName,
      title: "Custom Design",
      sku: sku,
      id: "custom_design",
      shipping: shipping,
      price: custom_price,
      file: file,
      is_image: isLaser,
      is_design: true,
      is_custom_design: true,
      material: material,
      model_3d: { url: null },
      model_3d_specular: { url: null },
    };

    this.setOption(this.fileOption);
  }

  removeFileOption(file) {
    if (!this.fileOption) return;

    Store.action("removeMaterialLayoutOption", { option: this.fileOption });
    this.fileOption = null;
  }

  componentDidMount() {
    const data = _groupBy(this.props.data, "design_type");
    this.setState({
      data,
      content: [],
      categories: Object.keys(data),
      currentCategory: null,
    });

    this.checkSelectedOption();

    this.checkIsSaved();

    Store.watch("monogramText", (value) => {
      this.updateCurrentMonogram();
    });

    Store.watch("options", this.binds.watchOptions);

    if (Object.keys(data).length == 1) {
      this.selectCategory(Object.keys(data)[0]);
    }
  }

  showText() {
    return (
      this.state.activeOption &&
      this.state.activeOption.monogram_info &&
      Object.keys(this.state.activeOption.monogram_info).length > 0 &&
      (this.getMonogramCharsLimit("a") > 0 ||
        this.getMonogramCharsLimit("b") > 0)
    );
  }

  showPicker() {
    return (
      this.state.activeOption &&
      this.state.activeOption.albedo_textures &&
      this.state.activeOption.design_type === this.state.currentCategory
    );
  }

  getTextCTA() {
    const styles = this.getMonogramStyle();
    if (/laser/i.test(this.props.materialCategory)) {
      if (styles && Object.keys(styles).length) {
        return (
          <button
            className="h11 regular c-light upcase"
            onClick={this.binds.updateMonogram}
          >
            {!this.state.saved && <img src={GetCDNImage("conf/reload.svg")} />}
            {this.state.saved ? "Apply" : "Apply"}
          </button>
        );
      } else {
        return (
          <button
            className="h11 regular c-light upcase"
            onClick={this.binds.updateMonogram}
          >
            {!this.state.saved && <img src={GetCDNImage("conf/save.svg")} />}
            {this.state.saved ? "Apply" : "Apply"}
          </button>
        );
      }
    }
  }

  componentWillUnmount() {
    Store.watchOff("options", this.binds.watchOptions);
    Store.unlinkState(this);
  }

  checkSelectedOption() {
    let selected = Store.action("getSelectedOptionFor", {
      partName: this.props.extraName,
      is_design: true,
    });

    this.setState({
      activeOption: selected,
      monogram_info: selected ? selected.monogram_info : {},
    });
  }

  updateCurrentMonogram() {
    const value = Store.get("monogramText");
    this.setState(
      {
        monogramText: _cloneDeep(value),
      },
      () => this.checkIsSaved()
    );
  }

  async switchTab(e) {
    const l = this.state.data[this.state.currentCategory].length;
    const els = Array.from(document.querySelectorAll(".conf_sidebar_option"));
    let pos = this.state.pos;
    switch (e.key) {
      case "ArrowLeft":
      case "ArrowUp":
        if (pos == 0) {
          pos = l - 1;
        } else {
          pos = pos - 1;
        }
        await this.setState({
          pos: pos,
        });

        els[pos].click();

        els[pos].focus();
        break;

      case "ArrowRight":
      case "ArrowDown":
        if (pos == l - 1) {
          pos = 0;
        } else {
          pos = pos + 1;
        }
        await this.setState({
          pos: pos,
        });
        els[pos].click();

        els[pos].focus();
        break;
    }
  }

  watchOptions() {
    this.checkSelectedOption();
    this.updateCurrentMonogram();
  }

  async componentWillReceiveProps(newprops) {
    if (newprops.data === this.props.data) return;

    let selected = Store.action("getSelectedOptionFor", {
      partName: newprops.extraName,
      is_design: true,
    });

    const data = _groupBy(newprops.data, "design_type");
    this.setState({
      data,
      categories: Object.keys(data),
      currentCategory: null,
      activeOption: selected,
    });

    this.props.onCloseInnerSection();

    if (this.animator) clearStyleProps(this.animator, "all");
  }

  async selectCategory(category) {
    await setState(this, {
      currentCategory: category,
    });

    this.props.onOpenInnerSection();
    const el = document.querySelector(".conf_sidebar_scroll");
    if (el) {
      el.classList.add("hidden");
    }
    const ell = document.querySelector(".conf_sidebar_inner_scroll");
    if (ell) {
      ell.classList.remove("hidden");
    }
    if (this.animator) {
      animate(this.animator, 0, {
        x: "-100%",
      });
    }
    this.refs.back.focus();
  }

  async closeCategory() {
    if ( this.state.activeOption ) {
      const activeOption = Store.action("addMaterialLayoutOption", {
        option: this.state.activeOption,
        old_option: this.state.activeOption,
        partName: this.props.extraName,
      });
    }
    
    this.props.onCloseInnerSection();
    const el = document.querySelector(".conf_sidebar_scroll");
    if (el) {
      el.classList.remove("hidden");
    }
    const ell = document.querySelector(".conf_sidebar_inner_scroll");
    if (ell) {
      ell.classList.add("hidden");
    }
    if (this.animator) {
      await animate(this.animator, 0, {
        x: "0%",
      });
    }

    this.setState({
      currentCategory: null,
    });
  }

  getMonogramText(index) {
    return (this.state.monogramText[this.props.extraName] || [])[index] || "";
  }

  getContent() {
    return this.state.data[this.state.currentCategory];
  }

  getMonogramPlaceholder(type) {
    return this.state.activeOption && this.state.monogram_info
      ? this.state.monogram_info[`text_${type}_placeholder`] || ""
      : "";
  }

  getMonogramStyle() {
    const active = this.state.activeOption;
    return active && active.monogram_style ? active.monogram_style : null;
  }

  getMonogramCharsLimit(type) {
    return this.state.activeOption && this.state.monogram_info
      ? this.state.monogram_info[`text_${type}_chars_limit`] || 0
      : 0;
  }

  async albedoChange(
    i,
    color,
    title,
    text_color,
    gradient,
    text_color_2,
    gradient_from,
    gradient_to,
    gradient_dir,
    event
  ) {
    this.state.activeOption.albedo_textures.forEach((el, ix) => {
      if (ix == i) {
        el.active = "active";
      } else {
        el.active = "";
      }
    });
    let options = Store.get("options");
    const pos = options
      .map(function (e) {
        return e.id;
      })
      .indexOf(this.state.activeOption.id);
    let active = this.state.activeOption;
    let base_sku;
    if (window.location.href.toLowerCase().indexOf("hex") > 0) {
      base_sku = "HEXM-";
    } else {
      base_sku = "OV-CP-";
    }

    if (active.monogram_style) {
      base_sku = base_sku + "M-";
    }
    let new_sku =
      base_sku +
      active.title.toUpperCase().replace(" ", "") +
      "-" +
      title.toUpperCase();

    options[pos].sku = new_sku;
    if (color) {
      options[pos].material.albedoTexture = color;
      options[pos].material.reflectivityColor = "#000000";
    }
    if (text_color) {
      options[pos].monogram_style.color = text_color;
      options[pos].monogram_style.gradient = false;
    }
    if (text_color_2) {
      options[pos].monogram_style_2.color = text_color_2;
      options[pos].monogram_style_2.gradient = false;
    }
    if (gradient) {
      options[pos].monogram_style.gradient = true;
      if (gradient_from) {
        options[pos].monogram_style.gradient_from = gradient_from;
      }
      if (gradient_to) {
        options[pos].monogram_style.gradient_to = gradient_to;
      }
      if (gradient_dir) {
        options[pos].monogram_style.gradient_dir = gradient_dir;
      }
    }

    Store.set("monogramText", this.state.monogramText);
    options[pos].color_variant = title;
    Store.set("options", options);
  }

  render() {
    return !this.isDesignSupported() ? (
      <DesignNotSupported />
    ) : (
      <div
        className="conf_sidebar_tab_content"
        role="tabpanel"
        aria-labelledby="tab_btn_1"
        tabindex="0"
      >
        <div
          className="conf_sidebar_content_animator"
          ref={(el) => (this.animator = el)}
        >
          <div className="conf_sidebar_scroll">
            <h2 class="regular h11 light conf-m-bottom">
              {this.props.translations.des_choose_design}
            </h2>
            <ul>
              {this.state.categories.map((v, i) => (
                <li>
                  <SidebarRow
                    label={this.props.translations[v]}
                    name={v}
                    controls={v}
                    expanded={this.state.currentCategory}
                    key={v + i}
                    onClick={this.binds.selectCategory}
                  />
                </li>
              ))}
            </ul>
          </div>

          <div className="conf_sidebar_innersection" id={this.state.currentCategory}>
            {this.state.categories.length > 1 && (
              <button
                ref="back"
                className="conf_sidebar_inner_head c-light h11 regular"
                onClick={this.binds.closeCategory}
              >
                <img
                  src="/arrow.svg"
                  alt="Go back"
                  className="conf_sidebar_inner_head_arrow"
                />
                <span>
                  {this.props.translations[this.state.currentCategory]}
                </span>
              </button>
            )}
            <div className="conf_sidebar_inner_scroll">
              {this.state.currentCategory === "custom_design" ? (
                <FileInput
                  onFile={this.binds.onFile}
                  file={(this.state.activeOption || {}).file}
                  onCheckboxChange={this.binds.onCustomDesignCheckboxChange}
                  accepted={this.getCustomDesignAccepted()}
                  materialCategory={this.props.materialCategory}
                  onFileRemove={this.binds.removeFileOption}
                  translations={this.props.translations}
                />
              ) : (
                <div className="design_options">
                  <div className="conf_sidebar_intro">
                    {this.state.categories.length > 1 && (
                      <button
                        className="conf_sidebar_inner_head_mobile upcase h11 bold c-dark regular"
                        onClick={this.binds.closeCategory}
                      >
                        <img
                          src={"/arrow_b.svg"}
                          alt="Go Back"
                          className="conf_sidebar_inner_head_arrow"
                        />
                        BACK
                      </button>
                    )}
                    {this.state.currentCategory == "designs" && (
                      <p className="h11 light regular" id="custom-legend-des">
                        {this.props.translations.des_choose_design}
                      </p>
                    )}
                    {this.state.currentCategory == "monogram" && (
                      <p className="h11 light regular" id="custom-legend-des">
                        {this.props.translations.des_choose_monogram}
                      </p>
                    )}
                  </div>
                  <fieldset
                    ref="fset"
                    role="radiogroup"
                    onKeyDown={this.switchTab.bind(this)}
                    aria-labelledby="custom-legend-des"
                  >
                    <legend className="visually-hidden">Designs</legend>
                    {this.state.currentCategory &&
                      this.getContent().map(
                        (v, i) =>
                          v.category == this.props.materialType && (
                            <SidebarOption
                              name={v.title}
                              key={v.id.toString() + i}
                              img={v.img}
                              isDesign={"design"}
                              focus={i == this.state.pos}
                              active={v === this.state.activeOption}
                              onClick={() => this.binds.setOption(v)}
                            />
                          )
                      )}
                  </fieldset>
                </div>
              )}
            </div>
            <div
              ref="color_picker"
              className={"conf_sidebar_color_input " + this.showPicker()}
            >
              <fieldset>
                <legend className="regular medium c-dark h13">
                  {this.props.translations.mat_choose_color}
                </legend>
                {this.showPicker() &&
                  this.state.activeOption.albedo_textures.map((v, i) => (
                    <input
                      key={i}
                      type="radio"
                      className={
                        "conf_sidebar_color_choose " + v.bg + " " + v.active
                      }
                      onClick={this.albedoChange.bind(
                        this,
                        i,
                        v.image,
                        v.name,
                        v.text_color,
                        v.gradient,
                        v.text_color_2,
                        v.gradient_from,
                        v.gradient_to,
                        v.gradient_dir
                      )}
                    />
                  ))}
              </fieldset>
            </div>
            {this.showText() && (
              <div className="conf_sidebar_textinput" ref="texts">
                <fieldset>
                  <legend className="regular c-dark medium h13">
                    {this.props.translations.cp_monogram_notice}
                  </legend>

                  {this.getMonogramCharsLimit("a") > 0 && (
                    <input
                      type="text"
                      ref="input_1"
                      placeholder={this.getMonogramPlaceholder("a")}
                      pattern="![^a-zA-Z0-9]"
                      onChange={(e) => this.binds.monogramTextChange(0, e)}
                      maxLength={this.getMonogramCharsLimit("a")}
                    />
                  )}

                  {this.getMonogramCharsLimit("b") > 0 && (
                    <input
                      type="text"
                      ref="input_2"
                      placeholder={this.getMonogramPlaceholder("b")}
                      onChange={(e) => this.binds.monogramTextChange(1, e)}
                      pattern="![^a-zA-Z0-9]"
                      maxLength={this.getMonogramCharsLimit("b")}
                    />
                  )}
                  
                  {window.location.href.toLowerCase().indexOf("hex") != -1 && (
                    <p className="h12">
                      <br />
                      {this.props.translations.cp_notice}
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                  )}
                </fieldset>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
