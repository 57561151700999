import { qs, qsa } from "../utils/lib";

export default class Newsletter {
    constructor(el) {
        this.el = qs('.widget_subscribe')
        this.form = qs('form',this.el)
        this.input = qs('input',this.el)
        this.title = qs('h2',this.el)
        this.text  = qs('p',this.el)
        this.wrapper = qs('.widget_subscribe_center_form')
        this.init()
    }

    init() {
        this.form.addEventListener("submit",(e) => {
            if (typeof gtag !== "undefined") {
                gtag("event", "newsletter", {
                  event_category: "subscribe",
                  event_label: "subscribe"
                });
              }
            e.preventDefault()
            const data = new FormData(document.getElementById("nl_widget"));
            fetch(this.form.getAttribute("action"), { method: "post", body: data });
            this.text.innerHTML = this.text.dataset.alt
            this.wrapper.classList.add('hidden')
        })
    }
}