import { qs, qsa } from "../../utils/lib";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/all";
import VirtualScroll from "virtual-scroll";

import homeHeroFirstSlide from "./home-hero-firstSlide";
import homeHeroSecondSlide from "./home-hero-secondSlide";
import homeHeroThirdSlide from "./home-hero-thirdSlide";

gsap.registerPlugin(ScrollToPlugin);

export default class homeHeroSlider {
  constructor() {
    //Generic

    this.el = qs(".home-hero");
    this.scroller = new VirtualScroll({
      el: this.el,
    });
    this.prevButton = qs(".prevNext_button_container .prevButton");
    this.nextButton = qs(".prevNext_button_container .nextButton");

    this.firstSlide = new homeHeroFirstSlide();
    this.secondSlide = new homeHeroSecondSlide();
    this.thirdSlide = new homeHeroThirdSlide();

    this.index = 0;
    this.counter = 0;
    this.isAnimating = false;
    this.duration = 4500
    this.tl = gsap.timeline();
  }
  init() {
    if (typeof gtag !== "undefined") {
      gtag("event", "home_view", {
        event_category: "home",
        event_label: "home_view",
      });
    }
    this.firstSlide.firstSlideIn("firstTime");

    this.secondSlide.setupSecondSlide();
    qs(".prevButton svg").classList.add("disabled");
    this.nextButton.addEventListener("click", () => {
      if (this.isAnimating) {
        return false;
      } else {
        this.nextSlide();
        this.isAnimating = true;
      }

      setTimeout(() => {
        this.isAnimating = false;
      }, this.duration);
    });
    this.prevButton.addEventListener("click", () => {
      if (this.isAnimating) {
        return false;
      } else {
        this.prevSlide();
        this.isAnimating = true;
      }

      setTimeout(() => {
        this.isAnimating = false;
      }, this.duration);
    });
  }

  nextSlide() {
    if (this.isAnimating) {
      return true;
    } else {
      this.counter++;
      qsa('.hh_slide', this.el).forEach((slide,i) => i == this.counter ? slide.setAttribute('aria-hidden', 'false') : slide.setAttribute('aria-hidden', 'true'))
      switch (this.index) {
        case 0:
          qs(".prevButton svg").classList.add("disabled");

          if (typeof gtag !== "undefined") {
            gtag("event", "home_second_slide", {
              event_category: "home",
              event_label: "home_second_slide",
            });
          }
          this.firstSlide.firstSlideOut(
            `case0firstNextSlideOut1` + this.counter,
            `case0firstNextSlideOut2` + this.counter
          );
          this.index = 1;
          qs(".prevButton svg").classList.remove("disabled");
          qs(".prevButton").removeAttribute("disabled")
          qs(".nextButton svg").classList.remove("disabled");
          qs(".nextButton").removeAttribute("disabled")

          break;

        case 1:
          if (typeof gtag !== "undefined") {
            gtag("event", "home_third_slide", {
              event_category: "home",
              event_label: "home_third_slide",
            });
          }
          this.secondSlide.secondSlideOut(
            false,
            true,
            `case1NextsecondSlideOut1` + this.counter,
            `case1NextsecondSlideOut2` + this.counter
          );
          this.index = 2;
          qs(".nextButton svg").classList.add("disabled");
          qs(".nextButton").setAttribute("disabled","")
          if (this.index == 2) {
            this.thirdSlide.thirdSlideElement.classList.add("active");
          }

          break;

        default:
          break;
      }
    }
    this.isAnimating = false;
  }

  prevSlide() {
    if (this.isAnimating) {
      return true;
    } else {
      switch (this.index) {
        case 1:
          this.secondSlide.secondSlideOut(
            true,
            false,
            `case1PrevsecondSlideOut1` + this.counter,
            `case1PrevsecondSlideOut2` + this.counter
          );
          setTimeout(() => {
            this.firstSlide.firstSlideIn(`firstSlideIn` + this.counter);
          }, 1500);

          this.index = 0;
          qs(".prevButton svg").classList.add("disabled");
          qs(".prevButton").setAttribute("disabled","")
          break;

        case 2:
          this.thirdSlide.thirdSlideOut();
          this.firstSlide.firstSlideOut(
            `case2PrevfirstSlideOut1` + this.counter,
            `case2PrevfirstSlideOut2` + this.counter
          );
          this.index = 1;
          qs(".prevButton svg").classList.remove("disabled");
          qs(".prevButton").removeAttribute("disabled")
          qs(".nextButton svg").classList.remove("disabled");
          qs(".nextButton").removeAttribute("disabled")
          break;

        default:
          break;
      }
    }
    this.isAnimating = false;
  }

  unlockCarousel() {
    let carousel = qs(".home-hero").offsetHeight;
    if (typeof gtag !== "undefined") {
      gtag("event", "home_scrolled", {
        event_category: "home",
        event_label: "home_scrolled",
      });
    }

    gsap.to(window, {
      scrollTo: { y: carousel },
      duration: 1,
    });

    gsap.to([".navigation_navbar_menu svg", ".navigation_navbar_icons svg"], {
      color: "black",
      duration: 1,
    });
  }
}
