import { qs, qsa } from "../utils/lib"
export default class NewsletterPopup {
    constructor(el) {
        this.el = el
        this.text = qs('#nl_body', this.el)
        this.form = qs('form',this.el)
        this.popup = this.el.parentNode
        this.close_btn = this.el.querySelector('button')
        this.wrapper = qs('#text_wrapper')
    }
    init() {
        this.close_btn.addEventListener('click', this.close.bind(this))
        this.form.addEventListener("submit",(e) => {
            if (typeof gtag !== "undefined") {
                gtag("event", "newsletter", {
                  event_category: "subscribe",
                  event_label: "subscribe"
                });
              }
            e.preventDefault()
            const data = new FormData(document.getElementById("nl_popup"));
            fetch(this.form.getAttribute("action"), { method: "post", body: data });
            this.text.classList.add('center')
            this.text.innerHTML = this.text.dataset.alt
            this.form.innerHTML = ""
        })
    }
    close() {
        this.popup.classList.add('hidden')
    }
}