import { fetchJSON } from "utils";

const body = document.querySelector("body");
export class Api {
  //static BASE_URL = "https://vmoda-fe.herokuapp.com/us/en"
  static BASE_URL = `${window.location.protocol}//${
    window.location.host
  }/${body.getAttribute("data-country")}/${body.getAttribute("data-locale")}`;
  //static BASE_URL = `http://localhost:3000/${body.getAttribute('data-country') }/${body.getAttribute('data-locale')}`
  //static BASE_URL = `https://www.v-moda.com/${body.getAttribute('data-country') }/${body.getAttribute('data-locale')}`
  static getCartURL() {
    return Api.BASE_URL + "/api/create_variant";
  }

  static checkoutUrl() {
    return Api.BASE_URL + "/cart";
  }

  static getModelKey() {
    return window.location.pathname.split("/").pop().split("_")[0];
  }

  static getModelParam() {
    return window.location.href.split("/").pop().split("?").pop();
  }

  static getProduct() {
    const key = Api.getModelKey();
    const param = Api.getModelParam();
    let url = "";
    if (param) {
      url = `${Api.BASE_URL}/api/customizer/${key}.json` + "?" + param;
    } else {
      url = `${Api.BASE_URL}/api/customizer/${key}.json`;
    }

    return fetchJSON(url);
  }
}
