import { qs, qsa, fetchJSON } from "../utils/lib";
import Selectr from "mobius1-selectr";
import Flickity from "flickity";
import Siema from "siema";

export default class AddToCart {
    constructor(el) {
        this.el = el;
        this.data = JSON.parse(this.el.dataset.product);
        this.stock = el.dataset.stock;
        this.out_of_stock = true;
        this.has_custom = el.dataset.has_custom;
        this.custom_cta = el.dataset.custom_cta;
        this.custom_url = el.dataset.custom_slug;
        this.buttons = qsa(".js_color_button");
        this.labels = qsa(".js_color_target");
        this.select_element = qs("#variantForm");
        this.navbar_buttons = qsa(".js_navbar_button");
        this.option_label = "";
        this.current_sku = "";
        this.btn_qty = qs(".navigation_navbar_icons_cart");
        this.qty_span = qsa(".js-cart-qty");
        this.carousel_cont = qs(".pr_details_carousel_inner");
        this.discount_strings = qsa(".js_discount_perc");
        this.alert_string = qs(".stock_alert");
        this.preorder = el.dataset.preorder;
        this.carousel = null;
        this.carousel_wrapper = qs(".pr_details_carousel");
        this.dots = qsa(".dot", this.carousel_wrapper);
        this.prices = qsa(".js_price");
        this.compares = qsa(".js_compare_price");
        this.form = qs("#notify_form");
        this.stock_pop = qs(".notify_me_pop");
        this.stock_pop_close = qs(".notify_me_pop_close");
        this.stock_body = qs(".notify_body");
        this.stock_cta = qs(".notify_cta");
        this.to_google = this.el.dataset.to_google;
        this.current = parseInt(this.el.dataset.startIndex) || 0;
        this.init();
        this.updateLabels();
        if (this.el.dataset.init_carousel != "false") {
            this.initCarousel();
        }
    }
    initCarousel() {
        this.carousel = new Siema({
            selector: this.carousel_cont,
            duration: 200,
            easing: "ease-out",
            perPage: 1,
            startIndex: this.current,
            draggable: true,
            multipleDrag: true,
            threshold: 20,
            loop: true,
            rtl: false,
            onInit: () => { },
            onChange: () => this.activeDots(this.carousel.currentSlide),
        });
    }

    init() {
        if (this.alert_string) {
            this.checkStock();

        }
        if (this.select_element) {
            this.select = new Selectr(this.select_element, {
                searchable: false,
            });
        }
        this.stock_pop_close.addEventListener("click", () => {
            this.stock_pop.classList.remove("active");
        });
        this.form.addEventListener("submit", (e) => {
            e.preventDefault();
            this.submit();
        });
        this.el.addEventListener("click", () => {
            this.addToCart();
        });
        this.navbar_buttons.forEach((el) => {
            el.addEventListener("click", () => {
                this.addToCart();
            });
        });
        this.buttons.forEach((el, i) => {
            el.addEventListener("click", () => {
                this.current = i;
                this.updateLayout();
                this.updateImage(i);
                this.carousel.goTo(i);
                this.checkStock();
            });
        });

        this.dots.forEach((dot, i) => {
            dot.addEventListener("keypress", (e) => {
                if (e.key === "Enter") {
                    this.carousel.goTo(i);
                }
            });
        });

        this.select &&
            this.select.on("selectr.change", (option) => {
                this.current = option.value;
                this.updateImage(this.current);
                this.updateLabels();
                this.checkStock();
            });
    }
    activeDots(index) {
        const dots = this.dots;
        if (dots.length > 0) {
            dots.forEach((dot, i) => {
                if (i == index) {
                    dot.classList.add("is-selected");
                    dot.setAttribute("aria-current", "true");
                } else {
                    dot.classList.remove("is-selected");
                    dot.setAttribute("aria-current", "false");
                }
            });
        }
        const slides = this.carousel.innerElements;
        slides.forEach((slide, i) => {
            if (i == index) {
                slide.setAttribute("aria-hidden", "false");
            } else {
                slide.setAttribute("aria-hidden", "true");
            }
        });
    }
    submit() {
        const addData = {
            email: qs("#notify_email").value,
            product: this.data[this.current],
            url: window.location.pathname,
        };
        const data = {
            data: addData,
        };
        fetchJSON(this.form.getAttribute("action"), {
            method: "POST",
            body: JSON.stringify(data),
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "xmlhttprequest",
            },
        }).then((result) => {
            this.stock_body.innerHTML = this.stock_body.dataset.thanks.replace(
                "xxx",
                this.stock_pop.dataset.thanks +
                " " +
                this.data[this.current]["title"].split("/")[0]
            );
            this.stock_cta.innerHTML = this.stock_cta.dataset.thanks;
            this.stock_cta.addEventListener("click", (e) => {
                e.preventDefault();
                this.stock_pop.classList.remove("active");
            });
        });
    }
    updateLayout() {
        this.buttons.forEach((btn, i) => {
            if (i == this.current) {
                btn.classList.add("active");
                btn.setAttribute("aria-current", "true");
                this.option_label = btn.dataset.variant;
                this.select.setValue(i);
            } else {
                btn.classList.remove("active");
                btn.setAttribute("aria-current", "false");
            }
            this.updateLabels();
        });
    }

    updateLabels() {
        this.labels.forEach((l) => {
            l.innerHTML = this.data[this.current]["title"].split("/")[0];
        });
        if (this.data[this.current]["available"]) {
            this.out_of_stock = true;
            this.updateCartLabel();
            this.updatePrices();
        } else {
            this.out_of_stock = true;
            this.updateCartLabel();
            this.updatePrices();
        }
    }

    updatePrices() {
        const currency = qs("body").dataset.currency;
        this.prices.forEach((el) => {
            el.innerHTML = currency + this.data[this.current]["price"];
        });

        this.compares.forEach((el) => {
            if (
                this.data[this.current]["compare_at"] &&
                parseFloat(this.data[this.current]["compare_at"]) >
                parseFloat(this.data[this.current]["price"])
            ) {
                el.innerHTML = currency + this.data[this.current]["compare_at"];
                this.discount_strings.forEach((string) => {
                    string.innerHTML = `-${this.getPercDiscount(
                        parseFloat(this.data[this.current]["compare_at"]),
                        parseFloat(this.data[this.current]["price"])
                    )}%`;
                    string.classList.remove("hidden");
                });
            } else {
                el.innerHTML = "";
                this.discount_strings.forEach((string) =>
                    string.classList.add("hidden")
                );
            }
        });
    }

    updateCartLabel() {
        if (this.out_of_stock) {
            this.el.innerHTML = this.el.dataset.stock;
            this.el.classList.remove("red");
            this.el.classList.add("black");
            const stock_v = qs("h3", this.stock_pop);
            stock_v.innerHTML = this.data[this.current]["title"].split("/")[0];
            this.navbar_buttons.forEach((el) => {
                el.innerHTML = this.el.dataset.stock;
                el.classList.remove("red");
                el.classList.add("black");
            });
        } else {
            this.el.innerHTML =
                this.has_custom == "true" || this.preorder == "true"
                    ? this.custom_cta
                    : this.el.dataset.add;
            this.el.classList.add("red");
            this.el.classList.remove("black");
            this.navbar_buttons.forEach((el) => {
                el.innerHTML =
                    this.has_custom == "true" || this.preorder == "true"
                        ? this.custom_cta
                        : this.el.dataset.add;
                el.classList.add("red");
                el.classList.remove("black");
            });
        }
    }

    updateImage(index) {
        this.carousel.goTo(index);
    }
    addToCart() {
        if (this.out_of_stock) {
            this.stock_pop.classList.add("active");
            return false;
        }
        if (this.has_custom == "true") {
            window.location =
                this.custom_url + "?color=" + this.data[this.current]["sku"];
            return false;
        } else {
            this.el.innerHTML = this.el.dataset.added;
            setTimeout(() => {
                this.el.innerHTML =
                    this.has_custom == "true" || this.preorder == "true"
                        ? this.custom_cta
                        : this.el.dataset.add;
            }, 1000);

            const addData = {
                id: this.data[this.current]["id"],
                variant: this.data[this.current],
                quantity: 1,
                title: this.el.dataset.title,
                image: this.data[this.current]["image"],
                store_id: this.data[this.current]["store_id"],
                compare_at: this.data[this.current]["compare_at"],
                price: this.data[this.current]["price"],
                product_id: this.el.dataset.id,
            };

            if (this.data.length > 1) {
                addData["properties"] = {
                    variant: this.data[this.current]["title"],
                };
            }

            const body = qs("body").dataset.currenturl;
            const add_url = body + "/cart/add.js";
            const fetch_url = body + "/cart.js";

            fetchJSON(add_url, {
                method: "POST",
                body: JSON.stringify(addData),
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    "X-Requested-With": "xmlhttprequest",
                },
            }).then((result) => {
                fetchJSON(fetch_url, {
                    method: "GET",
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "xmlhttprequest",
                    },
                }).then((result) => {
                    this.btn_qty.classList.remove("hidden");
                    this.qty_span.forEach(
                        (el) => (el.innerHTML = result.item_count)
                    );
                });
            });
        }
    }
    getPercDiscount(a, b) {
        let value = 0;
        if (a > b) {
            value = 100 - (b * 100) / a;
        } else {
            value = 100 - (a * 100) / b;
        }
        return Math.ceil(value);
    }
    checkStock() {
        let stock = this.data[this.current].stock;
        if (stock <= 5 && stock > 0) {
            this.showStock(stock);
        } else {
            this.hiddenStock();
        }
    }
    showStock(stock) {
        this.alert_string.classList.remove("hidden");
        const qty_wrap = this.alert_string.querySelector(".stock_alert_qty");
        qty_wrap.innerHTML = stock;
    }
    hiddenStock() {
        this.alert_string && this.alert_string.classList.add("hidden");
    }
}
