import React, { Component } from 'react'
import { classes, setState, animate, hasProperty, GetCDNImage } from 'utils'
import { filesToLoadFromEvent } from './get_files'


const current_url = document.querySelector('body').getAttribute('data-currenturl');

const LaserDesignInfo = ({ limits }) => (
	<div className="conf_file_input_section">
		<span className="conf_file_input_title regular c-dark h12">
			{limits}&nbsp;&nbsp;
			<a href={current_url + "/support/faq"} target="_blank"><img alt="Go to Faq" src="/product_page.svg" /></a>
		</span>
	</div>
)

const ColorDesignInfo = ({ limits, design_caption, print_caption }) => (
	<div>
	<div className="conf_file_input_section">
		<span className="conf_file_input_title regular c-dark h12">
			{limits}&nbsp;&nbsp;
			<a href={current_url + "/support/faq"} target="_blank"><img alt="Go to Faq" src="/product_page.svg" /></a>
		</span>
	</div>
	<div className="conf_file_input_section">
			<img src=
			{ window.location.href.includes("m-200") ? "/m200_safe2.svg" : "/safe_area_2.svg"}
			 className="conf_custom_design_notice small" />
			 <span className="conf_file_input_title regular c-dark h12">
				{design_caption}
			</span>
			 
	</div>
	<div className="conf_file_input_section">
		<span className="conf_file_input_title regular c-dark h12">
			{print_caption}&nbsp;&nbsp;
			<a href={current_url + "/support/faq"} target="_blank"><img alt="Go to Faq" src="/product_page.svg" /></a>
		</span>
	</div>
	</div>
)

const PrintedDesignInfo = ({ limits, warr })  => (
	<div>

		<div className="conf_file_input_section">
			<span className="conf_file_input_title regular c-dark h12">
				{warr}&nbsp;&nbsp;
			<a href={"/" + current_url + "/support/faq"}><img alt="Go to Faq" src="/product_page.svg" /></a>
			</span>
		</div>

		<div className="conf_file_input_section">
			<img src={GetCDNImage("conf/custom_design_notice.svg")}
			 className="conf_custom_design_notice" />
		</div>

		<div className="conf_file_input_section">
			<span className="conf_file_input_title regular c-dark h12">
				{limits}&nbsp;&nbsp;
			<a href={"/" + current_url + "/support/faq"}><img alt="Go to Faq" src="/product_page.svg" /></a>
			</span>
		</div>

	</div>
)

export default class FileInput extends Component {

	state = {
		file: null,
		blob: null,
		accepted: false,
		accepted_2: false,
		materialCategory: null,
	}

	async componentDidMount() {
		if ( this.props.file ) {

			let blob = null
			const canPreviewFile = this.canPreviewFile(this.props.file)

			if ( canPreviewFile ) {
				blob = await this.readFile(this.props.file)
			}

			this.setState({
				blob,
				canPreviewFile,
				file: this.props.file,
			})
		}

		if ( typeof this.props.accepted !== "undefined" ) {
			this.setState({
				accepted: this.props.accepted
			})
		}

		this.checkCurrentCategory(this.props)
	}
	
	canPreviewFile(file) {
		if ( !file ) return false
		const exts = /\.(svg|pdf|png|jpe?g)/i
		const name = file.name
		return exts.test(name)
	}

	readFile(file) {
		return new Promise(resolve => {
			const reader = new FileReader()
			reader.onload = readerEvent => {
				const blob = readerEvent.target.result
				resolve(blob)
			}
			reader.readAsDataURL(file)
		})
	}

	async setFile(file) {
		let blob = null
		const canPreviewFile = this.canPreviewFile(file)

		if ( canPreviewFile ) {
			blob = await this.readFile(file)
		}

		this.setState({
			blob: blob,
			file: file,
			canPreviewFile,
		})
	}

	onChange(e) {
		const file = filesToLoadFromEvent(e)[0]
		this.setFile(file)
		this.props.onFile(file)
	}

	async onCheckboxChange(e) {
		await setState(this, {
			accepted: e.target.checked
		})
		this.props.onCheckboxChange(this.state.accepted)
	}

	async onCheckboxChangeTwo(e) {
		await setState(this, {
			accepted_2: e.target.checked
		})
	}

	checkCurrentCategory(props) {
		if ( props.materialCategory !== this.state.materialCategory ) {
			this.setState({
				materialCategory: props.materialCategory
			})
		}
	}

	componentWillReceiveProps(newprops) {
		if ( newprops.file !== this.state.file )
			this.setFile(newprops.file)

		this.checkCurrentCategory(newprops)
	}

	render() {
		return (
			<div className="conf_file_input">
				
				{  /color/i.test(this.state.materialCategory) && (
					<ColorDesignInfo   limits={this.props.translations["cp_custom_design_2d"]} 
									   design_caption={this.props.translations["cp_color_upload"]}
									   print_caption={this.props.translations["cp_color_disc"]} />
				)}
				{  /laser/i.test(this.state.materialCategory) && (
					<LaserDesignInfo   limits={this.props.translations["cp_custom_design_2d"]}/>
				)}
				{  /3d/i.test(this.state.materialCategory) && (
					<PrintedDesignInfo warr={this.props.translations["cp_custom_design_2d"]} limits={this.props.translations["cp_custom_design_3d"]} />
				)}

				<div className="conf_design_checkbox h12 c-light regular">
					<label for="cp_approval" 
						   className="c-dark">
					<input id="cp_approval" type="checkbox"
						   checked={this.state.accepted}
						   onChange={this.onCheckboxChange.bind(this)}/>
					<img src={GetCDNImage("conf/check_black.svg")} />
					{this.props.translations["cp_custom_design_check"]}
					</label>
				</div>

				<div className={classes("conf_file_input_btn", { enabled: (this.state.accepted)})}>
					<span className="h12 medium regular c-light upcase">Upload</span>
					<input type="file" role="button" aria-label="Upload a Custom Design" onChange={this.onChange.bind(this)}/>
				</div>
		
				{ this.state.file && (
					<div>
						<div className="conf_input_file_preview h12 regular c-grey">
							<button
							    aria-label="Remove upladed design"
								className="conf_input_remove"
								onClick={() => this.props.onFileRemove(this.state.file)}></button>
							 {
							    this.state.canPreviewFile && this.state.blob
							    ? <img src={this.state.blob} alt="uploaded image preview" aria-label="remove custom design" />
							    : <span>Preview Not Available</span>
							  }
						</div>
						{/*<span className="h12 c-mediumgray regular">{this.state.file.name}</span>*/}
					</div>
				)}

			</div>
		)
	}
}