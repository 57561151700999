import React from "react";
import { classes, fetchJSON, parameterize } from "utils";
import map_style from "./map_style.js";
import _ from "lodash";
import { AccessibleGooglePlacesAutocomplete } from "accessible-google-places-autocomplete";

export default class Video extends React.Component {
  state = {
    map: null,
    stores: [],
    current_stores: [],
    current_store: {},
    markers: [],
    view_store: false,
    reset_markers: false,
    active_el: null,
    l: JSON.parse(this.props.translations),
    start: JSON.parse(this.props.center),
    zoom: parseInt(this.props.zoom),
  };

  async componentDidMount() {
    const base_url = this.props.url;
    const dataset = await fetchJSON(base_url, {
      method: "GET",
      mode: "cors",
      redirect: "follow",
      headers: new Headers({
        "Content-Type": "text/json",
      }),
    });
    const center = this.state.start;
    this.renderMap(center, false, this.state.zoom);
    //this.getUserPosition()
    //this.setAutocomplete()
    const input = document.querySelector("#address_input");
    input.removeAttribute("role");
    const w = document.querySelector(".autocomplete__wrapper");
    w.removeAttribute("role");
    this.setState({
      stores: dataset,
      current_stores: dataset,
      current_store: dataset[0],
    });
  }

  setAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.refs.autocomplete,
      { types: ["geocode"] }
    );
    autocomplete.addListener("place_changed", () => {});
  }

  renderMap(center, bounds, zoom) {
    const map = this.refs.map;
    const show_map = new google.maps.Map(map, {
      disableDefaultUI: true,
      zoomControl: true,
      zoom: 12,
      scaleControl: true,
      scrollwheel: false,
      styles: map_style,
    });
    show_map.addListener(
      "bounds_changed",
      _.debounce(
        () => {
          this.getShops(center);
        },
        300,
        { trailing: true, leading: false }
      )
    );

    google.maps.event.addListener(show_map, "idle", () => {
      this.refs.map.classList.add("loaded");
    });
    if (center) {
      show_map.setZoom(12);
      show_map.setCenter(new google.maps.LatLng(center));
    }
    if (bounds) {
      show_map.fitBounds(bounds);
    }
    if (zoom) {
      show_map.setZoom(zoom);
    }
    this.setState({
      map: show_map,
    });
  }

  getShops(center = false) {
    const map = this.state.map;
    let stores = [];
    this.hideMarkers({});
    this.state.stores.forEach((v) => {
      if (v.lat_lng) {
        if (map.getBounds().contains(v.lat_lng)) {
          stores.push(v);
        }
      }
    });

    if (center) {
      stores.forEach((v) => {
        v.distance =
          google.maps.geometry.spherical.computeDistanceBetween(center, v.lat_lng) / 1000;
      });
      stores = _.sortBy(stores, "distance");
    }

    if (stores.length == 0) {
      map.setZoom(map.zoom - 1);
    }

    this.setState({
      current_stores: stores,
    });
    this.setMarkers();
  }

  setLocation(e) {
    const location = e.geometry.location;
    const center = { lat: location.lat(), lng: location.lng() };
    const bounds = e.geometry.viewport;
    this.setMarkersFromCenter(center, bounds);
  }

  getUserPosition() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.setMarkersFromCenter(pos, false, 12);
      },
      null,
      {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 0,
      }
    );
  }

  setMarkersFromCenter(center, bounds, zoom) {
    this.renderMap(center, bounds, zoom);
  }

  hideMarkers(marker) {
    const markers = this.state.markers;
    const map = this.state.map;
    const pin = this.props.image;

    markers.forEach((v, i) => {
      const lat = v.getPosition().lat();
      const lng = v.getPosition().lng();
      if (lat !== marker.lat && lng !== marker.lng) {
        v.setMap(null);
      }
    });
  }

  showMarkers() {
    this.state.markers.forEach((v, i) => {
      v.setMap(this.state.map);
    });
  }

  setMarkers(center) {
    const markers = [];
    const map = this.state.map;
    const pin = this.props.image;
    const bounds = new google.maps.LatLngBounds();

    this.state.current_stores.forEach((v, i) => {
      if (v.lat_lng) {
        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(v.lat_lng),
          map: map,
          visible: true,
          icon: pin,
        });
        marker.addListener("click", () => {
          this.openStore(v.id);
        });
        markers.push(marker);
        bounds.extend(marker.getPosition());
      }
    });

    if (center) {
      const current = this.props.current;
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(center),
        map: map,
        visible: true,
        icon: current,
      });
      markers.push(marker);
      bounds.extend(marker.getPosition());
    }
    //map.fitBounds(bounds);

    this.setState({
      markers: markers,
    });
  }

  async openStore(id) {
    const store = _.filter(this.state.stores, function (o) {
      return o.id == id;
    })[0];
    const map = this.state.map;
    google.maps.event.clearListeners(map, "bounds_changed");
    map.setZoom(15);
    map.setCenter(new google.maps.LatLng(store.lat_lng));
    await this.setState({
      current_store: store,
      view_store: true,
      active_el: document.activeElement,
    });
    document.querySelector(".map_close").focus();
    this.hideMarkers(store.lat_lng);
  }

  async closeStore() {
    const map = this.state.map;

    map.addListener(
      "bounds_changed",
      _.debounce(
        () => {
          this.getShops();
        },
        300,
        { trailing: true, leading: false }
      )
    );

    map.setZoom(12);
    await this.setState({
      view_store: false,
    });
    setTimeout(() => {
      this.state.active_el.focus();
    }, 1000);
  }

  parseLink(link) {
    return /http/.test(link) ? link : "http://" + link;
  }

  render() {
    return (
      <div className="map_container">
        <div className="map_sidebar">
          <div
            role="region"
            aria-label="Store Information"
            className={classes("map_store_detail", {
              active: this.state.view_store,
            })}
          >
            <button
              ref="close"
              type="button"
              className="map_close"
              onClick={this.closeStore.bind(this)}
            >
              <img src={this.props.close} alt="" aria-label="Close" />
            </button>
            <h3 className="c-dark h9 univers upcase">
              {this.state.current_store.name}
            </h3>
            <p className="h11 hneue lgrey">
              {this.state.current_store.address}
              <br />
              {this.state.current_store.city}
            </p>

            <br />

            <br />
            <span className="full_line bg-dgrey"></span>
            <a
              href={
                "https://www.google.it/maps/place/" +
                this.state.current_store.google_link
              }
              target="_blank"
              className="link c-dark button-typo"
            >
              {this.state.l.retailers_get_directions}
            </a>
            {this.state.current_store.link && (
              <span>
                <br />
                <a
                  href={this.parseLink(this.state.current_store.link)}
                  target="_blank"
                  className="link c-dark button-typo"
                >
                  {this.state.l.retailers_website}
                </a>
              </span>
            )}
            <br />
            <a
              href={"tel:" + this.state.current_store.phone}
              className="link c-dark button-typo"
            >
              Tel: {this.state.current_store.phone}
            </a>
          </div>
          <div
            className={classes("map_stores", { active: this.state.view_store })}
          >
            <div className="map_sidebar_search">
              <label for="address_input" class="body2 bold capitalize">
                {this.state.l.retailers_insert_address}
              </label>
              <p class="visually-hidden">
                Please start typing in the following your address and choose one
                from a suggested result.
              </p>
              <div className="map_sidebar_autocomplete">
                <AccessibleGooglePlacesAutocomplete
                  id="address_input"
                  onConfirm={this.setLocation.bind(this)}
                  googlePlacesApiKey="AIzaSyBBOSxDSg-3qle4wJVBR-j14U7A5lI29Gs"
                />
              </div>
              <button
                onClick={this.getUserPosition.bind(this)}
                className="map_sidebar_near h12 c-dark hneue medium"
              >
                <span>{this.state.l.retailers_find_a_store}</span>
              </button>
            </div>
            {this.state.current_stores.map((v, i) => (
              <div className="map_store" key={i}>
                <h2 className="h10 univers upcase">{v.name}</h2>
                <p className="body2 lgrey">
                  {v.address}
                  <br />
                  {v.city}
                  <br /><br />
                </p>
                <button
                  className="link c-dark button-typo"
                  onClick={this.openStore.bind(this, v.id)}
                >
                  {this.state.l.retailers_more_info}
                </button>
              </div>
            ))}
          </div>
        </div>
        <div className="map_map" ref="map" role="region" aria-label="map"></div>
      </div>
    );
  }
}
