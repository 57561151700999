import React, { Component } from "react";
import { classes, setState } from "utils";

import SideBarContent from "./content";
import SideBarExtras from "./extras";
import Store from "../../store";
import ColorBar from "../alt_color_bar";
import _ from "lodash";

import MaterialLayout from "./new_materials/materials";
import Designs from "./new_materials/designs";
import feather from "feather-icons";

export default class SideBar extends Component {
    state = {
        activeExtra: 0,
        currentCategory: 0,
        printing: null,
        dataset: this.props.data,
        loaded: false,
        categories: [],
        currentCategory: null,
        openOnMobile: this.props.openOnMobile,
        activeOption: null,
        ...Store.getProps(["custom_type", "options"]),
        translations: this.props.translations,
    };

    componentDidMount() {
        let empty = _.filter(this.state.dataset, function (el) {
            return (
                (Object.keys(el.content).length === 0 ||
                    el.label == "screws-cfw3") &&
                el.content.constructor === Object
            );
        });
        if (empty.length > 0) {
            let new_data = _.filter(this.state.dataset, function (el) {
                return el.name != empty[0].name;
            });
            this.setState({
                dataset: new_data,
            });
        }
        const designs = this.props.data[0].content.design["color_printing"];
        const data = _.groupBy(designs, "design_type");
        this.setState({
            data,
            categories: Object.keys(data),
            currentCategory: null,
        });
        // //console.log(this.state.dataset,'aaaa')
        Store.linkState(this, ["custom_type", "options"]);
    }

    getContent() {
        //console.log(this.state.data[this.state.currentCategory]);
        return this.state.data[this.state.currentCategory];
    }

    onOpenInnerSection() {
        //console.log("open");
    }
    onCloseInnerSection() {
        //console.log("open");
    }
    componentWillUnmount() {
        Store.unlinkState(this);
    }

    onExtraClick(extraIndex) {
        this.props.onExtraClick(extraIndex);
        /*
		this.setState({
			activeExtra: extraIndex,
		})
		*/
    }

    onTypeClick(value) {
        this.props.onCustomizationTypeClick(value);
        this.removeOption(1)
        this.setState({
            printing: value,
        });
        const scroll = document.querySelector(".conf_sidebar");
        if (scroll) {
            setTimeout(()=> {
                scroll.scrollTo(0, scroll.scrollHeight);
            },500)
        }
        console.log("bazinga scroll")

    }
    onColorClick(value) {
        this.props.onCustomizationTypeClick(value);
        /*
		this.setState({
			activeExtra: extraIndex,
		})
		*/
    }
    async setDesignCategory(option) {
        this.setState({
            currentCategory: option,
        });
    }
    async setOption(option) {
        //option.price = 0
        const activeOption = Store.action("addMaterialLayoutOption", {
            option: option,
            old_option: this.state.activeOption,
            partName: "Shields",
        });

        await this.setState({
            activeOption,
        });
    }

    getAccessoriesCount() {
        return Object.keys(this.state.accessories).reduce((sum, key) => {
            return sum + this.state.accessories[key].count;
        }, 0);
    }

    componentWillReceiveProps(newprops) {
        const newstate = {};

        if (newprops.activeExtra !== this.state.activeExtra) {
            newstate.activeExtra = newprops.activeExtra;
        }

        if (newprops.openOnMobile !== this.state.openOnMobile) {
            newstate.openOnMobile = newprops.openOnMobile;
        }

        const scroll = document.querySelector('.new_conf_mobile_nav')
        scroll.style.opacity = 1;

        if (Object.keys(newstate).length > 0) this.setState(newstate);
    }

    getOption(prop) {
        const monogram = Store.getProps(["monogramText"]);
        const options = this.state.options;
        let result = "";
        if (prop == "design") {
            const option = options.filter(function (o) {
                return o.custom_type == prop;
            });
            result =
                option.length > 0
                    ? option[0].title + " " + option[0].color_variant
                    : "";
            if (result.length > 0 && option[0].design_type == "monogram") {
                result = "Monogram " + result;
            }
            //console.log(monogram);
        } else {
            const option = options.filter(function (o) {
                return o.custom_type == prop;
            });
            result = option.length > 0 ? option[0].title : "";
        }

        //console.log("ecco il monogram", monogram);
        return result;
    }

    isHex() {
       return (/hexa/.test(window.location.href)) 
    }

    isRemoveShields(){
       return (/lp2/.test(window.location.href)) || (/m-200/.test(window.location.href)) || (/master/.test(window.location.href))
    }

    async removeOption(optionIndex) {
       
        if (optionIndex == 0) {
            this.setState({
                custom_type: "",
                printing: null
            });
        }
        const options = Store.get("options");
        Store.action("removeOption", { option: options[optionIndex] });
        Store.set("monogramText", {});
    }

    checkNav(el) {
        if (window.innerWidth < 1024) {
            return false;
        } else {
            Store.action("resetCamera");
            const els = document.querySelectorAll(".new_conf_option_accordion");
            //console.log(el, "questo");
            els.forEach((acc) => {
                if (acc == el.target.parentNode) {
                    acc.classList.toggle("active");
                } else {
                    acc.classList.remove("active");
                }
            });
        }
    }
    checkMobileNav(index, el) {
        Store.action("resetCamera");
        const els = document.querySelectorAll(".new_conf_option_accordion");
        const navs = document.querySelectorAll(".new_conf_mobile_nav_el");
        //console.log(el, "questo");
        this.props.setActiveStep(index);
        els.forEach((acc, i) => {
            if (i == index) {
                els[i].classList.add("active");
                navs[i].classList.add("active");
            } else {
                els[i].classList.remove("active");
                navs[i].classList.remove("active");
            }
        });
    }
    getName(option) {
        return (
            this.props.component +
            ": " +
            this.props.name +
            " " +
            this.props.color_variant
        );
    }

    isColorSelected() {
        return this.getOption("material").length > 0 ? "enabled" : "";
    }

    isCustomizationTypeSelected() {
        return this.state.printing ? "enabled" : "";
    }

    render() {
        return (
            <div
                className={classes("conf_sidebar", {
                    open: this.state.openOnMobile,
                })}
            >
                <p className="visually-hidden">
                    "Selected options chosen will be displayed further down the
                    page in a region labeled "selected options" where users can
                    remove selected options.".
                </p>
                <div className="relative_container">
                <section className="new_conf_mobile_nav">
                    <div
                        onClick={this.checkMobileNav.bind(this, 0)}
                        className={"new_conf_mobile_nav_el enabled active"}
                    >
                        STEP 0
                    </div>
                    <div
                        onClick={this.checkMobileNav.bind(this, 1)}
                        className={"new_conf_mobile_nav_el enabled"}
                    >
                        STEP 1
                    </div>
                    <div
                        onClick={this.checkMobileNav.bind(this, 2)}
                        className={
                            "new_conf_mobile_nav_el " + this.isColorSelected()
                        }
                    >
                        STEP 2
                    </div>
                    <div
                        onClick={this.checkMobileNav.bind(this, 3)}
                        className={
                            "new_conf_mobile_nav_el " +
                            this.isCustomizationTypeSelected()
                        }
                    >
                        STEP 3
                    </div>
                </section>
                </div>
                <section
                    className={classes("new_conf_option_accordion enabled", {
                        active: this.props.activeStep == 0,
                    })}
                >
                    <div
                        onClick={this.checkNav.bind(this)}
                        className={
                            "navigation5 new_conf_option_accordion_title"
                        }
                    >
                        <span className="navigation5">0. </span>
                        HEADPHONE COLOR{" "}
                        <span className="capitalize body3 no-spacing pointer-none">
                            |{" "}
                        </span>
                        <span className="capitalize body3 no-spacing pointer-none">
                            {this.props.colors[this.props.current].name}
                        </span>
                    </div>
                    <div className="new_conf_option_accordion_cont">
                        <p className="subtitle">
                            Choose the base color of your headphones
                        </p>
                        <ColorBar
                            current={this.props.current}
                            colors={this.props.colors}
                            actionsEnabled={true}
                            onColorClick={this.props.onColorClick}
                            visible={true}
                        />
                    </div>
                </section>

                <section className={"new_conf_option_accordion enabled"}>
                    <div
                        onClick={this.checkNav.bind(this)}
                        className={
                            "navigation5 new_conf_option_accordion_title"
                        }
                    >
                        <span className="navigation5">1. </span>
                        SHIELD COLOR
                        {this.getOption("material").length > 0 && (
                            <>
                                <span className="capitalize body3 no-spacing pointer-none">
                                    {" "}
                                    |{" "}
                                </span>

                                <span
                                    className="capitalize body3 no-spacing"
                                    onClick={() => this.removeOption(0)}
                                >
                                    {this.getOption("material")}
                                    <i
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons.x.toSvg(),
                                        }}
                                    ></i>
                                </span>
                            </>
                        )}
                    </div>
                    {this.props.data && (
                        <div className="new_conf_option_accordion_cont">
                            <p className="subtitle">
                                Choose the base color of your shields
                                {this.isRemoveShields() && ( 
                                <span className="material_warning"><br />Your headphones include matte black shields by default. If you pick a black shield without customization, it will be replaced with the standard pair during checkout, and only the standard shields will be shipped with your headphones.  
                                  </span>
                                   )}
                                   
                            </p>
                            
                            <MaterialLayout
                                extraName={
                                    this.props.data[this.state.activeExtra].name
                                }
                                currentColor={this.props.currentColor}
                                translations={this.props.translations}
                                camera={
                                    this.props.data[this.state.activeExtra]
                                        .cameraProps
                                }
                                data={
                                    this.props.data[this.state.activeExtra]
                                        .content
                                }
                            />
                            <div className="wrapper_mobile">
                                <button
                                    className="new_conf_option_accordion_cont_reset"
                                    onClick={() => this.removeOption(0)}
                                >
                                    Remove shield
                                </button>
                            </div>
                        </div>
                    )}
                </section>
                <section
                    className={
                        "new_conf_option_accordion " + this.isColorSelected()
                    }
                >
                    <div
                        onClick={this.checkNav.bind(this)}
                        className={
                            "navigation5 new_conf_option_accordion_title"
                        }
                    >
                        <span className="navigation5">2. </span>
                        CUSTOMIZATION TYPE
                        {this.state.custom_type && (
                            <>
                                <span className="capitalize body3 no-spacing pointer-none">
                                    {" "}
                                    |{" "}
                                </span>
                                <span
                                    className="capitalize body3 no-spacing"
                                    onClick={() => {
                                        this.setState({
                                            custom_type: "",
                                            printing: null,
                                        });
                                        this.removeOption(1);
                                    }}
                                >
                                    {
                                        this.props.translations[
                                            this.state.custom_type
                                        ]
                                    }
                                    <i
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons.x.toSvg(),
                                        }}
                                    ></i>
                                </span>
                            </>
                        )}
                    </div>
                    <div className="new_conf_option_accordion_cont">
                        <p className="subtitle">
                            Choose between our customization options.
                        </p>
                        <div className="new_conf_sidebar_button_grid subtitle">
                            <div className="new_conf_sidebar_button_grid_col">
                                <button
                                    className={
                                        "body2 " +
                                        (this.state.custom_type ==
                                        "color_printing"
                                            ? "selected"
                                            : "")
                                    }
                                    onClick={() =>
                                        this.onTypeClick("color_printing")
                                    }
                                >
                                    Color Printing
                                </button>
                            </div>
                            { !this.isHex() && (
                                <div className="new_conf_sidebar_button_grid_col">
                                <button
                                    className={
                                        "body2 " +
                                        (this.state.custom_type ==
                                        "laser_engraved"
                                            ? "selected"
                                            : "")
                                    }
                                    onClick={() =>
                                        this.onTypeClick("laser_engraved")
                                    }
                                >
                                    Laser Engraving
                                </button>
                            </div>
                            )}
                            
                        </div>
                        <div className="wrapper_mobile spacer-bottom">
                            <p className="body3">
                                {
                                    this.props.translations[
                                        `${this.state.custom_type}_text`
                                    ]
                                }
                            </p>
                        </div>
                    </div>
                </section>
                <section
                    className={
                        "new_conf_option_accordion " +
                        this.isCustomizationTypeSelected()
                    }
                >
                    <div
                        onClick={this.checkNav.bind(this)}
                        className={
                            "navigation5 new_conf_option_accordion_title"
                        }
                    >
                        <span className="navigation5">3. </span>
                        Design
                        {this.getOption("design").length > 0 && (
                            <>
                                <span className="capitalize body3 no-spacing pointer-none">
                                    {" "}
                                    |{" "}
                                </span>
                                <span
                                    className="capitalize body3 no-spacing"
                                    onClick={() => this.removeOption(1)}
                                >
                                    {this.getOption("design")}
                                    <i
                                        dangerouslySetInnerHTML={{
                                            __html: feather.icons.x.toSvg(),
                                        }}
                                    ></i>
                                </span>
                            </>
                        )}
                    </div>
                    {this.props.data && (
                        <div className="new_conf_option_accordion_cont">
                            <Designs
                                translations={this.props.translations}
                                materialCategory={this.state.custom_type}
                                materialType="Aluminum"
                                onChange={() => {
                                    this.removeOption(1);
                                }}
                                data={
                                    this.props.data[this.state.activeExtra]
                                        .content.design
                                }
                                extraName={
                                    this.props.data[this.state.activeExtra].name
                                }
                                extraLabel={
                                    this.props.data[this.state.activeExtra].name
                                }
                                camera={
                                    this.props.data[this.state.activeExtra]
                                        .cameraProps
                                }
                                onOpenInnerSection={this.onOpenInnerSection}
                                onCloseInnerSection={this.onCloseInnerSection}
                            />
                            <div className="wrapper_mobile spacer-bottom">
                                <button
                                    className="new_conf_option_accordion_cont_reset no_margin"
                                    onClick={() => this.removeOption(1)}
                                >
                                    Remove design
                                </button>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        );
    }
}
