import React from 'react'
import SearchEmpty from './search_empty'
const SearchGrid = (props) => {
    return (
        <div className="search_grid">
            <div className="sr-only" aria-live='assertive'>{props.results.length > 0 ? `${props.results.length} results available` : "No results available"}</div>
            <div className="search_grid_inner">
                <div className="col col-1 text-right">
                    {
                        props.isTyped && props.results.length > 0 && (
                            <div className="search_grid_inner_circle bg-light">
                                <span className="sr-only">results found</span> 
                                <span className="c-dark h6">{props.results.length}</span>
                            </div>

                        )
                    }
                </div>
                {
                    !props.isTyped ? (
                        <div className="col col-10">
                            <h1 className="cn-bd h4 c-light search_grid_inner_title" id="heading">{props.title}</h1>

                            <div className="row no_margin space-between">
                                <h2 className=" h6 upcase col col-auto c-gray space-letter-1">{props.t.headphones}</h2>
                                <div className="col col-auto">
                                    <a href={`${document.querySelector('body').dataset.currenturl}/collections/headphones`} className="arrow-button-view-social">
                                        <div className="arw">
                                            <span className="arw_svg icon-Arrow-back c-light"></span>
                                        </div>
                                        <p className="button-typo c-light">{props.cta}</p>
                                    </a>
                                </div>
                            </div>
                            <div className="row no_margin search_grid_inner_block">
                                {
                                    props.headphones.length > 0 && props.headphones.map((p,i) => (
                                        <div className="col col-4 col-mb-4 search_grid_inner_block_item" key={i}>
                                            <a href={p.link} className="pr_card">
                                                <div className="pr_card_w">
                                                    <div className="pr_card_w_image relative">
                                                        <div className="image_wrapper ratio-1x1">
                                                            <img src={p.image} alt="" className="contain" />
                                                        </div>
                                                    </div>
                                                    <div className="pr_card_w_body">
                                                        <p className="cn-bd h6 text-center c-light">{p.title}</p>
                                                    </div> 
                                                </div>
                                            </a>                
                                        </div>
                                    ))
                                }                        
                            </div>                 
                            <div className="row no_margin space-between">
                                <h2 className="h6 upcase col col-auto c-gray space-letter-1">{props.t.accessories}</h2>
                                <div className="col col-auto">
                                    <a href={`${document.querySelector('body').dataset.currenturl}/collections/accessories`}className="arrow-button-view-social">
                                        <div className="arw">
                                            <span className="arw_svg icon-Arrow-back c-light"></span>
                                        </div>
                                        <p className="button-typo c-light">{props.cta}</p>
                                    </a>                
                                </div>
                            </div>
                            <div className="row no_margin search_grid_inner_block">
                                {
                                    props.accessories.length > 0 && props.accessories.map((p,i) => (
                                        <div className="col col-4  col-mb-4  search_grid_inner_block_item" key={i}>
                                            <a href={p.link} className={"pr_card " + (i == props.accessories.length - 1 ? "last-focusable-element" : "")}>
                                                <div className="pr_card_w">
                                                    <div className="pr_card_w_image relative">
                                                        <div className="image_wrapper ratio-1x1">
                                                            <img src={p.image} alt="" className="contain" />
                                                        </div>
                                                    </div>
                                                    <div className="pr_card_w_body">
                                                        <p className="cn-bd h6 text-center c-light">{p.title}</p>
                                                    </div> 
                                                </div>
                                            </a>                
                                        </div>
                                    ))
                                }
                            </div>                         
                        </div>
                    ) : (
                        props.results.length > 0 ? (
                            <div className="col col-10">
                                <h1 className="cn-bd h4 c-light search_grid_inner_title" id="heading">{props.title}</h1>                            
                                <div className="row no_margin search_grid_inner_block">
                                    {
                                        props.results.map((p,i) => (
                                            <div className="col col-4  col-mb-4  search_grid_inner_block_item" key={i}>
                                                <a href={p.link} className={"pr_card " + (i == props.accessories.length - 1 ? "last-focusable-element" : "")}>
                                                    <div className="pr_card_w">
                                                        <div className="pr_card_w_image relative">
                                                            <div className="image_wrapper ratio-1x1">
                                                                <img src={p.image} alt="" className="contain" />
                                                            </div>
                                                        </div>
                                                        <div className="pr_card_w_body">
                                                            <p className="cn-bd h6 text-center c-light">{p.title}</p>
                                                        </div> 
                                                    </div>
                                                </a>                
                                            </div>
                                        ))
                                    }
                                </div>     
                            </div>                         
                        ) : (
                            <SearchEmpty 
                                string={props.string}
                                sentences={[props.t.no_res_1,props.t.no_res_2]}
                                products={props.no_res_prods}    
                            />
                        )
                    )
                }
            </div>
        </div>       
    )
}
export default SearchGrid