import React, { Component } from "react";
import { classes, hasProperty, GetCDNImage } from "utils";

export const SidebarRow = ({ name, label, img, onClick, currentColor, controls, expanded }) => (
  <button
    className={
      currentColor != 0 && name === "limited_edition"
        ? "conf_sidebar_row h11 regular light  disabled"
        : "conf_sidebar_row h11 regular light"
    }
    aria-controls={controls}
    aria-expanded={(expanded == null ? "false" : "true")}
    onClick={() => onClick(name)}
  >
    {img && <img src={img} className="conf_row_img" />}
    {label ? label : name}
    <img className="arrow" src={"/arrow_b.svg"} alt="" />
  </button>
);

export const SidebarOption = ({
  name,
  img,
  active,
  focus = active,
  onClick,
  isDesign = false,
  hidden = false,
}) => (
  <button
    role="radio"
    
    aria-checked={active ? "true" : "false"}
    className={classes("conf_sidebar_option", { active, isDesign, hidden })}
    onClick={onClick}
  >
    <div className="conf_sidebar_option_content">
      {img && (
        <div className={"conf_option_image " + isDesign}>
          <img src={img} className={"image_conf " + isDesign} alt="" />
        </div>
      )}
      <span className="regular c-dark h13 upcase force_smaller">{name}</span>
    </div>
  </button>
);

export const Tabs = ({
  tabs,
  onClick,
  activeTab,
  designEnabled,
  translations,
  onCloseSidebar,
}) => {
  const classNames = (tab, i) =>
    classes("conf_sidebar_tab bold", {
      active: i === activeTab,
      disabled: tab === "design" && !designEnabled,
    });

  let pos = 0;
  let l = 2;
  const switchTab = (e) => {
    const els = Array.from(document.querySelectorAll(".conf_sidebar_tab"));
    switch (e.key) {
      case "ArrowLeft":
        if (!designEnabled) {
          return false;
        } else if (pos == 0) {
          pos = l - 1;
        } else {
          pos = pos - 1;
        }
        els[pos].focus();
        break;

      case "ArrowRight":
        if (!designEnabled) {
          return false;
        } else if (pos == l - 1) {
          pos = 0;
        } else {
          pos = pos + 1;
        }
        els[pos].focus();
        break;
    }
  };

  return (
    <div className="reset_sidebar_wrap">
      <div onKeyDown={switchTab} className="conf_sidebar_tabs" role="tablist">
        {tabs.length > 1 &&
          tabs.map((tab, i) => (
            <button
              className={classNames(tab, i)}
              role="tab"
              disabled={tab === "design" && !designEnabled}
              aria-selected={i === activeTab ? "true" : "false"}
              tabindex={i === activeTab ? "" : -1}
              key={tab + i}
              id={"tab_btn_" + i}
              onClick={() => onClick(i)}
            >
              {translations[tab]}
            </button>
          ))}
        <button
          className="mobile_close_sidbar c-light"
          onClick={() => onCloseSidebar()}
        >
          <img src="/close_w.svg" />
        </button>
      </div>
      <button
        className="reset_sidebar c-light bold"
        onClick={() => {
          location.reload();
        }}
      >
        RESET
        <img src="/reload.svg" alt="reloads the page" />
      </button>
    </div>
  );
};
