import { qs, qsa } from "../utils/lib"
const AnnouncementBar = (wrap) => {
    const buttons = qsa('.announcement_bar_right_button', wrap)
    const dropdowns = qsa('.announcement_bar_right_button_dropdown', wrap)
    buttons.forEach((button, index) => {
        button.addEventListener('click', () => {
            const dropdown = button.querySelector('.announcement_bar_right_button_dropdown')
            if (dropdown && !dropdown.classList.contains('open')) {
                showDropdown(index)
            } else {
                hideDropdown(index)
            }
        })
    })
    function showDropdown(index) {
        qs('.announcement_bar').classList.add('drop_open')
        dropdowns.forEach((dropdown, i) => {
            if (index == i) {
                dropdown.classList.add('open')
                const arrow = dropdown.parentNode.querySelector('svg')
                const button = dropdown.parentNode
                arrow.classList.add('inverted')
                button.classList.add('active')
                dropdown.setAttribute('aria-expanded', 'true')

            } else {
                dropdown.classList.remove('open')
                const arrow = dropdown.parentNode.querySelector('svg')
                const button = dropdown.parentNode
                arrow.classList.remove('inverted')
                button.classList.remove('active')
                dropdown.setAttribute('aria-expanded', 'false')
            }
        })
    }
    function hideDropdown(index) {
        dropdowns[index].classList.remove('open')
        const arrow = dropdowns[index].parentNode.querySelector('svg')
        const button = dropdowns[index].parentNode
        arrow.classList.remove('inverted')
        button.classList.remove('active')
        qs('.announcement_bar').classList.remove('drop_open')
        dropdowns[index].setAttribute('aria-expanded', 'false')
    }
}
export default AnnouncementBar