import $ from 'jquery'
import '../utils/jquery-ui.min.js'
import '../utils/session.js' 

export default function () {

    $(document).ready(function($) {

        // first thing, hide the registration number div
        $("#reg_number_div").hide();

        // function to grab url parameters (when user visits the page from the QR code with a parameter of ?rn=xxxxxxxxxxxxxx in the URL)
        var getUrlParameter = function getUrlParameter(sParam) {
            var sPageURL = decodeURIComponent(window.location.search.substring(1)),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

            for (i = 0; i < sURLVariables.length; i++) {
                sParameterName = sURLVariables[i].split('=');

                if (sParameterName[0] === sParam) {
                    return sParameterName[1] === undefined ? true : sParameterName[1];
                }
            }
        };

        // Hide divs on start up
        $("#showingthanks").hide();
        $("#myloadingbar").hide();
        $("#reg_form_pinfo").hide();
        $("#theothermessage").hide();
        $("#previewdata").hide();
        $("#uploadpreview_container").hide();
        $("#bestbuycheckbox").hide();
        $("#bbuycheckboxmessage").hide();

        // date picker function
        $(function() {
            $('#purchase_date').datepicker({
                maxDate: 0
            });
        });

        // show checkbox for Best Buy Tech Insider program, if Best Buy is selected in purchase place dropdown
        $("#retailerlist").change(function(event) {
            var userpurchaseplace = $("#retailerlist").val();
            if (userpurchaseplace === "BestBuy.com")
            {
                $("#bestbuycheckbox").show();
            } else {
                $("#bestbuycheckbox").hide();
                $("#bbuycheckboxmessage").hide();
                $("#uploadmessage").html("Upload Receipt/Proof of Purchase<span class='tool-tip-question-mark'>?<span class='tooltiptext'>We accept the following file formats: JPG, PNG and PDF</span></span>");
                // uncheck the box
                $('#bbuycheckbox').prop('checked', false);
            }
        });

        $("#bbuycheckbox").click(function(event) {
            if ($(this).is(":checked"))
            {
                $("#uploadmessage").html("Upload Image of Headphones &amp; Serial Number<span class='tool-tip-question-mark'>?<span class='tooltiptext'>We accept the following file formats: JPG, PNG and PDF</span></span>");
                $("#bbuycheckboxmessage").show();
            } else {
                $("#uploadmessage").html("Upload Receipt/Proof of Purchase<span class='tool-tip-question-mark'>?<span class='tooltiptext'>We accept the following file formats: JPG, PNG and PDF</span></span>");
                $("#bbuycheckboxmessage").hide();
            }
        });

        // Set up Ajax
        function createObject() {
            var request_type;
            var browser = navigator.appName;
            if(browser == "Microsoft Internet Explorer") {
                request_type = new ActiveXObject("Microsoft.XMLHTTP");
            } else {
                request_type = new XMLHttpRequest();
            }
            return request_type;
        }

        var http = createObject();

        // start function where if user inputs a serial number, it will show the registeration option (user did not visit page via QR code) if the serial number is the old format

        $('#serialnumber').on('keyup',function() {

            // set variables of serial number input box and the string's length
            var ns = document.getElementById('serialnumber').value;
            var nsl = ns.length;
            var nsffour = ns.substring(0,4);

            // first let's set the first length to start checking at 7
            if (nsl == 7) {

                // check vmodatracking for serial number lookup of sku

                // Show loading gif
                $('#myloadingbar').show();

                // Create random URL string to prevent caching
                var cacheurl1 = Math.random();
                var ajaxurl1 = 'https://v-modatracking.com/webforms/register/checkserialnumber.cfm?sn='+ns+'&nocache = '+cacheurl1;

                // ajax call
                $.ajax({
                    url:ajaxurl1,
                    success: function(response) {

                        // trim the response
                        var response = response.trim();

                        // reponse is not blank, so let's do something
                        if(response!="") {

                            // No Product Found response from page
                            if(response == "NO SKU FOUND") { 

                                // remove background
                                document.getElementById('serialnumber').style.backgroundImage = "none";

                                // set select value to none
                                $('#product option[value=""]').prop('selected', true);

                                // hide display div
                                $('#myloadingbar').hide();

                                // hide display div
                                $('#reg_form_pinfo').hide();

                                // write no product available message
                                $('#theothermessage').html('<span style="color:#eb8023;">Please contact V-MODA Support (support@v-moda.com) or your authorized reseller for further help in registering this product. Some serial numbers/products have not yet been successfully uploaded into the serial number database.</span>');

                                // show no product available message
                                $('#theothermessage').show();
             
                            } else {

                                // hide no product available message
                                $('#theothermessage').hide();

                                // Set checkmark in text box if success
                                document.getElementById('serialnumber').style.backgroundImage = "url('https://v-modatracking.com/images/register_page/greencheck.png')"; 

                                // Set checkmark position in text box if success
                                document.getElementById('serialnumber').style.backgroundPosition = "95%"; 

                                // Set checkmark repeat pattern in text box if success
                                document.getElementById('serialnumber').style.backgroundRepeat = "no-repeat"; 

                                // select value in the select box based on returned sku
                                $('#product option[value="' + response + '"]').prop('selected', true);

                                // URL to headphones and products
                                var myproducturl = "https://v-modatracking.com/webforms/masterfiles/json/headphones.cfm?nocache=" + cacheurl1;

                                // load json file
                                $.getJSON(myproducturl, function(dataheadphones) {

                                    // json function after loading file 
                                    $.each(dataheadphones.Products, function (key, value) {

                                        // set variables

                                        // x is the product sku name
                                        var x = value.HeadphoneSKU;

                                        // trim the product sku name
                                        var x = x.trim();

                                        if (x == response) {

                                            // y is the warranty length 
                                            var y = value.WarrantyLength;

                                            // z is the full headphone/product name
                                            var z = value.HeadphoneName;

                                            // set hidden form value for warranty period to the WarrantyLength value
                                            $('#register_warrantylength').val(y);

                                            // hide display div
                                            $('#myloadingbar').hide();

                                            // show display div
                                            $('#reg_form_pinfo').show();

                                            // show picture of product
                                            $('#reg_form_pinfo_picture').html('<img src="https://v-modatracking.com/images/register_page/skus/' + x + '.jpg" alt="' + z + '" title="' + z + '" style="width:200px;height:200px;" width="200" height="200" />');
                 
                                            // show sku info
                                            // show warning if it's a crossfade 2 wireless matte black as some rose gold shows that info
                                            $('#reg_form_pinfo_data').html('Product Name: ' + z + '<br />Product SKU: ' + x + '<br />Serial Number: ' + ns + '<br />Warranty Period<span style="color:#eb8023;">**</span>: ' + y + '<br /><span style="font-size:8pt;"><span style="color:#eb8023;">**</span>Please note that the warranty period starts from the date of purchase, <span style="text-decoration:underline;">not</span> the date of registration</span>');
                                        }
                                    });
                                });
                            }
                        }
                    }

                });

            } else if (nsl == 10) {

                // next let's see if those three digits equal "110"

                // if first three characters do equal "110", then show the registration div
                if (nsffour == 1100 || nsffour == 1101 || nsffour == 1400) {

                    // show reg number div 
                    $("#reg_number_div").show();

                } else {

                    // hide div and reset reg number to nothing
                    $("#reg_number_div").hide();
                    $("#reg_number_div").val('');

                }
            } else {

                // hide div and reset reg number to nothing
                document.getElementById('serialnumber').style.backgroundImage = "none";
                $("#reg_number_div").hide();
                $("#reg_number_div").val('');
                $('#product option[value=""]').prop('selected', true);
                $('#myloadingbar').hide();
                $('#reg_form_pinfo').hide();
                $('#reg_form_pinfo_picture').html('');
                $('#reg_form_pinfo_data').html('');

            }
        });

        // function for displaying product information if available upon a successful registration number being inputted
        function runregisterationnumber() {
     
            // Set variable of the keystrokes from user in the serial number box
            var q = document.getElementById('registrationnumber').value; 
            var ql = q.length;

            // if registration number inputted is less than 14 characters, don't run function
            if (ql < 14) {

                // hide green check (or red x) from input box
                document.getElementById('registrationnumber').style.backgroundImage = "none";

                // set select value to none
                $('#product option[value=""]').prop('selected', true);

                // hide display div
                $('#myloadingbar').hide();

                // reset picture html
                $("#reg_form_pinfo_picture").html("");

                // reset sku data html
                $("#reg_form_pinfo_data").html("");

                // hide display div
                $('#reg_form_pinfo').hide();

                // hide no product available message
                $("#theothermessage").html("");

                // hide no product available message
                $('#theothermessage').hide();

            }

            // run function if registration number is gte 14 characters after being inputted by user
            else {
         
                // Show loading gif
                $('#myloadingbar').show();

                // Create random URL string to prevent caching
                var cacheurl = Math.random();
                var ajaxurl = 'https://v-modatracking.com/webforms/register/checkregistrationnumber.cfm?rn='+q+'&nocache = '+cacheurl;

                // ajax call
                $.ajax({
                    url:ajaxurl,
                    success: function(response){

                        // trim the response
                        var response = response.trim();

                        // reponse is not blank, so let's do something
                        if(response!="") {

                            // No Product Found response from page
                            if(response == "INVALID VERCODE. PLEASE CHECK AND RETRY.") { 

                                // hide green check (or red x) from input box
                                document.getElementById('registrationnumber').style.backgroundImage = "none";

                                // set select value to none
                                $('#product option[value=""]').prop('selected', true);

                                // hide display div
                                $('#myloadingbar').hide();

                                // hide display div
                                $('#reg_form_pinfo').hide();

                                // write no product no product available message
                                $('#theothermessage').html('<span style="color:#eb8023;">Please contact V-MODA Support (support@v-moda.com) or your authorized reseller for further help in registering this product. Some serial numbers/products have not yet been successfully uploaded into the serial number database.</span>');

                                // show no product available message
                                $('#theothermessage').show();
             
                            }

                            // product not found response
                            else if (response == "PRODUCT RECORD NOT FOUND") {

                                // hide green check (or red x) from input box
                                document.getElementById('registrationnumber').style.backgroundImage = "none";

                                // set select value to none
                                $('#product option[value=""]').prop('selected', true);

                                // hide display div
                                $('#myloadingbar').hide();

                                // hide display div
                                $('#reg_form_pinfo').hide();

                                // write no product no product available message
                                $('#theothermessage').html('<span style="color:#eb8023;">Please contact V-MODA Support (support@v-moda.com) or your authorized reseller for further help in registering this product. Some serial numbers/products have not yet been successfully uploaded into the serial number database.</span>');

                                // show no product available message
                                $('#theothermessage').show();
                            }

                            // Product SKU and Serial is returned
                            else if (response.substring(0,4) == 1100) {

                                var serialnumberstring = response.substr(0,10);
                                var productskunamestring = response.substr(10);

                                // hide no product available message
                                $('#theothermessage').hide();

                                // Set checkmark in text box if success
                                document.getElementById('registrationnumber').style.backgroundImage = "url('https://v-modatracking/images/register_page/greencheck.png')"; 

                                // Set checkmark position in text box if success
                                document.getElementById('registrationnumber').style.backgroundPosition = "95%"; 

                                // Set checkmark repeat pattern in text box if success
                                document.getElementById('registrationnumber').style.backgroundRepeat = "no-repeat"; 

                                // set value of serial number text box 
                                $('#serialnumber').val(serialnumberstring);

                                // select value in the select box based on returned sku
                                $('#product option[value="' + productskunamestring + '"]').prop('selected', true);

                                // URL to headphones and products
                                var myproducturl = "https://v-modatracking.com/webforms/masterfiles/json/headphones.cfm?nocache=" + cacheurl;

                                // load json file
                                $.getJSON(myproducturl, function(dataheadphones) {

                                    // json function after loading file 
                                    $.each(dataheadphones.Products, function (key, value) {

                                        // set variables

                                        // x is the product sku name
                                        var x = value.HeadphoneSKU;

                                        // trim the product sku name
                                        var x = x.trim();

                                        if (x == productskunamestring) {

                                            // y is the warranty length 
                                            var y = value.WarrantyLength;

                                            // z is the full headphone/product name
                                            var z = value.HeadphoneName;

                                            // set hidden form value for warranty period to the WarrantyLength value
                                            $('#register_warrantylength').val(y);

                                            // hide display div
                                            $('#myloadingbar').hide();

                                            // show display div
                                            $('#reg_form_pinfo').show();

                                            // show picture of product
                                            $('#reg_form_pinfo_picture').html('<img src="https://v-modatracking.com/images/register_page/skus/' + productskunamestring + '.jpg" alt="' + z + '" title="' + z + '" style="width:200px;height:200px;" width="200" height="200" />');
                 
                                            // show sku info
                                            // show warning if it's a crossfade 2 wireless matte black as some rose gold shows that info
                                            if (x == 'XFBT2-MBLACKM') {
                                                $('#reg_form_pinfo_data').html('Product Name: ' + z + '<span style="color:yellow;">*</span><br />Product SKU: ' + x + '<br />Serial Number: ' + serialnumberstring + '<br />Warranty Period<span style="color:#eb8023;">**</span>: ' + y + '<br /><span style="font-size:8pt;"><p><span style="color:yellow;">*</span>If you registered a Crossfade 2 Wireless - Rose Gold edition (XFBT2-RGOLDB) and it shows a Matte Black (XFBT2-MBLACKM) instead, then please be aware this is a known issue for certain serial numbers. Please select "Crossfade 2 Wireless - Rose Gold" from the select/drop down box and continue as normal. If you are registering Crossfade 2 Wireless Matte Black, then please ignore this and continue.</p><p><span style="color:#eb8023;">**</span>Please note that the warranty period starts from the date of purchase, <span style="text-decoration:underline;">not</span> the date of registration</p></span>');
                                            } else {
                                                $('#reg_form_pinfo_data').html('Product Name: ' + z + '<br />Product SKU: ' + x + '<br />Serial Number: ' + serialnumberstring + '<br />Warranty Period<span style="color:#eb8023;">**</span>: ' + y + '<br /><span style="font-size:8pt;"><span style="color:#eb8023;">**</span>Please note that the warranty period starts from the date of purchase, <span style="text-decoration:underline;">not</span> the date of registration</span>');
                                            }
                                        }
                                    });
                                });
                            }

                            else {

                                // hide green check (or red x) from input box
                                document.getElementById('registrationnumber').style.backgroundImage = "none";

                                // set select value to none
                                $('#product option[value=""]').prop('selected', true);

                                // hide display div
                                $('#myloadingbar').hide();

                                // hide display div
                                $('#reg_form_pinfo').hide();

                                // write no product no product available message
                                $('#theothermessage').html('<span style="color:#eb8023;">Please contact V-MODA Support (support@v-moda.com) or your authorized reseller for further help in registering this product. Some serial numbers/products have not yet been successfully uploaded into the serial number database.</span>');

                                // show no product available message
                                $('#theothermessage').show();
                            }
                        }
                    },
                    type: 'GET'
                })
            }
        }

        // function to read the file the customer uploads as their receipt or proof of purchase
        function readImage(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();

                // get file extension from input value
                var myfilename = $("#myreceipt").val();

                // get the last characters from after the last "."
                var myextension = myfilename.replace(/^.*\./, '');

                // convert it to lowercase
                var myextension = myextension.toLowerCase();

                // determine MB size
                var myfilesizemb = ~~((input.files[0].size/1024)/1024) +' MB';

                // determine KB size
                var myfilesizekb = ~~(input.files[0].size/1024) +' KB';

                // show the preview data to populate with above variables
                $("#previewdata").show();

                // populate the preview div with the variables above
                $("#previewdata").html("File extension: <span class='previewanswer'>" + myextension + "</span><br />File type: <span class='previewanswer'>" + input.files[0].type + "</span><br />File size: <span class='previewanswer'>" + myfilesizemb + " (" + myfilesizekb + ")</span><br />File name: <span class='previewanswer'>" + input.files[0].name + "</span>");
                
                // check and make sure file is either JPG/JPEG or PNG
                if(myextension == "jpg" || myextension == "jpeg" || myextension == "png") {

                    // hide the pdf iframe viewer
                    $("#pdfviewer").hide();

                    // show the image preview div container
                    $("#uploadpreview_container").show();

                    // show the image preview div
                    $("#uploadpreview").show();

                    // read the file
                    reader.onload = function(e) {

                        // populate the image preview with the image the customer uploaded
                        $("#uploadpreview").html("<img src='"+ e.target.result +"' />");
                    }

                // if file is not JPG/JPGE or PNG, see if it's a PDF
                } else if (myextension == "pdf") {

                    // show image preview container
                    $("#uploadpreview_container").show();

                    // hide image preview div
                    $("#uploadpreview").hide();

                    // create pdf file url and object
                    var pdffile=document.getElementById("myreceipt").files[0];
                    var pdffile_url=URL.createObjectURL(pdffile);

                    // show pdf iframe
                    $("#pdfviewer").show();

                    // populate pdf iframe with the pdf file preview
                    $("#pdfviewer").attr("src",pdffile_url);

                // if file is not JPG/JPEG or PNG or PDF, then it's invalid and show the user it's wrong
                } else {

                    // hide pdf iframe viewer
                    $("#pdfviewer").hide();

                    // hide image preview container
                    $("#uploadpreview_container").hide();

                    // hide image preivew div
                    $("#uploadpreview").hide();

                    // populate preview data div with the warning message
                    $("#previewdata").html("<span class='previewanswer'>File format is not suppoted. Please upload a JPG, PNG, GIF, BMP or PDF file.</span>")
                }
                reader.readAsDataURL(input.files[0]);
            }
        }

        // grab url parameter rn
        var urlrn = getUrlParameter('rn');

        // if the URL parameter 'rn' is present, start processing it
        // make sure url.rn is not blank
        if (urlrn != "") {
            // make sure url.rn is numeric
            if($.isNumeric(urlrn)) {
                // make sure url.rn is gte 16 digits in length
                if(urlrn.length >=16) {
                    // grab the first two digits of the url.rn string
                    var startofurlrn = urlrn.substring(0,2);
                    // make sure the those first two digits are "92" (this comes direct from the QR code)
                    if (startofurlrn == 92) {
                        // if the first two digits are "92", then grab the remaining characters and forget the "92"
                        var urlrn = urlrn.substring(2);
                        // make sure the remaining string is the correct length, gte 14 and lte 16
                        if(urlrn.length >= 14 && urlrn.length <= 16) {
                            // if the url.rn passes all those rules, populate the registration number input field
                            $("#registrationnumber").val(urlrn);
                            // run the registration number look up function automatically
                            runregisterationnumber();
                        }
                    }
                }
            }
        }

        // Create random URL string to prevent caching
        var cacheme = Math.random();

        // URL to headphones and products
        var myproducturl = "https://v-modatracking.com/webforms/masterfiles/json/headphones.cfm?nocache=" + cacheme;

        // URL to retailers
        var myretailerurl = "https://v-modatracking.com/webforms/masterfiles/json/endorseyourself_retailers.cfm?nocache=" + cacheme;

        // Get JSON Data for headphone select box
        $.getJSON(myproducturl, function(data) {
            $.each(data.Products, function(key, value) {
                if (value.Codex == 1) {
                    $("#product").append($('<option></option>').val(value.HeadphoneSKU).html(value.HeadphoneName)); //Append desktop select box
                }
            });
        });

        // Get JSON Data for Retailers on Desktop
        $.getJSON(myretailerurl, function(data) { // get json function to read file
            for (var x = 0, len2 = data.retailers[0].continents.length; x < len2; ++x) {

                // Continent OptGroup
                var myContinent = data.retailers[0].continents[x].continentName;
                var myOptGroupContinent = "<optgroup class='continentlabel' label='" + myContinent + "'>";
                var $optgroup = $(myOptGroupContinent);
                $("#retailerlist").append($optgroup);
                for (var y = 0, len3 = data.retailers[0].continents[x].countries.length; y < len3; ++y) {

                    // Country OptGroup
                    var myCountry = data.retailers[0].continents[x].countries[y].countryName;
                    var myOptGroup = "<optgroup class='countrylabel' label='" + myCountry + "'>";
                    var $optgroup = $(myOptGroup);
                    $("#retailerlist").append($optgroup);
                    for (var z = 0, len4 = data.retailers[0].continents[x].countries[y].retailer.length; z < len4; ++z) {

                        // Retailer Options
                        var myRetailer = data.retailers[0].continents[x].countries[y].retailer[z].retailerName;
                        var op = "<option value='" + myRetailer + "'>" + myRetailer + "</option>";
                        $optgroup.append(op);
                    }
                }
            }
        });

        // get IP address
        $.get('https://jsonip.com/', function(r) {
            $('#register_ipaddress').val(r.ip);
        });

        // keyup function for registration number
        $('#registrationnumber').on('keyup',runregisterationnumber);

        // upload file action
        $("#myreceipt").change(function() {
            readImage(this);
        });

        // validate form fields before submitting
        $('#vmoda_register_form').submit(function() {

            // hide warnings
            $("#warranty_number_warning").hide();
            $("#registrationnumber_warning").hide();
            $("#registrationnumber_valid_warning").hide();
            $("#serialnumber_warning").hide();
            $("#serialnumber_valid_warning").hide();
            $("#product_warning").hide();
            $("#firstname_warning").hide();
            $("#lastname_warning").hide();
            $("#email_warning").hide();
            $("#email_valid_warning").hide();
            $("#wherepurchased_warning").hide();
            $("#vmoda_order_number_warning").hide();
            $("#vmoda_order_number_valid_warning").hide();
            $("#retailerlist_warning").hide();

            var snl = $("#serialnumber").val().length

            // make sure registration number is not blank, if serial number is 10 digits
            if (snl == 10) {
                if ($('#registrationnumber').val() == '') {
                    // show warning
                    $("#registrationnumber_warning").show();
                    $('#registrationnumber').focus();
                    $('#registrationnumber').select();
                    return false;
                // reg number is not blank, so make sure it's numeric
                } else {
                    // check if not numeric
                    if (!$.isNumeric($('#registrationnumber').val())) {
                        $("#registrationnumber_warning").hide();
                        $("#registrationnumber_valid_warning").show();
                        $('#registrationnumber').focus();
                        $('#registrationnumber').select();
                        return false;
                    } else {
                        // reg number is numeric, now make sure it's the right length (gte 14 and lte 16)
                        if ($("#registrationnumber").val().length < 14 || $("#registrationnumber").val().length > 16) {
                            $("#registrationnumber_warning").hide();
                            $("#registrationnumber_valid_warning").show();
                            $('#registrationnumber').focus();
                            $('#registrationnumber').select();
                            return false;
                        } else {
                            $("#registrationnumber_warning").hide();
                            $("#registrationnumber_valid_warning").hide();
                        }
                    }
                }
            }

            // make sure serial number is not left blank
            if ($('#serialnumber').val() == '') {

                $("#serialnumber_warning").show(); // show warning
                $('#serialnumber').focus();
                $('#serialnumber').select();
                return false;

            } else { // not blank

                if (snl == 7) { // sn length is 7 digits

                    if ($.isNumeric($('#serialnumber').val())) { // 7 digit sn is numeric

                        $("#serialnumber_warning").hide();
                        $("#serialnumber_valid_warning").show();
                        $('#serialnumber').focus();
                        $('#serialnumber').select();
                        return false;

                    } else { // 7 digit sn is NOT numeric

                        $("#serialnumber_warning").hide();
                        $("#serialnumber_valid_warning").hide();

                    }

                } else if (snl == 10) { // sn length is 10

                    if (!$.isNumeric($('#serialnumber').val())) { // 10 digit sn is NOT numeric

                        $("#serialnumber_warning").hide();
                        $("#serialnumber_valid_warning").show();
                        $('#serialnumber').focus();
                        $('#serialnumber').select();
                        return false;

                    } else { // 10 digit sn is numeric

                        var serialnumfirst = $('#serialnumber').val();
                        var serialnumfirstfour = serialnumfirst.substring(0, 4);

                        if (serialnumfirstfour == 1100 || serialnumfirstfour == 1101 || serialnumfirstfour == 1400) { // 10 digit numeric sn starts with either 1100 or 1100

                            $("#serialnumber_warning").hide();
                            $("#serialnumber_valid_warning").hide();

                        } else { // 10 digit sn does not start with 1100 or 1101 or 1400

                            $("#serialnumber_warning").hide();
                            $("#serialnumber_valid_warning").show();
                            $('#serialnumber').focus();
                            $('#serialnumber').select();
                            return false;

                        }

                    }

                } else { // sn length is not 7 or 10 digits

                    $("#serialnumber_warning").hide();
                    $("#serialnumber_valid_warning").show();
                    $('#serialnumber').focus();
                    $('#serialnumber').select();
                    return false;

                }

            }

            // make sure product is entered
            if ($('#product').val() == '') {
                $("#product_warning").show();
                $('#product').focus();
                $('#product').select();
                return false;
            } else {
                $("#product_warning").hide();
            }

            // make sure first name is entered
            if ($('#firstname').val() == '') {
                $("#firstname_warning").show();
                $('#firstname').focus();
                $('#firstname').select();
                return false;
            } else {
                $("#firstname_warning").hide();
            }

            // make sure last name is entered
            if ($('#lastname').val() == '') {
                $("#lastname_warning").show();
                $('#lastname').focus();
                $('#lastname').select();
                return false;
            } else {
                $("#lastname_warning").hide();
            }

            // make sure email is entered
            if ($('#email').val() == '') {
                $("#email_warning").show();
                $('#email').focus();
                $('#email').select();
                return false;
            } else {
                // make sure email is a valid address
                if ($('#email').val().indexOf('@') == -1 || $('#email').val().indexOf('.') == -1) {
                    $("#email_warning").hide();
                    $("#email_valid_warning").show();
                    $('#email').focus();
                    $('#email').select();
                    return false;
                } else {
                    $("#email_warning").hide();
                    $("#email_valid_warning").hide();
                }   
            }

            // make sure retailer list was chosen from
            if ($("#retailerlist").val() == '') {
                $("#retailerlist_warning").show();
                return false;
            } else {
                $("#retailerlist_warning").hide();
            }

            // if the user selected from the dropdown list, then make sure they also choose a date of purchase
            if ($("#purchase_date").val() == '') {
                $("#retailerlist_warning").hide();
                $("#purchase_date_warning").show();
                $('#purchase_date').focus();
                $('#purchase_date').select();
                return false;
            } else {
                $("#retailerlist_warning").hide();
                $("#purchase_date_warning").hide();         
            }
            
            // make sure reCaptcha was at least fill out (validation will occur on server side)
            var gresponse = grecaptcha.getResponse();
            if(gresponse.length == 0) {
                alert("You did not fill out the reCatpcha");
            }

            // Hide form on page after submission
            $("#vmoda_register_form").hide();

            // shows thank you container
            $("#showingthanks").show(); 

            // sets height of iframe upon visibility
            $('#register_iframe').css({"height": "925px"}); 

            // scroll to top of thank you div
            $('html,body').animate({
                scrollTop: $("#showingthanks").offset().top - 20
            });

        });

    });

}