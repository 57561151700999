import { qs, qsa } from "../utils/lib";
import { gsap, Power2 } from "gsap";

const tl = gsap.timeline();

export default class FaqAccordion {
  constructor(el) {
    this.accordion = el;
    this.plusImg = qs(".faq_accordion_icon_plus", el);
    this.minusImg = qs(".faq_accordion_icon_minus", el);
    this.pList = qs(".faq_accordion_text_p", el);
    this.status = true;
  }

  init() {
    qs('button', this.accordion).addEventListener("click", (evt) => {
        this.plusImg && this.plusImg.classList.toggle("hide");
        this.minusImg && this.minusImg.classList.toggle("hide");
      this.pList.classList.toggle("hide")
      this.showHide(this.pList);
      this.status = !this.status;
    });
  }

  showHide(element) {
    const button = element.previousElementSibling.querySelector('button')
    button.setAttribute('aria-expanded',this.status ? "true" : "false")
    tl.to(element, {
      height: this.status ? "auto" : 0,
      autoAlpha: this.status ? 1 : 0,
      duration: 0.2,
      paddingTop: this.status ? "16px" : "0px",
      ease: Power2.easeInOut,
    });
  }
}
