import React, { Component } from "react";
import { classes, setState, arrayIndex, animate } from "utils";
import trap from "../../trap.js";

export default class Box extends Component {
  duration = 0.8;
  ease = "";

  constructor() {
    super(...arguments);
    this.binds = {
      onClose: this.onClose.bind(this),
    };
  }

  static defaultProps = {
    fixed: false,
  };

  componentDidMount() {
    animate(
      this.container,
      this.duration,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        ease: this.ease,
      }
    );

    animate(
      this.popup,
      this.duration,
      {
        y: 4,
      },
      {
        y: 0,
        ease: this.ease,
      }
    );
    const el = document.querySelector(".conf_box_container");
    trap(el);
  }

  async onClose() {
    animate(
      this.container,
      this.duration,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: this.ease,
      }
    );

    await animate(
      this.popup,
      this.duration,
      {
        y: 0,
      },
      {
        y: 4,
        ease: this.ease,
      }
    );

    if (typeof this.props.onClose === "function") this.props.onClose();
  }

  render() {
    return (
      <div
        role="dialog"
        aria-modal="true"
        aria-labelledby="title"
        className={classes("conf_box_container", { fixed: this.props.fixed })}
        ref={(el) => (this.container = el)}
        style={{ opacity: 0 }}
      >
        <div
          className="conf_box_background_close"
          aria-label="Close"
          onClick={this.binds.onClose}
        ></div>

        <div
          className={classes("conf_box", {
            dark: this.props.dark,
            small: this.props.small,
          })}
          ref={(el) => (this.popup = el)}
        >
          {this.props.title && (
            <div id="title" className="conf_box_head ">
              {this.props.titleImg && <img src={this.props.titleImg} />}
              <h1 className="regular upcase bold h10 regular">
                {this.props.title}
              </h1>

              <button
                className="close_accessories close"
                onClick={this.binds.onClose}
                aria-label="Close"
              ></button>
            </div>
          )}

          {this.props.children}
        </div>
      </div>
    );
  }
}
