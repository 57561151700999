import $ from 'jquery'

export default function () {

	$(document).ready(function($) {
		// hide divs on startup
		$("#showingthanks").hide();
        $("#show_canada_province").hide();
        $("#show_australia_state").hide();
        $("#show_mexico_state").hide();
        $("#other_region").hide();
        $("#current_product_info").hide();
        $("#upgraded_product_info").hide();

        //Show states/provinces/regions based on selected Country
        $("#country").change(function(event) {
            var usercountry = $("#country").val();
            if (usercountry === "US")
            {
                $("#show_us_state").show();
                $("#show_canada_province").hide();
                $("#show_australia_state").hide();
                $("#show_mexico_state").hide();
                $("#other_region").hide();
            }
            else if (usercountry === "Canada")
            {
                $("#show_us_state").hide();
                $("#show_canada_province").show();
                $("#show_australia_state").hide();
                $("#show_mexico_state").hide();
                $("#other_region").hide();
            }
            else if (usercountry === "Australia")
            {
                $("#show_us_state").hide();
                $("#show_canada_province").hide();
                $("#show_australia_state").show();
                $("#show_mexico_state").hide();
                $("#other_region").hide();
            }
            else if (usercountry === "Mexico")
            {
                $("#show_us_state").hide();
                $("#show_canada_province").hide();
                $("#show_australia_state").hide();
                $("#show_mexico_state").show();
                $("#other_region").hide();
            }
            else
            {
                $("#show_us_state").hide();
                $("#show_canada_province").hide();
                $("#show_australia_state").hide();
                $("#show_mexico_state").hide();
                $("#other_region").show();
            }
        });

        // set nextdropdown variable for upgrade headphone selection
        let nextdropdown = $("#myupgrademodel");

        // empty the dropdown
        nextdropdown.empty();

        // append the title option to next dropdown 
        nextdropdown.append('<option selected="true" disabled>Choose A Product:</option>');
        nextdropdown.prop('selectedIndex',0);

        // Start of Price Changing Rules on Desktop

        // set current discount amount
        var currentdiscountamount = 0.20;

        // Create random URL string to prevent caching
        var cacheme = Math.random();

        // URL to JSON file of headphones/earbud
        // var myjsonurl = "/ilp.json?" + cacheme;

        var myjsonurl = "https://www.v-modatracking.com/webforms/masterfiles/json/ilp_jsonfile.cfm?" + cacheme;

        // change info based on the model you select
        $("#mymodel").change(function(event) {

            // empty the next dropdown first
            nextdropdown.empty();

            // append the title option to next dropdown 
            nextdropdown.append('<option selected="true" disabled>Choose A Product:</option>');
            nextdropdown.prop('selectedIndex',0);

            // set variable of current headphone you own/selected
            var productselected = $("#mymodel").val();

            $("#discount_value").html('');
	
    	    // hide upgraded headphone price div
            $("#upgraded_product_info").hide();
            
            // Start of different SKU Price Rules

            // set type of headphone selected from current dropdown
            if (productselected === "XFL2V-MBLACK" 
                || productselected === "XFLPR-GUNBLACK" 
                || productselected === "XFLPR-NERO" 
                || productselected === "XFLPR-ROUGE" 
                || productselected === "XFLPR-PWHITE" 
                || productselected === "XFLPR-PHCHROME" 
                || productselected === "XFLPR-STEALTH"
                || productselected === "XS-MBLACK" 
                || productselected === "XS-WSILVER"
                || productselected === "M-100-AIRA"
                || productselected === "M-100-U-MBLACK" 
                || productselected === "M-100-U-SHADOW" 
                || productselected === "M-100-U-WSILVER" 
                || productselected === "M-100-U-PHCHROME" 
                || productselected === "M-100MA-MB"
                || productselected === "M-80-U-SHADOW" 
                || productselected === "M-80-U-WSILVER" 
                || productselected === "V-80"
                || productselected === "XFBT-GUNBLACK" 
                || productselected === "XFBT-WSILVER" 
                || productselected === "XFBT-ROUGE" 
                || productselected === "XFBT-PHCHROME"
                || productselected === "XFBT2-RGOLDB" 
                || productselected === "XFBT2-MBLACKM" 
                || productselected === "XFBT2-MWHITE"
                || productselected === "XFBT2A-RGOLDB" 
                || productselected === "XFBT2A-MBLACK" 
                || productselected === "XFBT2A-MWHITE"
                || productselected === "M200-BK") 
            {
                var headphonetype = "headphone";
            } 
            else {
                var headphonetype = "earbud";
            }

            // set selection options for upgrade model based off JSON file
            $.getJSON(myjsonurl, function(data) {
                $.each(data.Products, function(key, value) {
                    // make sure headphone visibility is currently set to "1" in order for it to show up as an option to select
                    if (headphonetype === "earbud") {
                        if (value.Earbud == 1) {
                            //Append desktop select box
                            nextdropdown.append($('<option></option>').val(value.HeadphoneSKU).html(value.Headphone));
                        }
                    } else if (headphonetype === "headphone") {
                        if (value.Earbud == 0) {
                            //Append desktop select box
                            nextdropdown.append($('<option></option>').val(value.HeadphoneSKU).html(value.Headphone));
                        }
                    }
                });
            });

            // user selects upgrade model from second select box
            $("#myupgrademodel").change(function(event) {

                var upgradeproductselected = $("#myupgrademodel").val();

                $.getJSON(myjsonurl, function(data) {
                    for (var i = 0; i < data.Products.length; i++) {
                        if (data.Products[i].HeadphoneSKU === upgradeproductselected) {
                            var ProductPrice = data.Products[i].Price;
                            console.log(ProductPrice);
                            // Show the upgraded product pricing div
                            $("#upgraded_product_info").show();

                            // set final discounted price
                            var finaldiscountedprice = ProductPrice - (ProductPrice * currentdiscountamount);
                            var finaldiscountedprice = finaldiscountedprice.toFixed(2);

                            // Set and show the final price the customer would expect to pay
                            $("#your_price").html("<span class='upgradevalue'>$" + finaldiscountedprice + " (20% off)</span>");
                        }
                    }
                });
                
            });
            
        });

		$('#upgradeform').submit(function() {
            
            if($('#myfirstname').val() == '') {
                $("#firstname_warning").show();
                $('#myfirstname').focus();
                $('#myfirstname').select();
                return false;
            } else {
            	$("#firstname_warning").hide();
            }

            if($('#mylastname').val() == '') {
                $("#lastname_warning").show();
                $('#mylastname').focus();
                $('#mylastname').select();
                return false;
            } else {
            	$("#lastname_warning").hide();
            }
            
            if($('#myemail').val() == '') {
                $("#email_warning").show();
                $('#myemail').focus();
                $('#myemail').select();
                return false;
            } else {
            	$("#email_warning").hide();
            }
            
            if($('#myemail').val().indexOf('@') == -1 || $('#myemail').val().indexOf('.') == -1) {
                $("#email_valid_warning").show();
                $('#myemail').focus();
                $('#myemail').select();
                return false;
            } else {
            	$("#email_valid_warning").hide();
            }
            
            if($('#mymailingaddress1').val() == '') {
                $("#address_warning").show();
                $('#mymailingaddress1').focus();
                $('#mymailingaddress1').select();
                return false;
            } else {
            	$("#address_warning").hide();
            }
            
            if($('#mycity').val() == '') {
                $("#city_warning").show();
                $('#mycity').focus();
                $('#mycity').select();
                return false;
            } else {
            	$("#city_warning").hide();
            }
            
            if($('#myzipcode').val() == '') {
                $("#zipcode_warning").show();
                $('#myzipcode').focus();
                $('#myzipcode').select();
                return false;
            } else {
            	$("#zipcode_warning").hide();
            }
            
            if($('#mymodel').val() == '') {
                $("#myproduct_warning").show();
                $('#mymodel').focus();
                $('#mymodel').select();
                return false;
            } else {
            	$("#myproduct_warning").hide();
            }
            
            if($('#myupgrademodel').val() == '') {
                $("#myupgradeproduct_warning").show();
                $('#myupgrademodel').focus();
                $('#myupgrademodel').select();
                return false;
            } else {
            	$("#myupgradeproduct_warning").hide();
            }
            
            if($('#country').val() === 'US') {
                if($('#state').val() == '') {
                    $("#us_state_warning").show();
                    $('#state').focus();
                    $('#state').select();
                    return false;
                } else {
                	$("#us_state_warning").hide();
                }
            }
            else if($('#country').val() === 'Canada') {
                if($('#province').val() == '') {
                    $("#canada_province_warning").show();
                    $('#province').focus();
                    $('#province').select();
                    return false;
                } else {
                	$("#canada_province_warning").hide();
                }
            }
            else if($('#country').val() === 'Australia') {
                if($('#australia_state').val() == '') {
                    $("#australia_state_warning").show();
                    $('#australia_state').focus();
                    $('#australia_state').select();
                    return false;
                } else {
                	$("#australia_state_warning").hide();
                }
            }
            else if($('#country').val() === 'Mexico') {
                if($('#mexico_state').val() == '') {
                    $("#mexico_state_warning").show();
                    $('#mexico_state').focus();
                    $('#mexico_state').select();
                    return false;
                } else {
                	$("#mexico_state_warning").hide();
                }
            }

            if($('#country').val() != 'US') {
                if($('#myphonenumber').val() == '') {
                    $("#phone_warning").show();
                    $('#myphonenumber').focus();
                    $('#myphonenumber').select();
                    return false;
                } else {
                	$("#phone_warning").hide();
                }
            }
            
            // hide form after it's submitted
            $("#upgradeform").hide();

            // show thank you container holding iframe
            $("#showingthanks").show();

            // adjust iframe height
            $('#upgrade_iframe').css({"height": "400px"});

            // scroll to top of thank you div
            $('html,body').animate({
                scrollTop: $("#showingthanks").offset().top - 20
            });
            
        });

	});
}