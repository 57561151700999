import React, { Component } from "react";
import { classes, animate, clearStyleProps, GetCDNImage } from "utils";
import Store from "../store";
import _range from "lodash/range";
import { TweenMax } from "gsap";

const BarBtn = ({ styles = "", children, onClick }) => (
    <button
        role="status"
        className={classes("conf_bar_btn h10 roman", styles)}
        onClick={onClick}
    >
        {children}
    </button>
);

export default class BottomBar extends Component {
    state = {
        isAddingToCart: false,
        isAddedToCart: false,
        cartError: false,
        isWeeks: false,
        ...Store.getProps(["price", "currency", "options", "accessories"]),
    };

    async removeOption(optionIndex) {
        const options = Store.get("options");
        const option = document.getElementById(`conf_option_${optionIndex}`);

        let nexts = null;

        if (optionIndex < options.length - 1) {
            const start = optionIndex + 1;
            const end = options.length;
            const selectors = _range(start, end)
                .map((i) => `#conf_option_${i}`)
                .join(", ");
            nexts = document.querySelectorAll(selectors);
            animate(nexts, 0.4, { x: -option.clientWidth - 8 * 2 });
        }

        await animate(option, 0.4, { opacity: 0, y: 4 });

        Store.action("removeOption", { option: options[optionIndex] });

        if (options.length < 1) {
            Store.action("resetCamera");
            Store.action("showColorBar");
        }

        clearStyleProps(option, "all");
        if (nexts) {
            clearStyleProps(nexts, "all");
        }
    }

    componentDidMount() {
        this.checkCartState(this.props);
        Store.linkState(this, ["price", "options", "accessories", "currency"]);
    }

    checkCartState(props) {
        const state = {};

        if (props.isAddingToCart !== this.state.isAddingToCart)
            state.isAddingToCart = props.isAddingToCart;

        if (props.isAddedToCart !== this.state.isAddedToCart)
            state.isAddedToCart = props.isAddedToCart;

        if (props.cartError !== this.state.cartError)
            state.cartError = props.cartError;

        Object.keys(state).length > 0 && this.setState(state);
    }

    componentWillReceiveProps(newprops) {
        this.checkCartState(newprops);
    }

    componentWillUnmount() {
        Store.unlinkState(this);
    }

    componentDidUpdate(prevProps, prevState) {
        
    }
    getTotalPrice() {
        if ( Store.computed("price") != Store.computed("baseprice") ) {
            if ( this.refs.price.dataset.price != Store.computed("price")) {
                this.animatePrice()
            }
        } 
        return Store.computed("price");
    }

    animatePrice() {
        this.refs.price.classList.remove("pulsing")
        setTimeout(() => {
            this.refs.price.classList.add("pulsing")
          }, 200)
       
    }

    getShipping() {
        let weeks = false;
        const actual_days = "" + Store.computed("shipping") + "";
        let days = actual_days.split(",");
        if (days[0] > 6) {
            days[0] = Math.round(days[0] / 7);
            days[1] = Math.round(days[1] / 7);
            weeks = true;
        }
        this.weeks(weeks);
        return days.join("-");
    }

    weeks(w) {
        if (this.state.isWeeks == w) {
            return false;
        }
        this.setState({
            isWeeks: w,
        });
    }

    render() {
        return (
            <div
                className="conf_bar bottom"
                role="region"
                aria-label="Selected Options"
            >
                <p className="roman c-mediumgray h12">
                    {this.props.translations.cp_ships_in}{" "}
                    <span className="c-mediumgray roman h12">
                        {this.getShipping()}{" "}
                    </span>
                    {this.state.isWeeks
                        ? this.props.translations.weeks
                        : this.props.translations.cp_days}
                </p>
                <div className="items-end row no_margin y-center justify-end flex-1">
                    <p ref="price" data-price={this.getTotalPrice()} className="conf_price h9 roman c-dark">
                        {this.state.currency}
                        {this.getTotalPrice()}
                    </p>
                    <BarBtn
                        styles="c-light bg-mainred roman conf_bar_addtocart"
                        onClick={this.props.onAddToCart}
                    >
                        {this.state.cartError ? (
                            "Error"
                        ) : this.state.isAddedToCart ? (
                            "Updating"
                        ) : this.state.isAddingToCart ? (
                            <img
                                src={GetCDNImage("conf/spinner.png")}
                                className="conf_bar_load"
                            />
                        ) : this.props.preorder == "true" ? (
                            "PRE-ORDER"
                        ) : (
                            this.props.translations.cta_buy
                        )}
                    </BarBtn>
                </div>
            </div>
        );
    }
}
