import { qs, qsa } from "../utils/lib";
import { gsap } from "gsap";
import Flickity from "flickity";
export default class ArtistPopup {
  constructor(el) {
    this.el = el;
    this.tiles = qsa(".artist_title");
    this.popup = qs(".popup-artist");
    this.close = qs(".popup-artist_close");
    this.prev = qs(".popup-artist_prev");
    this.next = qs(".popup-artist_next");
    this.gallery = qs(".popup-carousel");
    this.cont = qs(".popup-artist_cont");
    this.image_cont = qs('.js-popup-carousel-image')
    this.data = [];
    this.updatedTile = 0;
    this.currentTile = 0;
    this.newSlide = 0;
    this.images = [];
    this.tile_image = ""
    this.flkty = "";
    // pop-up-content
    this.popup_wrap = qs(".popup-artist_cont");
    this.title = qs(".popup-artist_cont-txt_title");
    this.body = qs(".popup-artist_cont-txt_body");
    this.quote = qs(".popup-artist_cont-txt_quote");
    this.sc = qs(".sc");
    this.sp = qs(".sp");
    this.yt = qs(".yt");
    this.fb = qs(".fb");
    this.ig = qs(".ig");
    this.tw = qs(".tw");
    this.links = qsa(".artist-link");
    this.listen_icon_cont = qs(".listen_cont");
    this.has_opened = ""
  }

  init() {
    this.tiles.forEach((tile, i) => {
      tile.addEventListener("click", () => {
        this.openPopup(tile);
        
        this.getCurrentTile(i);  
        this.has_opened = tile.querySelector('button')
      });
    });
    this.close.addEventListener("click", () => {
      this.closePopup();
      
    });

    this.prev.addEventListener("click", () => {
      if (this.currentTile == 0) {
        return;
      }
      this.updateTile(-1);
      this.updateSlide(-40);
      if (window.innerWidth < 640) {
        this.popup.scrollTo(0,0)
      }
    });

    this.next.addEventListener("click", () => {
      if (this.currentTile == this.tiles.length - 1) {
        return;
      }
      this.updateTile(1);
      this.updateSlide(40);
      if (window.innerWidth < 640) {
        this.popup.scrollTo(0,0)
      }
    });
    this.keyEvents()
  }
  keyEvents(e) {
    window.addEventListener('keydown', function(e) {
      var isTabPressed = (e.key === 'Escape');
      if (isTabPressed) { 
        this.closePopup()
      }
    }) 
  }
  // reset hidden links class
  resetHiddenEls() {
    this.links.forEach((link) => {
      link.classList.remove("hidden");
    });
    this.listen_icon_cont.classList.remove("hide");
    this.listen_icon_cont.querySelector('.sc').classList.remove('display-none')
  }

  // hide or populate link
  getLinks(html_element, data_link) {
    if (data_link == "") {
      html_element.classList.add("hidden");
    } else {
      html_element.href = data_link;
    }
  }

  // populate pop up contents
  async getContent(tile) {
    await this.resetHiddenEls();

    this.data = JSON.parse(tile.dataset.artist);
    this.images = JSON.parse(tile.dataset.images);
    this.title.innerHTML = this.data.title;
    this.body.innerHTML = this.data.body;
    this.quote.innerHTML = this.data.quote;
    if (this.images.length > 0) {
      this.getImages(this.images);
    } else {
      this.image = this.data.image.url
      this.image_cont.setAttribute('src',this.image)
    }
    
    this.getLinks(this.sc, this.data.sc_link);
    this.getLinks(this.yt, this.data.yt_link);
    // nb sostituire con vero link spotify
    this.getLinks(this.sp, this.data.sc_link);
    this.getLinks(this.ig, this.data.ig_link);
    this.getLinks(this.tw, this.data.tw_link);
    this.getLinks(this.fb, this.data.fb_link);

    // check if listen section exists
    if (this.data.sc_link == "" && this.data.yt_link == "") {
      this.listen_icon_cont.classList.add("hide");
    }
    if (this.data.sc_link.includes('spotify')) {
      this.listen_icon_cont.querySelector('.sc').classList.add('display-none')
    } else {
      this.listen_icon_cont.querySelector('.sp').classList.add('display-none')
    }
  }

  getImages() {
    var cellElems = [];
    this.images.forEach((img, i) => {
      cellElems.push(makeCell(img));
      if (i == this.images.length - 1) {
        this.flkty = new Flickity(this.gallery);
        this.flkty.append(cellElems);
      }
    });
    function makeCell(img) {
      let image = document.createElement("img");
      image.setAttribute("src", img.image.url);
      let second_div = document.createElement("div");
      second_div.classList.add("image_wrapper", "ratio-1x1");
      second_div.appendChild(image);
      return second_div;
    }
  }

  getCurrentTile(i) {
    this.currentTile = i;
  }

  updateTile(dir) {
    this.updatedTile = dir == 1 ? this.currentTile + 1 : this.currentTile - 1;
  }

  updateAnim(dir_x) {
    gsap.fromTo(
      this.cont,
      {
        opacity: 0,
        x: dir_x,
        duration: 0,
      },
      {
        opacity: 1,
        x: 0,
        duration: .8,
        ease: "Expo.easeOut"
      }
    );
  }

  async updateSlide(dir_x) {
    this.newSlide = this.tiles[this.updatedTile];
    this.getCurrentTile(this.updatedTile);
    if (this.images.length > 0) {
      await this.resetImages();
    }
    
    await this.getContent(this.newSlide);
    this.updateAnim(dir_x);
  }

  async openPopup(el) {
    await this.getContent(el);
    // gsap.fromTo(
    //   this.popup,
    //   {
    //     autoAlpha: 0,
    //     scale: 0.95,
    //     y: -50,
    //   },
    //   {
    //     autoAlpha: 1,
    //     scale: 1,
    //     ease: "Expo.easeOut",
    //     duration: 1,
    //     y: 0,
    //     onComplete: () => {
    //       this.animating = false;
    //     },
    //   }
    // );

    var dialog = document.querySelector('.popup-artist');
    dialog.classList.add('active')
    var firstFocusableElement = dialog.querySelector('.first-focusable-element');
    var lastFocusableElement = dialog.querySelector('.last-focusable-element');   
    firstFocusableElement.focus() 
    dialog.addEventListener('keydown', function(e) {
      if(e.target == firstFocusableElement && e.key == 'Tab' && e.shiftKey) {
        e.preventDefault();
        lastFocusableElement.focus();
      } else if(e.target == lastFocusableElement && e.key == 'Tab' && !e.shiftKey) {
        e.preventDefault();
        firstFocusableElement.focus();
      }
    });    
  }

  closePopup() {
    // gsap.to(this.popup, {
    //   autoAlpha: 0,
    //   scale: 0.98,
    //   y: 50,
    //   ease: "Expo.easeOut",
    //   duration: 0.5,
    //   onComplete: () => {
    //     if (this.images.length > 0) {
    //       this.resetImages();
    //     }
    //     var dialog = document.querySelector('.popup-artist');
    //     dialog.classList.add('active')        
    //   }
    // });
    if (this.images.length > 0) {
      this.resetImages();
    }
    console.log(dialog)
    var dialog = document.querySelector('.popup-artist');
    dialog.classList.remove('active')
    
    this.has_opened.focus()
    this.has_opened = ""
  }
  resetImages() {
    this.images = [];
    this.flkty.destroy();
    const images = qsa(".image_wrapper", this.gallery);
    images.forEach((img) => img.remove());
  }
}
