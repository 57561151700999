import React, { Component } from 'react'
import { parameterize, classes } from 'utils'
import Store from '../store'
import cloneDeep from 'lodash/cloneDeep'
import Box from './box'

const Counter = ({ value, onAdd, onRemove }) => {
	return (
		<div className="conf_counter regular regular h12">
			<button aria-label="Decrease Quantity" disabled={value == 0 ? "true" : "" } onClick={() => value > 0 ? onRemove() : null}></button>
			<span aria-live="polite">{value}</span>
			<button aria-label="Increase  Quantity" onClick={onAdd}></button>
		</div>
	)
}


export default class Accessories extends Component {

	static defaultProps = {
		data: [],
	}

	state = {
		data: this.props.data,
		currentAccessories: {},
		...Store.getProps(["currency", "accessories"]),
	}

	componentDidMount() {
		Store.linkState(this, ["currency", "accessories"])
		this.setState({
			// Clone deep to avoid references for nested objects, it will be always O(N)
			currentAccessories: cloneDeep(this.state.accessories) 
		})
		document.onkeydown = (evt) => {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                this.props.onClose()
            }
        };

		
	}

	componentWillUnmount() {
		Store.unlinkState(this)
	}

	getAccessoriesCount() {
		return Object.keys(this.state.currentAccessories).reduce((sum, accessory) => {
			return sum + this.state.currentAccessories[accessory].count
		}, 0)
	}

	removeAccessory(index) {
		const accessory   = this.state.data[index]
		const accessories = this.state.currentAccessories

		if ( !accessories[index] )
			return

		const count = accessories[index].count

		if ( count > 1 ) {
			accessories[index].count -= 1
		} else {
			delete accessories[index]
		}

		this.setState({
			currentAccessories: accessories
		})
	}

	addAccessory(index) {
		const accessory   = this.state.data[index]
		const accessories = this.state.currentAccessories

		if ( accessories[index] ) {
			accessories[index].count += 1
		} else {
			accessories[index] = Object.assign({ count: 1 }, accessory)
		}

		this.setState({
			currentAccessories: accessories
		})

	}

	onChange(index,ev) {
		const new_id = ev.target.value
		const accessories   = this.state.data
		const variant = accessories[index].variants.find((v) => v.store_id === new_id )
		accessories[index].id = new_id
		accessories[index].img = variant.image
		const c_accessories = this.state.currentAccessories
		if ( accessories[index] ) {
			c_accessories[index].id = new_id
			c_accessories[index].img = variant.image
		}
		this.setState({
			data: accessories,
			currentAccessories: c_accessories
		})
	}

	updateCart() {
		Store.set("accessories", this.state.currentAccessories)
		this.props.onClose()
	}

	getItemInfo() {
		let count = 0
		let price = 0

		const keys = Object.keys(this.state.currentAccessories)

		for ( let i = 0, l = keys.length; i < l; i+=1 ) {
			const accessory = this.state.currentAccessories[ keys[i] ]
			count += accessory.count
			price += accessory.price * accessory.count
		}

		const items_string = count !== 1 ? "items" : "item"
		
		return <div className="conf_box_foot_cont h11 medium c-dark">
			<span className="c-dark titlecase">
				{this.props.translations.cp_subtotal}: {price}{this.state.currency}&nbsp;
			</span>
			({count} {this.props.translations.cp_items})
		</div>
	}

	firstAdd(_count,index){
        if ( _count < 1 ) {
            this.addAccessory.bind(this,i)
            this
        }
	}

	getPointer(_count) {
		return _count < 1 ? {
			cursor: "pointer"
		} : {}
	}

	optionAdd(_count) {
    return _count < 1
    	? null : onAdd 
	}

	render() {
		return <Box title={this.props.translations.cp_accessories} onClose={this.props.onClose}>

			<div className="conf_accessories_scroll" ref={el => this.scroller = el}>

				<ul className="conf_accessories_flex">

					{
						this.state.data.map((option,i) => {

							const accessory = this.state.currentAccessories[i]
							const count = accessory ? accessory.count : 0

							

							return (
								<li key={i} role="group" aria-label="product" className={ classes("conf_box_item", { active: count > 0 })}>
								
									<div className="conf_box_item_img" style={{ backgroundImage: `url(${option.img})` }}></div>

									<div className="conf_box_item_cont h12 regular">
										<span className="bold">{option.name}</span>
										<span>{this.state.currency} {option.price}</span>
										{(option.variants && option.variants.length > 1) &&
											<label for={parameterize(option.name)} className="acc_select">Please choose a variant</label>
										}
										<div className="conf_box_item_actions">
										<div className="conf_box_item_actions_col">
										<Counter value={count} onAdd={this.addAccessory.bind(this, i)} onRemove={this.removeAccessory.bind(this, i)}/>
										</div>
										{(option.variants && option.variants.length > 1) &&
										<div className="conf_box_item_actions_col">
										<select className="h12" id={parameterize(option.name)} onChange={this.onChange.bind(this, i)}>
											{option.variants.map((opt) => {
												return(<option value={opt.store_id}>{opt.title}</option>)
											})}
										</select>
										</div>
										}
										</div>
									</div>

								</li>
						  	)
						})
					}

				</ul>

			</div>

			<div className="conf_box_foot regular h11 medium">
				{ this.getItemInfo() }
				<button className="upcase c-light regular bold bg-mainred" onClick={this.updateCart.bind(this)}>
					{this.props.translations.cp_add_to_order}
				</button>
			</div>

		</Box>
	}

}