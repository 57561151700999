import _remove from 'lodash/remove'
import _find from 'lodash/find'

export function addDesignLayoutOption( store, { option, partName }) {
	const options = store.get('options')

	let activeOption = option

	if ( option.is_design ) {
		const isPresent = _find(options, v => v.id === option.id )

		if ( !isPresent ) {
			_remove(options, v => v.component === partName)
			options.push(activeOption)

			if ( activeOption.default_custom_part_item ) {
				options.push(activeOption.default_custom_part_item)
			}

		}

	} else {

		const isPresent = _find(options, v => v.id === option.id )

		if ( isPresent ) {

			const designOpt = _find(options, v => v.component === partName && v.is_design )

			if ( designOpt.default_custom_part_item && option.id === designOpt.default_custom_part_item.id ) {
				_remove(options, v => v.component === partName )
				activeOption = null
			} else {
				_remove(options, v => v === option)
				activeOption = designOpt.default_custom_part_item || null
				activeOption && options.push(activeOption)
			}
		
		} else {
			_remove(options, v => v.component === partName && !v.is_design)
			options.push(option)
		}
	}

	if ( activeOption ) {
		activeOption.component = partName
		activeOption.layout_type = "design"

		if ( activeOption.default_custom_part_item ) {
			activeOption.default_custom_part_item.component   = partName
			activeOption.default_custom_part_item.layout_type = "design"
		}
	}

	store.set('options', options)

	return activeOption
}