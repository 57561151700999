import React, { Component } from "react";
import { classes } from "utils";
import feather from "feather-icons";

const SideBarExtrasBtn = ({
    img,
    title,
    onClick,
    count = 0,
    isAccessories = false,
}) => (
    <button className="conf_sidebar_extras_btn" onClick={onClick}>
        {isAccessories && count > 0 && (
            <div className="conf_extras_accessories_count extras_btn_count_mobile">
                {count}
            </div>
        )}

        <span className="h6 upcase navigation1">
            <i
                dangerouslySetInnerHTML={{
                    __html: feather.icons.edit.toSvg({
                        color: "white",
                        width: "20px",
                        height: "20px",
                    }),
                }}
            ></i>
            {title}
        </span>
    </button>
);

export default ({
    parts,
    onClick,
    onAccessoriesClick,
    accessoriesCount = 0,
    showAccessories,
    setActiveStep,
    activeStep,
}) => (
    <div className="conf_sidebar_extras_mobile">
        <div className="conf_sidebar_extras_scroll">
            <SideBarExtrasBtn
                title="Customize"
                onClick={() => onClick(0, activeStep)}
            />
        </div>
    </div>
);
