import { qs, qsa } from "../utils/lib";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Navbar = (el) => {
  window.addEventListener("scroll", () => {
    const nav = qs(".navigation")
    if (window.scrollY > 0) {
      


      if (qs(".navigation_promotions_banner") || qs('.announcement_bar')) {
        el.classList.add("active");
        nav && nav.classList.add("active");
      } else {
        nav && nav.classList.add("active");
      }


      if (qs(".navigation_navbar_logo svg g")) {
        qs(".navigation_navbar_logo svg g").classList.remove("active");
      }
      if (qsa('header.navigation_navbar_menu svg').length > 0 && qsa("header.navigation_navbar_icons svg").length > 0) {
        gsap.to(["header.navigation_navbar_menu svg", "header.navigation_navbar_icons svg"], {
          color: "black",
          duration: 1,
        });
      }
    } else {
      el.classList.remove("active");
      nav && nav.classList.remove("active");

      //Home

      if (qs(".thirdSlide")) {
        if (qs(".thirdSlide").classList.contains("active")) {
          gsap.to(
            [".navigation_navbar_menu svg", ".navigation_navbar_icons svg"],
            {
              scrollTrigger: ".thirdSlide",
              color: "white",
              duration: 1,
            }
          );
        } else {
          return false;
        }
      }
    }
  });
};
export default Navbar;
