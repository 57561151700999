import { qs, qsa } from "../../utils/lib";
import { gsap, Power1 } from "gsap";

import homeHeroThirdSlide from "./home-hero-thirdSlide";

export default class homeHeroSecondSlide {
  constructor() {
    this.tl = gsap.timeline();

    this.thirdSlideClass = new homeHeroThirdSlide();

    //First Slide
    this.firstSlideElement = qs(".firstSlide");
    this.firstText = qs(".firstSlide .text1");
    this.firstImage = qs(".firstSlide .image_container .img1");
    this.svg = qs(".svg");
    this.rect = qs(".rect");

    //Second Slide
    this.secondSlideElement = qs(".secondSlide");
    this.secondText = qs(" .secondSlide .text2");
    this.cta = this.secondText.querySelector('a.button')
    this.secondImage = qs(".secondSlide .img2");
    this.secondSvg = qs(" .svg2 svg");
    this.vShape = qs(" .svg2 .v-shape");
    this.vShapePL = this.vShape.getTotalLength();
    this.triangle = qs(" .svg2 .triangle");
    this.speed = 1.5;
    this.percent = 1;

    //Third Slide
    this.thirdSlideElement = qs(".thirdSlide");
  }

  setupSecondSlide() {
    gsap.set(this.secondText, {
      opacity: 0,
      duration: 0,
    });
    gsap.set(this.secondImage, {
      opacity: 0,
      duration: 0,
    });

    gsap.set(this.triangle, {
      fill: "#e1251b",
    });

    gsap.set(this.secondSvg, {
      x: "50vw",
      y: "0%",
      scale: 1.6,
      opacity: 0,
    });
  }

  secondSlideOut(goToFirst, goToThird, firstName, secondName) {
    gsap.set(this.svg, {
      opacity: 0,
      duration: 0,
    });

    this.tl.to(this.triangle, {
      fill: "white",
    });

    this.tl.to(
      this.secondText,
      {
        x: "-100vw",
        duration: 0.6,
        ease: Power1.easeInOut,
      },
      firstName
    );

    this.tl.to(
      this.secondImage,
      {
        x: "100vw",
        duration: 0.7,
        ease: Power1.easeInOut,
        onComplete: () => {
          this.cta.classList.add('disabled')
          if (goToFirst) {
            gsap.to(
              [".navigation_navbar_menu svg", ".navigation_navbar_icons svg"],
              {
                color: "black",
                duration: 1,
              }
            );
          } else {
            this.thirdSlideClass.setupThirdSlide();
          }
        },
      },
      firstName
    );

    this.tl.to(
      this.rect,
      {
        y: "100%",
        opacity: 0,
        duration: 1,
      },
      secondName
    );

    this.tl.to(
      this.secondSvg,
      {
        y: "100%",
        opacity: 0,
        duration: 1,
      },
      secondName
    );

    this.tl.set(this.rect, { clearProps: "all", delay: 1 });
  }

  animateSecondSvg() {
    this.cta.classList.remove('disabled')
    gsap.to(this.firstSlideElement, {
      zIndex: 0,
    });

    gsap.to(this.secondSlideElement, {
      zIndex: 1,
      opacity: 1,
    });

    gsap.set(this.secondText, {
      zIndex: 2,
    });

    gsap.set(this.secondSvg, {
      opacity: 1,
      duration: 0.3,
    });

    gsap.set(this.vShape, {
      strokeDasharray: this.vShapePL + " " + this.vShapePL,
      strokeDashoffset: this.vShapePL,
      duration: 0,
    });

    this.tl.to(this.vShape, {
      strokeDashoffset: this.vShapePL * (1 - this.percent),
      duration: 1,
      ease: "power4.inOut",
    });

    this.tl.to([this.secondImage, this.secondText], {
      opacity: 1,
      duration: 2,
    });
  }
}
