import { qs, qsa } from "../utils/lib"
import gsap from 'gsap'
class NewCarousel {
    constructor(el) {
        this.el = el
        this.prev = this.el.querySelector('.prevButton')
        this.next = this.el.querySelector('.nextButton')
        this.active = 0
        this.slides = qsa('.home-hero-slide', this.el)
        this.wrappers = qsa('[js-carousel-text]', this.el)
        this.isMobile = window.innerWidth < 640
        this.animating = false
        this.init()
    }
    init() {
        if (this.isMobile) {
            if (this.checkIfVideo()) {
                this.setMobileVideo()
            }
        }        
        window.addEventListener('resize', () => {
            this.setMobileVideo()
        })
        if (this.checkIfVideo()) {
            this.playVideo()
        }
        this.fadeInSlide()
        if (this.prev) {
                this.prev.addEventListener('click', () => {
                    if (this.animating) {
                        return
                    } else {
                        if (this.active == 0) {
                            this.checkPosition()
                        }
                        this.fadeOutSlide(-1)
                    }

                })

        }  
        if (this.next) {
            this.next.addEventListener('click', () => {
                if (this.animating) {
                    return
                } else {
                    if (this.active == this.slides.length - 1) {
                        this.checkPosition()
                    } 
                    this.fadeOutSlide(1)   
                }           
            })
        }
    }

    goTo(dir) {
        this.active += dir
        this.setSlides()
        if (this.checkIfVideo()) {
            this.playVideo()
        } else {
            this.resetVideo()
        }
        
        this.fadeInSlide()
    }
    playVideo() {
        const video = this.slides[this.active].querySelector('video')
        video.play()
    }
    resetVideo() {
        this.slides.forEach((slide,i) => {
            if (i !== this.active) {
                const video = slide.querySelector('video')
                if (video) {
                    video.pause()
                    video.currentTime = 0
                }

            } 
        })
    }
    checkIfVideo() {
        const video = this.slides[this.active].querySelector('video')
        if (video) {
            return true
        } else {
            return false
        }
    }
    setMobileVideo() {
        this.slides.forEach(slide => {
            const video = slide.querySelector('video')
            if (video && video.dataset.mobile.length > 0) {
                const source = video.querySelector('source')
                let new_src;
                if (window.innerWidth < 640) {
                    new_src = video.dataset.mobile
                } else {
                    new_src = video.dataset.video
                }
                video.pause();
                source.src = new_src;
                video.load();
                video.play();
            }
        })
    }
    setSlides() {
        this.slides.forEach((sl,i) => {
            if (i == this.active) {
                sl.classList.add('active')
            } else {
                sl.classList.remove('active')
            }
        })
    }
    checkPosition() {
        if (this.active == 0) {
            this.active = this.slides.length 
        }
        if (this.active == this.slides.length - 1) {
            this.active = -1
        }
    }
    fadeOutSlide(dir) {
        let index = this.active
        if (this.active == this.slides.length) {
            index = 0             
        }
        if (this.active == -1) {
            index = this.slides.length - 1
        }
        
        const el = this.slides[index]
        this.animating = true
        gsap.to(el,{
            opacity: 0,
            duration: 0.8, 
            onComplete: () => {
                this.goTo(dir)
                
                gsap.set(el,{
                    opacity: 1
                })
            }
        })
    }

    resetSlides() {
        this.slides.forEach((slide,index) => {
            if ( index == this.active) {
                return false 
            } else {
                const text_wrapper = slide.querySelector('[js-carousel-text]')
                if (text_wrapper) {
                    const els = text_wrapper.children
                    const background = this.slides[this.active].querySelector('.image_container')
                    const tl = gsap.timeline()
                    tl.set(els,{
                        clearProps: "all"
                    })
                }
            }
        })
    }
    fadeInSlide() {

        //this.resetSlides()
        const text_wrapper = this.slides[this.active].querySelector('[js-carousel-text]')
        if (text_wrapper) {
            const els = text_wrapper.children
            const background = this.slides[this.active].querySelector('.image_container')
            const tl = gsap.timeline()
            
            tl.fromTo(background,{
                opacity: 0
            },{
                opacity: 1,
                duration: 0.3,
                ease: "ease-out"
            })
            .fromTo(els,{
                y: 30,
                opacity: 0
            },{
                y: 0,
                opacity: 1,
                duration: 1,
                stagger: 0.1,
                ease: "ease-out",
                onComplete: () => this.animating = false
            })
        }
        
    }
}
export default NewCarousel