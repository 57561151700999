
import { qs, qsa } from "../utils/lib";

export default class npr {
  constructor(el) {
      this.el = el
      this.product = qs('.product_registration_product')
      this.number = qs('.product_registration_order_number')
      this.serial = qs('.product_registration_serial_number')
      this.from = qs('.product_registration_purchased_from')
      this.date = qs('.product_registration_purchased_date')
      this.init()
  }
  init() {
      console.log(this.date)
      this.setFields()
      this.serial.querySelector('input').addEventListener("keyup", () => {
          this.setFields()
      })
      this.from.querySelector('select').addEventListener("change", () => {
        this.setFields()
    })
  }

  setFields() {
      if ( this.serial.querySelector('input').value.length == 10 ) {
          this.product.style.display = 'block'
          this.product.querySelector('select').setAttribute("required",true)
      } else {
        this.product.style.display = 'none'
        this.product.querySelector('select').removeAttribute("required")
      }
      if ( this.from.querySelector('select').value == "V-Moda.com" ) {
        this.number.style.display = 'block'
        this.number.querySelector('input').setAttribute("required",true)
        this.date.style.display = 'none'
        this.date.querySelector('input').removeAttribute("required")
    } else {
        this.number.style.display = 'none'
        this.number.querySelector('input').removeAttribute("required")
        this.date.style.display = 'block'
        this.date.querySelector('input').setAttribute("required",true)

     }
  }

  check

 

}
