import { qs, qsa } from "../../utils/lib";
import { gsap } from "gsap";

export default class homeHeroThirdSlide {
  constructor() {
    this.tl = gsap.timeline();

    //First Slide
    this.firstSlideElement = qs(".firstSlide");
    this.firstText = qs(".firstSlide .text1");
    this.firstImage = qs(".firstSlide .image_container .img1");
    this.svg = qs(".svg");
    this.rect = qs(".rect");

    //Second Slide
    this.secondSlideElement = qs(".secondSlide");
    this.secondText = qs(" .secondSlide .text2");
    this.secondImage = qs(".secondSlide .img2");
    this.secondSvg = qs(" .svg2 svg");
    this.vShape = qs(" .svg2 .v-shape");
    this.vShapePL = this.vShape.getTotalLength();
    this.triangle = qs(" .svg2 .triangle");
    this.speed = 1.5;
    this.percent = 1;

    //Third Slide
    this.thirdSlideElement = qs(".thirdSlide");
    this.cta = this.thirdSlideElement.querySelector('a.button')
  }

  setupThirdSlide() {
    this.cta.classList.remove('disabled')
    gsap.set(".secondSlide", {
      zIndex: 0,
    });

    gsap.to([".navigation_navbar_menu svg", ".navigation_navbar_icons svg"], {
      color: "white",
      duration: 1,
    });
    this.tl.to(this.svg, {
      opacity: 0,
      duration: 0,
    });

    this.tl.to(this.thirdSlideElement, {
      duration: 1,
      opacity: 1,
      zIndex: 0,
      onComplete: () => {
        if (typeof gtag !== 'undefined') {
          gtag('event', 'home_scrolled', {
            'event_category': 'home',
            'event_label': 'home_scrolled'
          });
        }
      },
    });
  }

  thirdSlideOut() {
    gsap.to([".navigation_navbar_menu svg", ".navigation_navbar_icons svg"], {
      color: "white",
      duration: 1,
    });

    gsap.set(this.secondText, {
      opacity: 0,
      x: 0,
    });
    gsap.set(this.secondImage, {
      opacity: 0,
      x: 0,
    });

    gsap.set(this.triangle, {
      fill: "#e1251b",
    });

    gsap.set(this.secondSvg, {
      x: "50vw",
      y: "0%",
      scale: 1.6,
      opacity: 0,
    });

    this.tl.to(this.thirdSlideElement, {
      opacity: 0,
      duration: 1,
      zIndex: -1,
      onComplete: () => {
        this.cta.classList.add('disabled')
      }
    });

    this.tl.to(this.rect, {
      width: "100%",
      duration: 1,
      opacity: 1,
    });
  }
}
