import { qs, qsa } from "../utils/lib";

export default function clickAndClose(container, closeButton) {
  let popup = qsa(container);
  let closeButtons = qsa(closeButton);

  for (let i = 0; i < closeButtons.length; i++) {
    closeButtons[i].addEventListener("click", (evt) => {
      popup[i].classList.add("display-none");
    });
  }
}
