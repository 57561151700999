import _remove from 'lodash/remove'
import _find from 'lodash/find'

export function removeDesignLayoutOption(store, { option }) {
	const options = store.get('options')
	
	if ( option.is_design )
		_remove( options, v => v.component === option.component )
	else {
		const designOpt = _find(options, v => v.component === option.component && v.is_design )
		
		if ( designOpt.default_custom_part_item ) {

			if ( designOpt.default_custom_part_item.id === option.id ) {
				_remove( options, v => v.component === option.component )
			} else {
				_remove( options, v => v.id === option.id )
				store.action("addDesignLayoutOption", {
					partName: option.component,
					option: designOpt.default_custom_part_item
				})
			}

		} else {
			_remove( options, v => v.id === option.id )
		}

	}

	store.set('options', options)
}