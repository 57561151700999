import $ from 'jquery'

export default function () {

    $('#vmoda_contact_form').submit(function(e) {
		    e.preventDefault();
			const validations = validate(this)
			var gresponse = grecaptcha.getResponse();
			const  form = document.querySelector('#vmoda_contact_form')
			if ( validations && gresponse.length > 0 ) {
				form.submit()
				form.reset();
				const el = document.querySelector('.js_contact_form_cta')
				el.innerHTML = "Thank you"
			}
			
	});

	const validate = function(form) {
			var errors = 0
			$(form).find('input, textarea').each(function(i,e) {
				const el   = $(this).parent().find('.form-error')
				if($(this).prop('required') && $(this).val().length == 0 ){
					// console.log($(this).attr("name"))
				 
				  this.setAttribute("aria-invalid","true")
			      const span = typeof el[0] === "undefined" ? $( '<span class="form-error upcase h13 c-light text-left">Required field</span>' ) : el
			      span.insertAfter(this);
			      errors = errors + 1
			    } else if ( $(this).attr("name") == 'message' && $(this).val().length > 0 ) {
			    	errors = errors + 1
			    } else {
			    	$(this).parent().find(".form-error").remove()
					this.setAttribute("aria-invalid","false")
			    }
			})
			if ( errors == 0 ) {
				return true
			} else {
				document.querySelector('[aria-invalid]').focus()
				return false
			}
	};
}
