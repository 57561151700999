const cloudName            = 'dnain5omf'
const unsignedUploadPreset = 'qfrtiqcc'

export default function UploadToCloudinary(file) {

  return new Promise(resolve => {

    const url = `https://api.cloudinary.com/v1_1/${cloudName}/upload`
    const xhr = new XMLHttpRequest()
    const fd = new FormData()
    xhr.open('POST', url, true)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    
    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener("progress", function(e) {
      const progress = Math.round((e.loaded * 100.0) / e.total)
    });

    xhr.onreadystatechange = function(e) {
      if (xhr.readyState == 4 && xhr.status == 200) {
        // File uploaded successfully

        const response = JSON.parse(xhr.responseText)

        resolve(response)

        /*
        // https://res.cloudinary.com/cloudName/image/upload/v1483481128/public_id.jpg
        const url = response.secure_url
        // Create a thumbnail of the uploaded image, with 150px width
        const tokens = url.split('/')
        tokens.splice(-2, 0, 'w_150,c_scale')
        const img = new Image() // HTML5 Constructor
        img.src = tokens.join('/')
        img.alt = response.public_id
        document.getElementById('gallery').appendChild(img)
        */
      }
    }

    fd.append('upload_preset', unsignedUploadPreset)
    fd.append('tags', 'browser_upload') // Optional - add tag for image admin in Cloudinary
    fd.append('file', file)
    xhr.send(fd)

  })
}