export const filesToLoadFromEvent = (e) => {
	let filesToLoad = null

	// Handling data transfer via drag'n'drop
  	if (e && e.dataTransfer && e.dataTransfer.files) {
  	    filesToLoad = e.dataTransfer.files
  	}

  	// Handling files from input files
  	if (e && e.target && e.target.files) {
  	    filesToLoad = e.target.files
  	}

  	let files = [];
   	var items = e.dataTransfer ? e.dataTransfer.items : null;

   	for (var i = 0; i < filesToLoad.length; i++) {
   	    let fileToLoad = filesToLoad[i];
   	    let name = fileToLoad.name.toLowerCase();
   	    let entry;

   	    fileToLoad.correctName = name;

   	    if (items) {
   	        let item = items[i]
   	        if (item.getAsEntry) {
   	            entry = item.getAsEntry()
   	        } else if (item.webkitGetAsEntry) {
   	            entry = item.webkitGetAsEntry()
   	        }
   	    }

   	   	files.push(fileToLoad)
   	}

   	return filesToLoad
}