import { qsa } from "../utils/lib";

export default class TabsNavigation {
    constructor(el) {
        this.tab_navigation = el;
        this.tabs = this.tab_navigation.querySelectorAll('.tab');
        this.current = 'user_info-tabpanel';
        this.active = 0;
        this.components = qsa('.user_tabs_content')
        this.init();
        this.ApplyFilters()
    }
    init() {
        this.tab_navigation.addEventListener('keydown', (e) => {
            const key = e.key
            switch (key) {
                case "ArrowRight":
                    this.active++
                    if (this.active == this.tabs.length) {
                        this.active = 0
                    }
                    this.current = this.tabs[this.active].dataset.target
                    break
                case "ArrowLeft":
                    if (this.active == 0) {
                        this.active = this.tabs.length
                    }
                    this.active--
                    this.current = this.tabs[this.active].dataset.target

                    break
                case "Home":
                    this.active = 0
                    break
                case "End":
                    this.active = this.tabs.length - 1
                    break
            }
            this.ActiveBtn(this.active)
            this.tabs[this.active].focus()
            this.ApplyFilters()
        })

        this.tabs.forEach((el, i) => {
            el.addEventListener('click', () => {
                this.current = el.dataset.target
                this.active = i
                this.ActiveBtn(i)
                this.ApplyFilters()
            })
        })
    }
    ApplyFilters() {
        this.components.forEach((comp) => {
            if (comp.getAttribute("id") == this.current) {
                comp.classList.add("active")
            } else {
                comp.classList.remove("active")
            }
        })
    }
    ActiveBtn(index) {
        this.tabs.forEach((el, i) => {
            if (i == index) {
                el.classList.add('active')
                el.setAttribute('aria-current', 'true')
                el.setAttribute('aria-selected', 'true')
                el.setAttribute('tabindex', '0')
            } else {
                el.classList.remove('active')
                el.setAttribute('aria-current', 'false')
                el.setAttribute('aria-selected', 'false')
                el.setAttribute('tabindex', '-1')
            }
        })
    }

}