import React, { Component } from "react";
import {
    classes,
    setState,
    animate,
    hasProperty,
    GetCDNImage,
    clearStyleProps,
} from "utils";
import { TweenMax } from "gsap";
import Store from "../../../store";
import _groupBy from "lodash/groupBy";
import _map from "lodash/map";

import { SidebarRow, SidebarOption } from "../components";

export default class Materials extends Component {
    state = {
        data: null,
        categories: [],
        currentCategory: null,
        activeOption: null,
        pos: 0,
        // Set to true if it's Free Customization Time!
        isFreeCustomization: true
    };

    constructor() {
        super(...arguments);
        this.binds = {
            selectCategory: this.selectCategory.bind(this),
            closeCategory: this.closeCategory.bind(this),
            setOption: this.setOption.bind(this),
            watchOptions: this.watchOptions.bind(this),
        };
    }

    async setOption(option) {
        if (this.state.isFreeCustomization) {
            option.price = 0
        }
        this.onOpenInnerSection();
        const activeOption = Store.action("addMaterialLayoutOption", {
            option: option,
            old_option: this.state.activeOption,
            partName: this.props.extraName,
        });

        await this.setState({
            activeOption,
        });
    }

    onCloseInnerSection() {
        Store.action("resetCamera");
        Store.action("showColorBar");
    }

    onOpenInnerSection() {
        if (this.props.camera) {
            console.log(this.props.camera,'camera props')
            let camera = this.props.camera 
            if ( window.innerWidth <= 640 ) {
                camera.rotation.radius = 70
            }
            Store.set("cameraProps", camera);
        }
        Store.action("hideColorBar");
    }

    componentDidMount() {
        const categories = Object.keys(this.props.data);
        //console.log(categories, "aaa");
        this.setState({
            categories,
            currentCategory: "material",
        });

        let selected = Store.action("getSelectedOptionFor", {
            partName: this.props.extraName,
            is_design: false,
        });

        this.setState({
            activeOption: selected,
        });

        //console.log("mounted");

        Store.watch("options", this.binds.watchOptions);
    }

    componentWillUnmount() {
        Store.watchOff("options", this.binds.watchOptions);
    }

    watchOptions(options) {
        for (let option of options) {
            if (option === this.state.activeOption) return;
        }
        this.setState({
            activeOption: null,
        });
    }

    async componentWillReceiveProps(newprops) {
        if (newprops.extraName === this.props.extraName) return;

        let selected = Store.action("getSelectedOptionFor", {
            partName: newprops.extraName,
            is_design: false,
        });

        const categories = Object.keys(newprops.data);
        this.setState({
            categories,
            currentCategory: categories[0],
            activeOption: selected,
        });

        clearStyleProps(this.animator, "all");
    }

    async selectCategory(category) {
        await setState(this, {
            currentCategory: category,
        });
    }

    async closeCategory() {
        this.state.categories.indexOf(this.state.currentCategory);
        this.setState({
            currentCategory: null,
        });
    }

    getInnerContent() {
        //console.log(this.props.data[this.state.currentCategory]);
        return this.props.data[this.state.currentCategory];
    }

    render() {
        return (
            <div className="new_conf_sidebar_grid no_margin_b">
                {this.state.currentCategory !== null &&
                    this.getInnerContent()["color_printing"].map((v, i) => (
                        <div
                            onClick={() => this.binds.setOption(v)}
                            className={classes("new_conf_sidebar_grid_col", {
                                selected:
                                    this.state.activeOption?.title === v.title,
                            })}
                        >
                            <div className="image_wrapper">
                                <img src={v.img} alt="" />
                            </div>
                            <span className="conf_color_bar_btn_badge">
                                {v.title}
                            </span>
                        </div>
                    ))}
            </div>
        );
    }
}
