import $ from 'jquery'
import '../utils/jquery-ui.min.js'
import '../utils/session.js' 

export default function () {

    $(document).ready(function($) {

        // testing purposes to make sure jquery is loading and working properly on the page - will be removed
      

        //Hide divs on start up
        $("#showingthanks").hide();
        $("#show_canada_province").hide();
        $("#show_australia_state").hide();
        $("#show_mexico_state").hide();
        $("#other_region").hide();
        //$("#showintlphone").hide();
        $("#show_order_number").hide();
        $("#show_warranty_number").hide();
        $("#show_purchase_date").hide();

        //Autotab phone number fields
        //$('#PhoneNumberOne').autotab({ target: '#PhoneNumberTwo', format: 'numeric' });
        //$('#PhoneNumberTwo').autotab({ target: '#PhoneNumberThree', format: 'numeric', previous: '#PhoneNumberOne' });
        //$('#PhoneNumberThree').autotab({ previous: '#PhoneNumberTwo', format: 'numeric' });

        // date picker function
        $(function() {
            $('#purchase_date').datepicker({
                maxDate: 0
            });
        });

        //Show states/provinces/regions based on selected Country
        $("#country").change(function(event) {
            var usercountry = $("#country").val();
            if (usercountry === "US")
            {
                //$("#showuscanphone").show();
                //$("#showintlphone").hide();
                $("#show_us_state").show();
                $("#show_canada_province").hide();
                $("#show_australia_state").hide();
                $("#show_mexico_state").hide();
                $("#other_region").hide();
            }
            else if (usercountry === "Canada")
            {
                //$("#showuscanphone").show();
                //$("#showintlphone").hide();
                $("#show_us_state").hide();
                $("#show_canada_province").show();
                $("#show_australia_state").hide();
                $("#show_mexico_state").hide();
                $("#other_region").hide();
            }
            else if (usercountry === "Australia")
            {
                //$("#showintlphone").show();
                //$("#showuscanphone").hide();
                $("#show_us_state").hide();
                $("#show_canada_province").hide();
                $("#show_australia_state").show();
                $("#show_mexico_state").hide();
                $("#other_region").hide();
            }
            else if (usercountry === "Mexico")
            {
                //$("#showintlphone").show();
                //$("#showuscanphone").hide();
                $("#show_us_state").hide();
                $("#show_canada_province").hide();
                $("#show_australia_state").hide();
                $("#show_mexico_state").show();
                $("#other_region").hide();
            }
            else
            {
                //$("#showintlphone").show();
                //$("#showuscanphone").hide();
                $("#show_us_state").hide();
                $("#show_canada_province").hide();
                $("#show_australia_state").hide();
                $("#show_mexico_state").hide();
                $("#other_region").show();
            }
        });

        // show order number text box if user says they purchased from V-MODA.com
        $("#wherepurchased").change(function(event) {
            var purchaseplace = $("#wherepurchased").val();
            if (purchaseplace === "v-moda.com") {
                $("#show_order_number").show(); 
                $("#show_warranty_number").hide();
                $("#show_purchase_date").hide();
            } else if (purchaseplace === "previous_warranty") {
                $("#show_order_number").hide();
                $("#show_warranty_number").show();
                $("#show_purchase_date").hide();
            } else if (purchaseplace === "other") {
                $("#show_order_number").hide();
                $("#show_warranty_number").hide();
                $("#show_purchase_date").show();
            } else {
                $("#show_order_number").hide();
                $("#show_warranty_number").hide();
                $("#show_purchase_date").hide();
            }
        });

        //Form submission rules
        $("#vmoda_warranty_form").submit(function() {
            // Make sure first name is filled out
            if($("#firstname_entry").val() == '') {
                $("#firstname_warning").show();
                $("#firstname_entry").focus();
                $("#firstname_entry").select();
                return false;
            } else {
                $("#firstname_warning").hide();
            }
            
            // Make sure last name is filed out
            if($("#lastname_entry").val() == '') {
                $("#lastname_warning").show();
                $("#lastname_entry").focus();
                $("#lastname_entry").select();
                return false;
            } else {
                $("#lastname_warning").hide();
            }
            
            // Make sure email is filled out
            if($("#email_entry").val() == '') {
                $("#email_warning").show();
                $("#email_entry").focus();
                $("#email_entry").select();
                return false;
            } else {
                $("#email_warning").hide();
            }
            
            // Make sure email address is valid
            if($("#email_entry").val().indexOf('@') == -1 || $("#email_entry").val().indexOf('.') == -1) {
                $("#valid_email_warning").show();
                $("#email_entry").focus();
                $("#email_entry").select();
                return false;
            } else {
                $("#valid_email_warning").hide();
            }
            
            // Make sure address line 1 is filled out
            if($("#address1_entry").val() == '') {
                $("#address_warning").show();
                $("#address1_entry").focus();
                $("#address1_entry").select();
                return false;
            } else {
                $("#address_warning").hide();
            }
            
            // Make sure city is filled out
            if($("#city_entry").val() == '') {
                $("#city_warning").show();
                $("#city_entry").focus();
                $("#city_entry").select();
                return false;
            } else {
                $("#city_warning").hide();
            }
            
            // Make sure country is filled out
            if($("#country").val() == '') {
                $("#country_warning").show();
                $("#country").focus();
                $("#country").select();
                return false;
            } else {
                $("#country_warning").hide();
            }
            
            // Make sure if Country is the US, then the states is filled out
            if($("#country").val() == "US" && $("#state").val() == '') {
                $("#us_state_warning").show();
                $("#state").focus();
                $("#state").select();
                return false;
            } else {
                $("#us_state_warning").hide();
            }
            
            // Make sure if Country is Canada, then the province is filled out
            if($("#country").val() == "Canada" && $("#canada_province").val() == '') {
                $("#canada_province_warning").show();
                $("#canada_province").focus();
                $("#canada_province").select();
                return false;
            } else {
                $("#canada_province_warning").hide();
            }

            // Make sure if Country is Mexico, then the state is filled out
            if($("#country").val() == "Mexico" && $("#mexico_state").val() == '') {
                $("#mexico_state_warning").show();
                $("#mexico_state").focus();
                $("#mexico_state").select();
                return false;
            } else {
                $("#mexico_state_warning").hide();
            }

            // Make sure if Country is Australia, then the state is filled out
            if($("#country").val() == "Australia" && $("#australia_state").val() == '') {
                $("#australia_state_warning").show();
                $("#australia_state").focus();
                $("#australia_state").select();
                return false;
            } else {
                $("#australia_state_warning").hide();
            }
            
            //if($('#country').val() == "US") {
                //var ph1 = $("#phonenumberone").val();
                //var ph2 = $("#phonenumbertwo").val();
                //var ph3 = $("#phonenumberthree").val();
                //var ph4 = ph1 + ph2 + ph3;
                //var n = ph4.length;
                //if (n != 10) {
                    //alert('You forgot to include a valid phone number.');
                    //$('#phonenumberone').focus();
                   //return false;
                //}
            //} else if($('#country').val() == "Canada") {
                //var ph1 = $("#phonenumberone").val();
                //var ph2 = $("#phonenumbertwo").val();
                //var ph3 = $("#phonenumberthree").val();
                //var ph4 = ph1 + ph2 + ph3;
                //var n = ph4.length;
                //if (n != 10) {
                    //alert('You forgot to include a valid phone number.');
                    //$('#phonenumberone').focus();
                    //return false;
                //}
            //} else {
                //if($('#intlphone').val() == '') {
                    //alert('You forgot to include a valid phone number');
                    //$('#intlphone').focus();
                    //return false;
                //}
            //}
            
            // Make sure zipcode is filled out
            if($("#zipcode_entry").val() == '') {
                $("#zipcode_warning").show();
                $("#zipcode_entry").focus();
                $("#zipcode_entry").select();
                return false;
            } else {
                $("#zipcode_warning").hide();
            }

            // Make sure product is filled out
            if($("#product_entry").val() == '') {
                $("#product_warning").show();
                $("#product_entry").focus();
                $("#product_entry").select();
                return false;
            } else {
                $("#product_warning").hide();
            }

            // Make sure where purchased from is filled out
            if($("#wherepurchased").val() == '') {
                $("#purchase_warning").show();
                $("#wherepurchased").focus();
                $("#wherepurchased").select();
                return false;
            } else {
                $("#purchase_warning").hide();
                
                var purchasedplace = $("#wherepurchased").val();

                // If purchase place is "v-moda.com" then make sure the order number is filled out
                if (purchasedplace === "v-moda.com") {
                    if($("#ordernumber").val() != ''){
                        // set order number variable
                        var ordernumbername = $("#ordernumber").val();
                        // strip "#" sign from order number, if exists
                        ordernumbername = ordernumbername.replace("#","");
                        // set order number length
                        var ordernumberlen = ordernumbername.length;

                        // check if order number is numeric or not (it should be for US orders, it may not be for EU orders)
                        if(!$.isNumeric(ordernumbername)) {
                            // if number is not numeric, it may be an EU order, so let's check
                            var firsttwocharacters = ordernumbername.substring(0,2);
                            firsttwocharacters = firsttwocharacters.toUpperCase();
                            if (firsttwocharacters != "EU") {
                                // if order number does not start with "EU", then alert customer
                                $("#order_number_warning").show();
                                $("#ordernumber").focus();
                                return false;
                            } else {
                                // order number is non numeric but starts with EU, move ahead
                                // order number WITH "EU" in the first 2 digits, SHOULD BE 6 digits total
                                if(ordernumberlen != 6) {
                                    // if order number does not have 6 digits, alert customer
                                    $("#order_number_warning").show();
                                    $("#ordernumber").focus();
                                    return false;
                                } else {
                                    $("#order_number_warning").hide();
                                }
                            }
                        } else {
                            // order number IS numeric, now let's check to see if it's 6 digits long or not
                            // check if length is 6 digits or 4 digits (an EU order without the "EU" part)
                            if (ordernumberlen != 6) {
                                if (ordernumberlen != 4) {
                                    $("#order_number_warning").show();
                                    $("#ordernumber").focus();
                                    return false;
                                } else {
                                    $("#order_number_warning").hide();
                                }
                            } else {
                                $("#order_number_warning").hide();
                            }
                        }
                    } else {
                        $("#order_number_warning").show();
                        $("#ordernumber").focus();
                        return false;
                    }
                }

                // If purchase place is "previous warranty" then make sure the previous warranty number is filled out
                else if (purchasedplace === "previous_warranty") {
                    if($("#prevwarrantynumber").val() != '') {
                        var warrantynumbername = $("#prevwarrantynumber").val();
                        var warrantynumberlen = warrantynumbername.length;
                        if(!$.isNumeric($("#prevwarrantynumber").val())){
                            $("#warranty_number_warning").show();
                            $("#prevwarrantynumber").focus();
                            return false;
                        } else {
                            if (warrantynumberlen != 6) {
                                $("#warranty_number_warning").show();
                                $("#prevwarrantynumber").focus();
                                return false;  
                            } else {
                                $("#warranty_number_warning").hide();
                            }
                        }
                    } else {
                        $("#warranty_number_warning").show();
                        $("#prevwarrantynumber").focus();
                        return false;
                    }
                }

                // If purchase place is "other" then make sure the purchase date is filled out
                else if (purchasedplace === "other") {
                    if($("#purchase_date").val() == '') {
                        $("#purchase_date_warning").show();
                        $("#purchase_date").focus();
                        return false;
                    } else {
                        $("#purchase_date_warning").hide();
                    }
                }
            }
            
            // Make sure problem is filled out
            if($("#product_problem").val() == '') {
                $("#product_problem_warning").show();
                $("#product_problem").focus();
                $("#product_problem").select();
                return false;
            } else {
                $("#product_problem_warning").hide();
            }
            
            // If the problem is "other" then make sure description is filled out
            if($("#product_problem").val() == "other" && $("#problem_description").val() == '') {
                $("#product_problem_description_warning").show();
                $("#problem_description").focus();
                $("#problem_description").select();
                return false;
            } else {
                $("#product_problem_description_warning").hide();
            }
            
            // If serial number is not blank, then make sure it's a valid serial number
            // (either "old GS1" format or "Roland" format)
            if($("#serialnumber_entry").val() != '') {

                // Set serial number value
                var serialname = $("#serialnumber_entry").val();

                // set serial number length
                var serialnumberlength = serialname.length;

                // the serial number MUST either be 7 (Roland) or 10 (GS1) digits in length
                if (serialnumberlength == 7) {
                    $("#serial_number_warning").hide();
                } else if (serialnumberlength == 10) {
                    // make sure the "GS1" (10 digit serial number) is numeric
                    if($.isNumeric(serialname)) {
                        // serial number is numeric, proceed
                        var serialfour = serialname.substring(0, 4);
                        if (serialfour == 1100 || serialfour == 1101 || serialfour == 1400) { 
                            $("#serial_number_warning").hide();
                        } else {
                            $("#serial_number_warning").show();
                            $("#serialnumber_entry").focus();
                            $("#serialnumber_entry").select();
                            return false;
                        }
                    } else {
                        $("#serial_number_warning").show();
                        $("#serialnumber_entry").focus();
                        $("#serialnumber_entry").select();
                        return false;
                    }
                } else {
                    $("#serial_number_warning").show();
                    $("#serialnumber_entry").focus();
                    $("#serialnumber_entry").select();
                    return false;
                }
            }
            
            // hide form after it's submitted
            $("#vmoda_warranty_form").hide();

            // show the thank you div container housing the iframe result
            $("#showingthanks").show();

            // adjust the iframe height
            $("#warranty_iframe").css({"height": "400px"});

            // scroll to top of thank you div
            $('html,body').animate({
                scrollTop: $("#showingthanks").offset().top - 20
            });
        });

    });
}