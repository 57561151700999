import Store from "./lib/store";

import _intersection from "lodash/intersection";
import _uniq from "lodash/uniq";

import { addToCart } from "./actions/add_to_cart";
import { getMonogramTextFor } from "./actions/get_monogram_text_for";
import { hideColorBar } from "./actions/hide_color_bar";
import { showColorBar } from "./actions/show_color_bar";
import { resetCamera } from "./actions/reset_camera";
import { getSelectedOptionFor } from "./actions/get_selected_option_for";
import { removeMaterialLayoutOption } from "./actions/remove_material_layout_option";
import { removeDesignLayoutOption } from "./actions/remove_design_layout_option";
import { removeOption } from "./actions/remove_option";
import { addMaterialLayoutOption } from "./actions/add_material_layout_option";
import { addDesignLayoutOption } from "./actions/add_design_layout_option";

export default new Store({
    state: {
        price: 0,
        currency: "$",
        shipping: [0, 0],
        options: [],
        accessories: {},
        currentModelColor: 0,
        custom_type: null,
        operating_system: null,

        data: null,

        cameraProps: {
            rotation: {},
            position: {},
        },

        defaultCameraProps: {
            rotation: {},
            position: {},
        },

        materials: {},

        monogramText: {},

        designCustomFiles: {},

        customDesignAccepted: false,

        colorBarVisible: false,
    },

    computed: {
        shipping(state) {
            // //console.log(state.shipping)
            const shipping = state.shipping || [0, 0];
            const options = state.options;
            //const accessories = state.accessories
            //console.log(state);
            if (options.length < 1) {
                return shipping;
            } else {
                let max = shipping.slice();
                const shippings = options.forEach((v) => {
                    if (!v.shipping) return;

                    if (v.shipping[1] > max[1]) {
                        max[0] = v.shipping[0];
                        max[1] = v.shipping[1];
                    }
                });

                return max;
            }
        },
        baseprice(state) {
            if (!state.data) return 0;
            const colorPrice = state.data.colors
                ? state.data.colors[state.currentModelColor].price
                : null;
            const price = colorPrice || state.price || 0;
            return price
        },

        price(state) {
            if (!state.data) return 0;

            const colorPrice = state.data.colors
                ? state.data.colors[state.currentModelColor].price
                : null;
            const price = colorPrice || state.price || 0;

            const options = state.options;
            const accessories = state.accessories;

            const accessoriesPrice = Object.keys(accessories).reduce(
                (sum, k) => {
                    const accessory = accessories[k];
                    return sum + accessory.price * accessory.count;
                },
                0
            );

            const optionsPrice = options.reduce((sum, v) => {
                if (!v.prices) {
                    return sum + v.price;
                }

                const pricesKeys = Object.keys(v.prices);

                const ids = _uniq(
                    options
                        .filter((c) => c.component === v.component)
                        .map((c) => c.sku)
                );

                if (!pricesKeys || pricesKeys.length < 1 || ids.length < 1) {
                    return sum + v.price;
                }

                const intersect = _intersection(pricesKeys, ids);

                if (intersect.length > 0) {
                    return sum + v.prices[intersect[0]];
                } else {
                    return sum + v.price;
                }
            }, 0);
            let rounded_price = price + optionsPrice;
            if (rounded_price == price) {
                rounded_price = price + optionsPrice;
            } else {
                if (rounded_price <= 1000) {
                    rounded_price = Math.ceil(rounded_price / 5) * 5;
                }
                if (rounded_price > 1000 && rounded_price <= 10000) {
                    rounded_price = Math.ceil(rounded_price / 100) * 100;
                }
                if (rounded_price > 10000 && rounded_price <= 100000) {
                    rounded_price = Math.ceil(rounded_price / 1000) * 1000;
                }
                if (rounded_price > 100000) {
                    rounded_price = Math.round(rounded_price / 1000) * 1000;
                }
            }
            return rounded_price + accessoriesPrice;
        },

        priceWithoutAccessories(state) {
            if (!state.data) return 0;

            const colorPrice = state.data.colors
                ? state.data.colors[state.currentModelColor].price
                : null;
            const price = colorPrice || state.price || 0;

            const options = state.options;

            const ids = _uniq(options.map((v) => v.sku));

            const optionsPrice = options.reduce((sum, v) => {
                if (!v.prices) {
                    return sum + v.price;
                }

                const pricesKeys = Object.keys(v.prices);

                if (!pricesKeys || pricesKeys.length < 1 || ids.length < 1) {
                    return sum + v.price;
                }

                const intersect = _intersection(pricesKeys, ids);

                if (intersect.length > 0) {
                    return sum + v.prices[intersect[0]];
                } else {
                    return sum + v.price;
                }
            }, 0);
            let rounded_price = price + optionsPrice;
            if (rounded_price == price) {
                rounded_price = price + optionsPrice;
            } else {
                if (rounded_price <= 1000) {
                    rounded_price = Math.ceil(rounded_price / 5) * 5;
                }
                if (rounded_price > 1000 && rounded_price <= 10000) {
                    rounded_price = Math.ceil(rounded_price / 100) * 100;
                }
                if (rounded_price > 10000 && rounded_price <= 100000) {
                    rounded_price = Math.ceil(rounded_price / 1000) * 1000;
                }
                if (rounded_price > 100000) {
                    rounded_price = Math.round(rounded_price / 1000) * 1000;
                }
            }
            return rounded_price;
        },

        accessoriesCount(state) {
            return Object.keys(state.accessories).reduce((sum, key) => {
                return sum + state.accessories[key].count;
            }, 0);
        },
    },

    actions: {
        addToCart,
        getMonogramTextFor,
        hideColorBar,
        showColorBar,
        resetCamera,
        getSelectedOptionFor,
        removeMaterialLayoutOption,
        removeDesignLayoutOption,
        removeOption,
        addMaterialLayoutOption,
        addDesignLayoutOption,
    },
});
