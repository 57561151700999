import React from 'react'
import {classes,fetchJSON,parameterize} from '../utils/lib'
import trap from './trap.js'

export default class CountryPop extends React.Component {

    state = {
        t: JSON.parse(this.props.translations),
        visible: this.props.visible
    }

    async componentDidMount() {
        document.onkeydown = (evt) => {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                this.close()
            }
        };
        
    }
    componentDidUpdate(prevProps){
        if ( this.props.visible ){
            this.refs.close.focus
            trap(this.refs.cont)
        }
    }


    close() {
        this.setState({
            visible: false
        })
    }

    evalAction() {
        window.location = this.props.url
    }


    render() {
        return (
            <div ref="cont" role="dialog" aria-modal="true" aria-label="Change country" className={classes("pop_form",{active : this.state.visible})}>
                <div className="login_form_container">
                <button className="login_form_close" onClick={this.close.bind(this)} aria-label="Close" ref="close" ><img className="login_close" src="https://cdn.shopify.com/s/files/1/2718/5436/t/6/assets/close.f84a66572ec026a4be27f4a5849ee4e5.svg" alt="" /></button>
                    
                    <p className="c-dark body4">{this.state.t.from} {this.state.t[this.props.from]}.<br />{this.state.t.to}  {this.state.t[this.props.to]}</p><br/>
                    <button onClick={this.evalAction.bind(this)} className={"button button-auto button-typo md black"}>OK</button>
                </div>
            </div>
        )
    }
}