import React, { Component } from 'react'
import Box from './box'
import { classes, GetCDNImage } from 'utils'
import Store from '../store'

export const OSValues = {
	android: "android",
	ios: "ios"
}

const Button = ({ label, img, selected = false, onClick }) => (	
	<button className={classes({ selected })} onClick={onClick}>
		<img src={img} />
		<span className="h12 c-mediumgray upcase">{label}</span>
		<div className="switch_os_radio"></div>
	</button>
)

export default class SwitchOs extends Component {

	state = {
		operating_system: Store.get('operating_system'),
	}

	static defaultProps = {
		fixed: false,
		labelAndroid: 'ANDROID',
		labelIos: 'IOS'
	}

	componentDidMount() {
		Store.linkState(this, ['operating_system'])
	}

	componentWillUnmount() {
		Store.unlinkState(this)
	}

	selectOs(os) {
		Store.set('operating_system', os)
	}

	async onAddToCart() {
		await this.box.onClose()
		this.props.onAddToCart({ os: this.state.operating_system })
	}

	render() {
		return <Box onClose={this.props.onClose} fixed={this.props.fixed} small={true} ref={el => this.box = el}>
		
			<div className="switch_os">
			
				<span className="switch_os_bar h11 c-mediumgray">Choose your volume control</span>
			
				<div className="switch_os_content">
					
					<Button
						label={this.props.labelIos}
						img={GetCDNImage("conf/appleinc.svg")}
						selected={this.state.operating_system === OSValues.ios}
						onClick={() => this.selectOs(OSValues.ios)}/>

					<Button
						label={this.props.labelAndroid}
						img={GetCDNImage("conf/android.svg")}
						selected={this.state.operating_system === OSValues.android}
						onClick={() => this.selectOs(OSValues.android)}/>
				
				</div>

				<div className="switch_os_bottombar">
					<button onClick={this.onAddToCart.bind(this)}>
						<img src={GetCDNImage("conf/gotocart.svg")} />
					</button>
				</div>

			</div>
		</Box>
	}
}