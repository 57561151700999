import { qs, qsa } from "../utils/lib"
export default class FaqDropdown {
    constructor(el) {
        this.el = el
        this.dropdowns = qsa('.faq_acc_dropdown', this.el)
        this.buttons = qsa('.faq_acc_button', this.el)
        this.init()
    }
    init() {
        this.buttons.forEach((btn,index) => {
            btn.addEventListener('click', this.toggleDropdown.bind(this,index))
        })
    }
    toggleDropdown(index) {
        this.dropdowns.forEach((drop,i) => {
            if (!drop.classList.contains('active') && i == index) {
                drop.classList.add('active')
            } else {
                drop.classList.remove('active')
            }
        })
    }
}