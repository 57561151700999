
import { qs, qsa } from "../utils/lib";

export default class colorPicker {
  constructor(el) {
      this.active = 0 
      this.circles = qsa('.color_circle',el)
      this.images = qsa('.product_list_image', el)
      this.init()
  }
  init() {
      this.circles.forEach((el,i) => {
        el.addEventListener("mouseover", () => {
            this.active = i
            this.applyImage()
        })
        el.addEventListener("change", () => {
            this.active = i
            this.applyImage()
        })
      })
  }

  applyImage() {
      this.images.forEach((el,i) => {
            if ( i == this.active ) {
                el.classList.add("active")
            } else {
                el.classList.remove("active")
            }
      })
  }


}
