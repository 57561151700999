import {qs,qsa} from '../utils/lib'
import gsap from 'gsap'
export default class VideoPlayer {
    constructor(el){
        this.el = el
        this.btn = qs('button', this.el)
        this.src = this.el.dataset.src
        this.player = ''
    }
    init() {
      this.importYTLibrary()
      this.btn.addEventListener('click', () => {
          this.setVideo()  

      })
    }
    importYTLibrary(){
      var tag = document.createElement('script')
      tag.src = "https://www.youtube.com/iframe_api";
      var firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
    }
    setVideo(){
        window.YT.ready(() => {
          this.player = new YT.Player('player', {
            height: '360',
            width: '640',
            videoId: this.src,
            events: {
                'onReady': this.onPlayerReady
              }
          });
          const placeholder = qs('.video_p_image', this.el)
          placeholder.classList.add('disabled')
          this.btn.classList.add('disabled')
        })

    }    
    onPlayerReady(event) {
        event.target.playVideo();
      }    

}