import "core-js";
import { fetchJSON, Observer } from "utils";
import waterfall from "async-es/waterfall";
import { Api } from "../api";
import UploadToCloudinary from "../lib/upload_to_cloudinary";

function makeCartData(store) {
  return new Promise(async (resolve) => {
    const accessories = Object.keys(store.state.accessories).map(
      (k) => store.state.accessories[k]
    );

    const custom_part_items = await Promise.all(
      store.state.options.map((option) => {
        return new Promise(async (resolve) => {
          const component = store.state.data.custom_parts.filter(
            (v) => v.name === option.component
          )[0];

          let file = {};

          if (option.file) {
            file.url = (await UploadToCloudinary(option.file)).secure_url;
            //// //console.log('file uploaded', file.url)
          }

          resolve({
            id: option.id,

            ...file,
            is_custom_design: option.is_custom_design || false,

            sku: option.sku,
            color_variant: option.color_variant,
            component_name: option.component,
            component_id: component.id,
            component_sku: component.sku,
          });
        });
      })
    );

    resolve({
      id: store.state.data.shopify_id,
      price: store.computed("priceWithoutAccessories"),
      operating_system: store.get("operating_system"),
      modelColor: store.state.data.colors[store.state.currentModelColor],
      monogramText: store.state.monogramText,
      custom_part_items: custom_part_items,
      accessories: accessories,
      shipping_days: document.querySelector(".conf_mobile_shipping > span")
        .textContent,
    });
  });
}

function makeRequest(url, data, sameorigin = false) {
  const credentials = sameorigin
    ? {
        credentials: "same-origin",
      }
    : {};

  const xml = sameorigin
    ? {
        "X-Requested-With": "xmlhttprequest",
      }
    : {};

  return fetchJSON(url, {
    method: "POST",
    body: JSON.stringify(data),
    ...credentials,
    headers: {
      "Content-Type": "application/json",
      ...xml,
    },
  });
}

export function addToCart(store) {
  const observer = new Observer();
  const URL = Api.getCartURL();

  const start = async () => {
    const data = await makeCartData(store);
    let req_finished = 0;
    const req_count = data.accessories.length + 2; // Accessories + base item + first request

    // First request to backend
    makeRequest(URL, data)
      .then((response_1) => {
        req_finished += 1;
        observer.emit("progress", req_finished / req_count, req_count);

        // Requests to shopify
        // Here, we are creating an array for async/waterfall
        const country = document.body.getAttribute("data-country");
        const locale = document.body.getAttribute("data-locale");
        const base_url = "/" + country + "/" + locale + "/cart/add.js";
        const add_to_cart = response_1.map((el) => {
          return (callback) => {
            makeRequest(base_url, el, true)
              .then((res) => {
                req_finished += 1;
                observer.emit("progress", req_finished / req_count, req_count);
                callback(null);
              })
              .catch((err) => {
                callback(err);
              });
          };
        });

        // Waterfall because shopify doesn't support async requests to cart
        waterfall(add_to_cart, (err, res) => {
          if (err) {
            return observer.emit("error", err);
          }

          observer.emit("finish");
        });
      })
      .catch((err) => {
        observer.emit("error", err);
      });
  };

  return {
    start,
    events: observer.client(),
  };
}
