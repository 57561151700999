import { filter } from "lodash"
import { qs, qsa } from "../utils/lib"
const ArtistFilters = (el) => {
    const filters = qsa('button', el)
    const artists = qsa('.artist_title')
    let current = 'music'
    let active = 0

    filters.forEach((btn, i) => {
        btn.addEventListener('click', () => {
            current = btn.dataset.filter
            if (btn.classList.contains('link')) {
                btn.classList.remove('link')
                active = ''
                current = ''
                artists.forEach((tile) => tile.classList.remove('hidden'))
            } else {
                active = i
                ActiveBtn(i)
                ApplyFilters()
            }
        })
    })
    const ApplyFilters = () => {
        artists.forEach((tile) => {
            if (tile.classList.contains(current)) {
                tile.classList.remove("hidden")
            } else {
                tile.classList.add("hidden")
            }
        })
    }
    const ActiveBtn = (index) => {
        filters.forEach((el, i) => {
            if (i == index) {
                el.classList.add('link')
                el.setAttribute('aria-selected', 'true')
                el.setAttribute('tabindex', '0')
            } else {
                el.classList.remove('link')
                el.setAttribute('aria-selected', 'false')
                el.setAttribute('tabindex', '-1')
            }
        })
    }
    el.addEventListener('keydown', (e) => {
        const key = e.key
        switch (key) {
            case "ArrowRight":
                active++
                if (active == filters.length) {
                    active = 0
                }
                current = filters[active].dataset.filter
                break
            case "ArrowLeft":
                if (active == 0) {
                    active = filters.length
                }
                active--
                current = filters[active].dataset.filter

                break
            case "Home":
                active = 0
                break
            case "End":
                active = filters.length - 1
                break
        }
        ActiveBtn(active)
        filters[active].focus()
        ApplyFilters()
    })
    ApplyFilters()
    ActiveBtn(active)
}
export default ArtistFilters