const ProductPopup = (el) => {
    const close = el.querySelector('.promo_pop_close')
    const button = el.querySelector('.promo_pop_col_button')
    const id = el.dataset.product_id
    close.addEventListener('click', () => {
        handleClick()
    })
    button.addEventListener('click', () => {
        handleClick()
    })
    const handleClick = () => {
        el.classList.add('hidden')
    }
}
export default ProductPopup