import $ from 'jquery'
import '../utils/jquery-ui.min.js'

export default function () {

    $(document).ready(function($) {
        // hide divs on startup
        $('#showingthanks').hide();

        // set nextdropdown variable for upgrade headphone selection
        let companydropdown = $('#choose_company');
        let productdropdown = $('#choose_product');

        // empty the dropdown
        companydropdown.empty();
        productdropdown.empty();

        // append the title option to next dropdown 
        companydropdown.append('<option selected="true" disabled>Select Your Company:</option>');
        companydropdown.prop('selectedIndex',0);
        productdropdown.append('<option selected="true" disabled>Select Your Product:</option>');
        productdropdown.prop('selectedIndex',0);

        // Create random URL string to prevent caching
        var cacheme = Math.random();

        // set json file
        var myjsonurl = "https://www.v-modatracking.com/webforms/masterfiles/json/epp_jsonfile.cfm?" + cacheme;
        var myproductjsonurl = "https://www.v-modatracking.com/webforms/masterfiles/json/epp_product_jsonfile.cfm?" + cacheme;

        // set selection options for available companies/domains off JSON file
        $.getJSON(myjsonurl, function(data) {
            $.each(data.EPP, function(key, value) {
                companydropdown.append($('<option></option>').val(value.DomainID).html(value.Domain));
            });
        });

        // set selection options for available products off JSON file
        $.getJSON(myproductjsonurl, function(data) {
            $.each(data.EPP_Products, function(key, value) {
                productdropdown.append($('<option></option>').val(value.ProductID).html(value.ProductName));
            });
        });

        $('#choose_company').change(function(event) {
            var companydomainid = $(this).val();
            $.getJSON(myjsonurl, function(data) {
                $.each(data.EPP, function(key, value) {
                    if(companydomainid == value.DomainID) {
                        $('#CompanyDomain').text('@'+value.Domain);
                    }
                });
            });
        });

        $('#vmoda_epp_form').submit(function() {
            
            if($('#firstname_entry').val() == '') {
                $('#firstname_warning').show();
                $('#firstname_entry').focus();
                $('#firstname_entry').select();
                return false;
            } else {
                $('#firstname_warning').hide();
            }

            if($('#lastname_entry').val() == '') {
                $('#lastname_warning').show();
                $('#lastname_entry').focus();
                $('#lastname_entry').select();
                return false;
            } else {
                $('#lastname_warning').hide();
            }
            
            if($('#email_entry').val() == '') {
                $('#email_warning').show();
                $('#email_entry').focus();
                $('#email_entry').select();
                return false;
            } else {
                $('#email_warning').hide();
            }

            if($('#choose_company').val() == '') {
                $('#company_warning').show();
                $('#choose_company').focus();
                $('#choose_company').select();
                return false;
            } else {
                $('#company_warning').hide();
            }

            if($('#choose_product').val() == '') {
                $('#product_warning').show();
                $('#choose_product').focus();
                $('#choose_product').select();
                return false;
            } else {
                $('#product_warning').hide();
            }
            
            // hide form after it's submitted
            $("#vmoda_epp_form").hide();

            // show thank you container holding iframe
            $("#showingthanks").show();

            // adjust iframe height
            $('#epp_iframe').css({"height": "600px"});

            // scroll to top of thank you div
            $('html,body').animate({
                scrollTop: $("#showingthanks").offset().top - 20
            });
            
        });

    });
}