const missingProperty = name => {
    throw Error(`Store: arguments '${name}' must be an Object`)
}

export default class State {
    
    constructor({

        state    = missingProperty('state'),
        actions  = {},
        computed = {}
    
    }) {
        this.state     = state
        this._actions  = actions
        this._computed = computed

        this.callbacks = {}
        this.scopes = []
        this.scopes_callbacks = {}
        for ( let key in this.state ) {
            this.callbacks[key] = []
        }

        window.store = this.state
    }

    computed(key) {
        return this._computed[key](this.state)
    }

    action(actionKey, params) {
        if ( params )
            return this._actions[actionKey](this, params)
        else
            return this._actions[actionKey](this)
    }
    
    set(key, value) {
        this._triggerOnChange(key, value)
    }
    
    get(key) {
        return this.state[key]
    }

    getProps(keys) {
        const obj = {}
        for ( let k of keys ) {
            obj[k] = this.state[k]
        }
        return obj
    }

    linkState(scope, keys, willChange = null, didChange = null) {
    
        this.scopes.push(scope)
    
        scope._store_watch_index = this.scopes.length
        this.scopes_callbacks[scope._store_watch_index] = {}
    
        const setState = (scope, key, _didChange) => (value) => {
            if ( typeof didChange === "function" )
                scope.setState({ [key]: value }, () => _didChange(key, value))
            else
                scope.setState({ [key]: value })
        }

        const willChangeSetState = (scope, key, value, _didChange) => () => {
            if ( typeof didChange === "function" )
                scope.setState({ [key]: value }, () => _didChange(key, value))
            else
                scope.setState({ [key]: value })
        }

        const _willChange = (scope, key, _didChange, localWillChange) => (newvalue) => {
            localWillChange(key, newvalue, willChangeSetState(scope, key, newvalue, _didChange)) 
        }
    
        for ( let k of keys ) {
            let callback
            if ( typeof willChange === "function" ) {
                callback = _willChange(scope, k, didChange, willChange)
            } else {
                callback = setState(scope, k, didChange)
            }
            this.watch(k, callback)
            this.scopes_callbacks[scope._store_watch_index][k] = callback
        }
    }

    unlinkState(scope) {
        const index = scope._store_watch_index
        const callbacks = this.scopes_callbacks[scope._store_watch_index]
    
        for ( let key in callbacks ) {
            this.watchOff(key, callbacks[key])
            delete callbacks[key]
        }
    
        delete this.scopes_callbacks[scope._store_watch_index]
        this.scopes.splice(index, 1)
    }
    
    watch(key, onChange) {
        this.callbacks[key].push(onChange)
        //this._triggerOnChange(key, this.get(key))
    }

    watchOff(key, fnToRemove) {
        for ( let i in this.callbacks[key] ) {
            const fn = this.callbacks[key][i]
            if ( fn === fnToRemove ) {
                this.callbacks[key].splice(i, 1)
                break;
            }   
        }
    }

    _triggerOnChange(key, value) {
        const old = this.state[key]
        this.state[key] = value
        this.callbacks[key].forEach( fn => {
            fn(value, old, key)
        })
    }

}