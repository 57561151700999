import React, { Component } from "react";
import feather from "feather-icons";

export default (props) => (
    <div className="conf_bar top">
        <div className="conf_bar_background"></div>
        <a
            href={props.product_page}
            className="conf_bar_back_btn navigation5 upcase"
        >
            {" "}
            <i
                dangerouslySetInnerHTML={{
                    __html: feather.icons["arrow-left"].toSvg({
                        color: "white",
                    }),
                }}
            ></i>
            <span>Back</span>
        </a>
        <h1 className="conf_prod_title upcase navigation5">{props.name}</h1>
        <div className="justify-end">
            <button
                className="conf_bar_reset_btn navigation5 upcase"
                onClick={() => window.location.reload()}
            >
                {" "}
                <i
                    dangerouslySetInnerHTML={{
                        __html: feather.icons["refresh-ccw"].toSvg({
                            color: "white",
                        }),
                    }}
                ></i>
                <span>Reset</span>
            </button>
        </div>
    </div>
);
