/* eslint-disable */
import TweenLite from 'gsap1'
import { Expo }  from 'gsap1'

export const showDisplaySign = function () {
  const display_sign = GetCDNImage("display/display_sign.gif");

  const height = 75;
  const width = 200;
  const padding = 8 * 3;

  const imagePath = window.location.protocol + display_sign;

  /*
  console.log(
    "%c",
    `font-size: 1px;
    padding: ${Math.floor(height/2) + padding/2}px ${Math.floor(width/2) + padding/2}px;
    line-height: ${height + padding}px;
    background-image: url(${imagePath});
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: transparent;
    background-position: center center;`
  );
  */
};

export function IsIphoneX() {
  // Really basic check for the ios platform
  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  // Get the device pixel ratio
  var ratio = window.devicePixelRatio || 1;

  // Define the users device screen dimensions
  var screen = {
    width: window.screen.width * ratio,
    height: window.screen.height * ratio,
  };

  // iPhone X Detection
  if (iOS && screen.width == 1125 && screen.height === 2436) {
    return true;
  }

  return false;
}

export const IsWebAssemblySupported = (function () {
  try {
    if (
      typeof WebAssembly === "object" &&
      typeof WebAssembly.instantiate === "function"
    ) {
      const module = new WebAssembly.Module(
        Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
      );
      if (module instanceof WebAssembly.Module)
        return new WebAssembly.Instance(module) instanceof WebAssembly.Instance;
    }
  } catch (e) {}
  return false;
})();

window.DEBUG = true;

export const setProtocolToURL = (x) =>
  window.location.protocol + x.replace(/https?:/gi, "");

export const getScrollTop = function () {
  if (typeof pageYOffset != "undefined") {
    //most browsers
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = D.clientHeight ? D : B;
    return D.scrollTop;
  }
};

export const parameterize = function (str1) {
  return str1
    .trim()
    .toLowerCase()
    .replace(/[^a-z0-9\-_]+/g, "-");
};

export class Observer {
  constructor() {
    this._events = {};
  }

  _getEvents(event) {
    this._events[event] = this._events[event] || [];
    return this._events[event];
  }

  emit(event, ...args) {
    const events = this._getEvents(event);
    for (let i = 0, l = events.length; i < l; i += 1) {
      events[i](...args);
    }
  }

  client() {
    return {
      on: this.on.bind(this),
      off: this.off.bind(this),
    };
  }

  on(event, fn) {
    const events = this._getEvents(event);
    if (typeof fn === "function") {
      events.push(fn);
    }
  }

  off(event, fn) {
    const events = this._getEvents(event);
    const index = events.indexOf(fn);
    if (index > -1) {
      events.splice(1, index);
      return true;
    }
    return false;
  }
}

export const GetCDNImage = function (image_id, type = "image") {
  const cloud_name = "dnain5omf";
  const base_url = `//res.cloudinary.com/{{cloud_name}}/${type}/upload/{{image_id}}`;

  return base_url
    .replace("{{cloud_name}}", cloud_name)
    .replace("{{image_id}}", image_id);
};

export const GetCDNPath = function (scope, type = "image") {
  const cloud_name = "dnain5omf";
  const base_url = `//res.cloudinary.com/{{cloud_name}}/${type}/upload/`;
  return base_url.replace("{{cloud_name}}", cloud_name);
};

export const sleep = (time) =>
  new Promise((res) => {
    setTimeout(() => res(true), time);
  });

export const isHTMLElement = (x) => x instanceof HTMLElement;

export const clearStyleProps = function (elements, props) {
  function clearStyles(el) {
    if (props === "all") {
      el.removeAttribute("style");
    }
  }

  if (elements.length && elements.length > 0) {
    for (let i = 0; i < elements.length; i += 1) {
      isHTMLElement(elements[i]) && clearStyles(elements[i]);
    }
  } else if (elements instanceof HTMLElement) {
    clearStyles(elements);
  }
};

export const deg2rad = (deg) => (deg * Math.PI) / 180;

export const isString = (x) => typeof x === "string";
export const isImageURL = (x) => isString(x) && /\.(png|jpe?g)$/gi.test(x);
export const isHexColor = (x) => isString(x) && /^#.+/i.test(x);

export const loadImage = (src) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.src = src;
    image.onload = () => resolve(image, src);
    image.onerror = () => reject(src);
  });
};

export const loadImages = (sources) => {
  return Promise.all(sources.map((src) => loadImage(src)));
};

export const isTouchDevice = () =>
  "ontouchstart" in window || navigator.maxTouchPoints;

export const supportWebGL = function () {
  // Create canvas element. The canvas is not added to the
  // document itself, so it is never displayed in the
  // browser window.
  var canvas = document.createElement("canvas");
  // Get WebGLRenderingContext from canvas element.
  var gl =
    canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  // Report the result.

  return gl && gl instanceof WebGLRenderingContext;
};

export const setState = (scope, props) =>
  new Promise((resolve) => scope.setState(props, resolve));

export const sameInstance = (a, b) => a.constructor.name === b.constructor.name;

export const isFunc = (fn) => typeof fn === "function";

export const hasProperty = (obj, prop) =>
  Object.prototype.hasOwnProperty.call(obj, prop);

export const log = function () {
  window.DEBUG ? console.log(...arguments) : null;
};

export const arrayIndex = (i, l) => (i < 0 ? (l + i) % l : i % l);

export const isUndefined = (x) => typeof x === "undefined";

export const animate = (el, time, props, toProps = null, ease) => {
  return new Promise(resolve => {
    const to = {
      ...( toProps || props ),
      ease: ease || Expo.easeInOut,
      onComplete: resolve
    }
    if ( toProps !== null )
      TweenLite.fromTo(el, time, props, to)
    else
      TweenLite.to(el, time, to)
  })
}

// Get json from api url with promise
export const fetchJSON = (url, props) => {
  return new Promise((res, rej) => {
    fetch(url, props)
      .then((response) => response.json())
      .then((data) => res(data))
      .catch((err) => rej(err));
  });
};

// Convert deg to radiants
export const toRadiands = (deg) => (deg * Math.PI) / 180;

export class Cookie {
  static create(name, value, days) {
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      var expires = "; expires=" + date.toGMTString();
    } else var expires = "";
    document.cookie = name + "=" + value + expires + "; path=/";
  }

  static get(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i += 1) {
      var c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1);
      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }
    return "";
  }
}

export const classes = function classes(...args) {
  const res = [];
  for (let cl of args) {
    if (typeof cl === "string") {
      res.push(cl);
    } else if (typeof cl === "object") {
      for (let k in cl) {
        if (cl[k]) {
          res.push(k);
        }
      }
    }
  }
  return res.join(" ");
};
