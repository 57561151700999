import {  qs, qsa, fetchJSON } from "../utils/lib";
import Selectr from "mobius1-selectr";

export default class Manuals {
    constructor(el) {
      this.el = el;
      this.select_element = qs('select',this.el)
      this.select = new Selectr(this.select_element, {
		searchable: false,
        defaultSelected: false

	  });
      this.target = ""
      this.link = qs('.btn-manuals',this.el)
      this.init()
    }
    init() {
        console.log("init")
        this.select.on('selectr.change', (option) => {
            this.target = option.value
            this.updateLink()
        });
    }
    updateLink() {
        this.link.setAttribute("href",this.target)  
    }
}