import { qs } from "../utils/lib";
class CenterTextPopup {
    constructor(el) {
        this.el = el;
        this.trigger = this.el.querySelector(".icon_btn");
        this.popup = this.el.querySelector(".video_p");
        this.close_btn = this.el.querySelector(".close_btn");
        this.src = this.popup.dataset.src;
        this.player = "";
        this.init();
    }
    init() {
        this.importYTLibrary();
        this.trigger.addEventListener("click", () => {
            this.popup.classList.add("active");
            this.setVideo();
        });
        this.close_btn.addEventListener("click", () => {
            this.popup.classList.remove("active");
        });
    }
    importYTLibrary() {
        var tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
    setVideo() {
        window.YT.ready(() => {
            this.player = new YT.Player("player", {
                height: "360",
                width: "640",
                videoId: this.src,
                events: {
                    onReady: this.onPlayerReady,
                },
            });
            const placeholder = qs(".image_wrapper", this.el);
            placeholder.classList.add("disabled");
        });
    }
    onPlayerReady(event) {
        event.target.playVideo();
    }
}
export default CenterTextPopup;
