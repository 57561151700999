import React, { Component } from 'react'
import { classes, setState, animate, hasProperty, clearStyleProps, GetCDNImage } from 'utils'
import Store from '../../../store'
import _groupBy from 'lodash/groupBy'
import _map from 'lodash/map'

import {
	Tabs,
	SidebarRow,
	SidebarOption,
} from '../components'

export default class Design extends Component {
		
	state = {
		data: [],
		currentCategory: null,
		activeOption: null
	}

	constructor() {
		super(...arguments)

		this.binds = {
			watchOptions: this.watchOptions.bind(this),
			setOption: this.setOption.bind(this),
			closeCategory: this.closeCategory.bind(this),
		}
	}

	setOption(option) {
		const activeOption = Store.action("addDesignLayoutOption", {
			option: option,
			partName: this.props.extraName,
		})

		this.setState({
			activeOption,
		})
	}

	componentDidMount() {
		// //console.log("ciao")
		this.setInitialState(this.props)
		Store.watch('options', this.binds.watchOptions)
	}

	componentWillUnmount() {
		Store.watchOff('options', this.binds.watchOptions)
	}

	async selectCategory(category, option) {
		
		await setState(this, {
			currentCategory: category,
		})

		const activeOption = Store.action("addDesignLayoutOption", {
			option: option,
			partName: this.props.extraName,
		})

		this.onOpenInnerSection()

		animate(this.animator, 0.8, {
			x: "-100%", 
		})
	}

	async closeCategoryAnimation() {
		this.onCloseInnerSection()

		await animate(this.animator, 0.8, {
			x: "0%", 
		})

		return setState(this, {
			currentCategory: null,
		})
	}

	async closeCategory() {
		const current = this.state.data[this.state.currentCategory]

		await this.closeCategoryAnimation()

		if ( this.state.activeOption === null ) {
			Store.action('removeOption', {
				option: current,
				partName: this.props.extraName
			})
		}
	}

	async watchOptions(options) {
		
		let withComponent = false

		for ( let option of options ) {	
			if ( option.component === this.props.extraName )
				withComponent = true
	
			if ( option === this.state.activeOption )
				return

			if ( !option.is_design && withComponent ) {
				return this.setState({
					activeOption: option
				})
			}

		}

		await setState(this, {
			activeOption: null
		})

		if ( !withComponent )
			this.closeCategoryAnimation()
	}

	checkSelectedOption() {
		let selected = Store.action("getSelectedOptionFor", {
			partName: props.extraName,
			is_design: false
		})

		this.setState({
			activeOption: selected
		})
	}

	setInitialState(props) {

		let selected = Store.action("getSelectedOptionFor", {
			partName: props.extraName,
			is_design: false
		})
		
		this.setState({
			data: props.data.design,
			currentCategory: null,
			activeOption: selected
		})

		clearStyleProps(this.animator, "all")
	}

	componentWillReceiveProps(newprops) {
		if ( newprops.extraName === this.props.extraName )
			return

		Store.action("resetCamera")
		Store.action("showColorBar")
		this.setInitialState(newprops)
	}

	onCloseInnerSection() {
		Store.action("resetCamera")
		Store.action("showColorBar")
	}

	onOpenInnerSection() {
		if ( this.props.camera ) {
			Store.set('cameraProps', this.props.camera )			
		}
		Store.action("hideColorBar")
	}

	getInnerContent() {
		return _groupBy(this.state.data[this.state.currentCategory].child_items, 'category')
	}

	render() {
		const tabs = Object.keys(this.props.data)

		return (
			<div className="conf_sidebar_container" ref={(el) => this.container = el}>

				<Tabs tabs={['Design']} activeTab={0} translations={this.props.translations}/>

				<div className="conf_sidebar_tab_content">

					<div className="conf_sidebar_content_animator" ref={(el) => this.animator = el }>

						<div className="conf_sidebar_scroll">	
							{ this.state.data.map((v,i) => (
								<SidebarRow name={v.title}
											img={v.img}
											key={v.toString() + i}
											onClick={() => this.selectCategory(i, v)}/>
							))}
						</div>

						<div className="conf_sidebar_innersection">
							
							<button className="conf_sidebar_inner_head h12 c-light regular"
									onClick={this.binds.closeCategory}>
								<img alt="Go Back" src={GetCDNImage("conf/arrow.svg")} className="conf_sidebar_inner_head_arrow"/>
								{ this.state.currentCategory !== null && (
									<img src={this.state.data[this.state.currentCategory].img} className="conf_row_img"/>
								)}
								{ this.state.currentCategory !== null && this.state.data[this.state.currentCategory].title}
							</button>

							<div className="conf_sidebar_inner_scroll">
							{
								this.state.currentCategory !== null && _map(this.getInnerContent(), (content, section) => (
									<div className="conf_inner_section" key={section.toString()}>
									
										<span className="conf_inner_sect_title h12 regular trajan c-mediumgray">{section}</span>

										{
											content.map((v,i) => (
												<SidebarOption
													name={v.title}
													key={v.id.toString() + i}
													img={v.img}
													active={
														this.state.activeOption
														&& v.id === this.state.activeOption.id
													}
													onClick={() => this.binds.setOption(v)}/>
											))
										}
									</div>
								))
							}
							</div>
						
						</div>

					</div>

				</div>

			</div>
		)
	}
}