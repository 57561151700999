
import { qs, qsa } from "../utils/lib";

export default class npr {
  constructor(el) {
      this.el = el
      this.from = el.querySelector('.immortal_life_product_from select')
      this.to = el.querySelector('.immortal_life_product_to select')
      this.products = JSON.parse(el.dataset.products)
      this.init()
  }
  init() {
     this.setFields()
     this.from.addEventListener("change", () => {
         this.setFields()
     })
  }

  setFields() {
    if ( this.from.value.length > 0 ) {
        document.querySelector('.immortal_life_product_to').classList.remove("disabled")
        this.to.innerHTML = "";
        this.to.removeAttribute("disabled")
        const group = this.from.querySelector('option:checked').parentElement.label
        this.products.forEach((prod) => {
            if ( prod[0] == group ) {
                prod[1].forEach((opt) => {
                    const option = document.createElement("option");
                    option.text = opt[0];
                    option.value = opt[1]
                    this.to.appendChild(option);
                })
            }
        })
    } else {
        document.querySelector('.immortal_life_product_to').classList.add("disabled")
        this.to.setAttribute("disabled", true)
    }
    
  }


 

}
