import { qs, qsa } from "../utils/lib";
import { gsap, Power2 } from "gsap";

const tl = gsap.timeline();

export default class OnlineRetailers {
  constructor(el) {
    this.accordion = el;
    this.plusImg = qs(".faq_accordion_icon_plus", el);
    this.minusImg = qs(".faq_accordion_icon_minus", el);
    this.pList = qs(".faq_accordion_text_p", el);
    this.pListContent = qs(".faq_accordion_text", el);
    this.status = true;
    
  }

  init() {
    this.accordion.addEventListener("click", (evt) => {
      this.plusImg.classList.toggle("hide");
      this.minusImg.classList.toggle("hide");
      this.pListContent.classList.toggle("hide");

      this.showHide(this.pList);
      this.status = !this.status;
    });
  }

  showHide(element) {
    const button = this.accordion.querySelector('button')
    button && button.setAttribute('aria-expanded', this.status ? "true" : "false")
    this.status ? element.classList.add('active') : element.classList.remove('active')
    tl.to(element, {
      height: this.status ? "auto" : 0,
      autoAlpha: this.status ? 1 : 0,
      duration: 0.2,
      ease: Power2.easeInOut,
    });
  }
}
