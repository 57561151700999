import { qs, qsa } from "../utils/lib";
import gsap from "gsap";
import Selectr from "mobius1-selectr";

export default class Menu {
  constructor(el) {
    this.el = el;
    this.isAnimating = false;
    this.primary_buttons = qsa('.js-primary-btn', this.el)
    this.menuTl = gsap.timeline();
    this.open_menu_btn = qs(".js-open-menu");
    this.close_menu_btn = qs("button[aria-label=close-menu]");
    this.images_background = qsa(".js-menu-background", this.el);
    this.active_img = 0;
    this.src = "";
    this.sub_m_btns = qsa(".js-sub-menu", this.el);
    this.sub_open_bnts = qsa(".js-sub-menu-open", this.el);
    this.close_sub_m_btns = qsa(".js-sub-menu-close", this.el);
    this.dropdown_btns = qsa('[data-dropdown="true"]', this.el);
    this.menu_icons = qsa(".header-icon");
    this.logo = qs(".js-logo");
    this.navbar = qs(".navigation");
    this.duration = 0.8;
    this.selects = qsa(".js-menu-select", this.el);
    this.location = document.body.dataset.country;
    this.language = document.body.dataset.locale;
  }
  init() {

    //this.setAnimationElements();
    this.open_menu_btn.addEventListener("click", this.openMenu.bind(this));
    this.close_menu_btn.addEventListener("click", this.closeMenu.bind(this));

    this.sub_m_btns.forEach((btn, i) => {
      btn.addEventListener("click", (e) => {
        this.openSubMenu(i);
      });
    });
    this.sub_open_bnts.forEach((open_btn, i) => {
      open_btn.addEventListener("click", (e) => {
        this.openSubMenu(i);

      });
    });
    this.close_sub_m_btns.forEach((btn, i) => {
      btn.addEventListener("click", () => {
        this.closeSubMenu();
        qsa('.js-sub-menu')[i].focus()
      });
    })

    this.dropdown_btns.forEach((btn, i) => {
      btn.addEventListener("click", (e) => {
        if (window.innerWidth < 1024) {
          this.toggleDropdownMobile(btn, i);
        } else {
          this.showDropdown(btn, i, e);
        }

      });
    });
    this.selects.forEach((sel) => {
      sel.addEventListener("change", (event) => {
        window.location.href = event.target.value
      });
    });
    this.keyEvents()
    this.hoverTitles();
  }

  keyEvents(e) {
    window.addEventListener('keydown', (e) => e.key === "Escape" && this.el.classList.contains('open') && this.closeMenu())
    const open_menu_btn = qs('button[aria-label=Menu]')
    open_menu_btn.addEventListener('keypress', (e) => e.key === "Enter" && this.openMenu())
    const close_btn_menu = qs('button[aria-label=close-menu]')
    close_btn_menu && close_btn_menu.addEventListener('keypress', (e) => e.key === "Enter" && this.closeMenu())

    const KEYCODE_TAB = 9;
    const firstFocusableEl = qs('.new_menu .first-focusable-element')
    const lastFocusableEl = qs('.new_menu .last-focusable-element')
    this.el.addEventListener('keydown', function (e) {
      console.log(document.activeElement)
      var isTabPressed = (e.key === 'Tab' || e.keyCode === KEYCODE_TAB);
      if (!isTabPressed) {
        return;
      }

      if (e.shiftKey) /* shift + tab */ {
        if (document.activeElement === firstFocusableEl) {
          lastFocusableEl.focus();
          e.preventDefault();
        }
      } else /* tab */ {
        if (document.activeElement === lastFocusableEl) {
          firstFocusableEl.focus();
          e.preventDefault();
        }
      }
      const titles = qsa(".menu_list_item", this.el);
      const images = qsa('.js-menu-background')
      titles.forEach((t, i) => {
        const el = t.firstElementChild;
        if (e.target == el) {
          this.active_img = i;
          images.forEach((img, i) => {
            if (i == this.active_img) {
              img.setAttribute("src", img.dataset.src)
              img.classList.add("active");
            } else {
              img.classList.remove("active");
            }
          });
        }
      });
    });
  }

  setAnimationElements() {
    gsap.set('[data-animation="fade-in-up"]', {
      y: 30,
      opacity: 0,
    });
    gsap.set('[data-animation="fade-in"]', {
      opacity: 0,
    });
  }
  openMenu() {
    if (this.isAnimating) {
      return false;
    } else {

      // this.openMenuTransition();
      qs('html').style.overflow = "hidden"
      qs('body').style.overflow = "hidden"
      this.el.classList.add("open");
      qs('.menu') && qs('.menu .first-focusable-element').focus()
      qs('.new_menu') && qs('.new_menu .first-focusable-element').focus()
      // this.animateItems();
      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
      }, 1000);

    }
  }
  closeMenu() {
    if (this.isAnimating) {
      return false;
    } else {
      //this.closeMenuTransition();
      this.el.classList.remove("open");
      qs('html').style.overflow = "auto"
      qs('body').style.overflow = "auto"
      this.open_menu_btn.classList.add("active");
      this.open_menu_btn.focus()
      this.dropdown_btns.forEach((drop) => {
        const dr_wrapper = qs(".dropdown_menu", drop);
        dr_wrapper && dr_wrapper.classList.remove("active");
      });
      const arrows = qsa(".menu_list_item_arrow");
      this.dropdown_btns.forEach((btn, i) => {
        const h1 = btn.querySelector("button");
        h1.classList.remove("active");
      });
      arrows.forEach((a, i) => a.classList.remove("active"));
      // Add Navbar divs background White

      // Show Sub Navbar when Menu is closed
      const pr_sub_nav = qs(".navigation_products_tab");
      pr_sub_nav && pr_sub_nav.classList.remove("hidden");
      // this.navbar.nextElementSibling.classList.remove("active");
      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
      }, 1000);
    }
  }
  hoverTitles(e) {
    const titles = qsa(".menu_list_item", this.el);
    titles.forEach((t, i) => {
      const el = t.firstElementChild;
      el.addEventListener("mouseover", (e) => {
        this.active_img = i;
        if (e.target == el) {
          this.animateBackground(this.active_img);
        }
      });
    });
  }
  animateBackground(index) {
    this.images_background.forEach((img, i) => {
      if (i == index) {
        img.setAttribute("src", img.dataset.src)
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }
  openSubMenu(index) {
    const sub_menu = qs(".sub_menu") || qs(".new_sub_menu");
    sub_menu.classList.add("open");
    this.showSubMenuContent(index);
    if (window.innerWidth <= 1024) {
      sub_menu.style.position = "fixed";
      sub_menu.style.overflow = "scroll";
    }
  }
  closeSubMenu() {
    const sub_menu = qs(".sub_menu") || qs(".new_sub_menu");
    sub_menu.classList.remove("open");
    this.hideSubMenuContent();
  }
  showSubMenuContent(index) {
    let sub_menus = qsa(".sub_menu_content");
    const new_sub_menu = qsa(".new_sub_menu_content")
    if (sub_menus.length == 0 && new_sub_menu.length > 0) {
      sub_menus = new_sub_menu
    }
    sub_menus.forEach((menu, i) => {
      index == menu.dataset.index
        ? menu.classList.add("active")
        : menu.classList.remove("active");
    });
    let active = qs('.sub_menu_content.active') || qs('.new_sub_menu_content.active')
    console.log(active)
    var dialog = active
    var firstFocusableElement = dialog.querySelector('.first-focusable-element');
    var lastFocusableElement = dialog.querySelector('.last-focusable-element');
    firstFocusableElement.focus()
    dialog.addEventListener('keydown', function (e) {
      if (e.target == firstFocusableElement && e.key == 'Tab' && e.shiftKey) {
        e.preventDefault();
        lastFocusableElement.focus();
      } else if (e.target == lastFocusableElement && e.key == 'Tab' && !e.shiftKey) {
        e.preventDefault();
        firstFocusableElement.focus();
      }
    });
  }
  hideSubMenuContent() {
    const sub_menus = qsa(".sub_menu_content");
    sub_menus.forEach((menu, i) => menu.classList.remove("active"));
  }
  toggleDropdownMobile(btn, index) {
    const button = btn.querySelector('button')
    button.classList.toggle('c-light')
    button.classList.toggle('c-gray')
    const dropdown = qs(".dropdown_menu", btn) || qs(".new_dropdown_menu", btn);
    const sub_menu = qs('.bottom_menu_cont')
    dropdown.classList.toggle('active')
    const dropdowns = qsa(".dropdown_menu")
    let all_closed = dropdowns.every(el => !el.classList.contains('active'))
    if (all_closed) {
      sub_menu.classList.remove('relative')
    } else {
      sub_menu.classList.add('relative')
    }


    const arrows = qsa(".menu_list_item_arrow");
    arrows.forEach((a, i) => {
      index == i ? a.classList.toggle("active") : "";
    })
  }
  showDropdown(btn, index, event) {


    const dropdown = qs(".dropdown_menu", btn);
    this.dropdown_btns.forEach((drop) => {
      const dr_wrapper = qs(".dropdown_menu", drop);
      if (dr_wrapper == dropdown) {
        dr_wrapper.classList.add("active")
      } else {
        dr_wrapper.classList.remove("active")
      }
    });


    const dropdowns = qsa(".menu_list_dropdown_inner", this.el);
    dropdowns.forEach((drop, i) => {
      const dp_wrapper = qs(".dropdown_menu", drop);
      if (index == drop.dataset.dropdown_index) {
        dp_wrapper.classList.add("active");

      } else {
        dp_wrapper.classList.remove("active");

      }
    });
    this.animateListItem(index);
  }
  animateListItem(index) {
    const arrows = qsa(".menu_list_item_arrow");
    this.dropdown_btns.forEach((btn, i) => {
      const button = btn.querySelector("button");
      if (index == i) {
        button.classList.add("active");

      } else {
        button.classList.remove("active");

      }
    });

    arrows.forEach((a, i) => {
      if (window.innerWidth > 1024) {
        index == i ? a.classList.add("active") : a.classList.remove("active");
      } else {
        index == i ? a.classList.toggle("active") : "";
      }
    });
  }
  animateItems() {
    gsap.to('[data-animation="fade-in-up"]', {
      y: 0,
      opacity: 1,
      duration: this.duration,
    });
    gsap.to('[data-animation="fade-in"]', {
      opacity: 1,
      duration: this.duration,
    });
  }
  openMenuTransition() {
    qs('.main_header').classList.add("active")

    gsap.to(".menu", {
      overflow: "scrollY",
      duration: 0,
    });

    qs(".main_header header").classList.remove("navigation");

    gsap.to([".navigation_navbar_menu svg", ".navigation_navbar_icons svg"], {
      color: "white",
      duration: 1,
    });

    gsap.fromTo(
      ".menu_list",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 2,
      }
    );
  }

  closeMenuTransition() {
    if (window.scrollY == 0) {
      qs('.main_header').classList.remove("active")
    }

    gsap.to(".menu", {
      overflow: "auto",
      duration: 0,
    });

    gsap.to(".main_header", { clearProps: "y" });

    qs(".main_header header").classList.add("navigation");

    gsap.to([".navigation_navbar_menu svg", ".navigation_navbar_icons svg"], {
      color: "black",
      duration: 1,
    });
  }
}
