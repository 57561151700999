import { qs,qsa } from "../utils/lib"
import {Howl, Howler} from 'howler';
class AudioPlayer {
    constructor(el) {
        this.el = el
        this.play_btn = this.el.querySelector('.feather-play')
        this.pause_btn = this.el.querySelector('.feather-pause')
        this.audio_src = this.el.dataset.src
        this.track_input = this.el.querySelector('.track')
        this.timer = this.el.querySelector('.audio_player_timer')
        this.duration = 0
        this.seconds = 0
        this.myVar = null        
    }
    init() {
        this.sound = new Howl({
            src: [this.audio_src],
            html5: true,
            onload: () => this.duration = this.sound.duration(this.audio_src),
            onend: () => pauseSound()
        });
        this.play_btn.addEventListener('click', () => {
            this.playSound()
        })
        this.pause_btn.addEventListener('click', () => {
            this.pauseSound()
        })          
    }
    playSound = () => {
        this.sound.play()
        this.myVar = setInterval(this.setTrack,20)
        this.play_btn.classList.add('hidden')
        this.pause_btn.classList.remove('hidden')
        if (typeof gtag !== "undefined") {
            gtag("event", "boom_pro", {
              event_category: "play",
              event_label: window.location.href
            });
          }
    }
    pauseSound = () => {
        this.sound.pause()
        clearInterval(this.myVar)
        this.play_btn.classList.remove('hidden')
        this.pause_btn.classList.add('hidden')
    } 
    setTrack = () => {
        this.seconds = this.sound.seek()
        this.track_input.value = (this.seconds * 100 / this.duration)
        this.timer.innerHTML = this.seconds.toFixed(2).toString().replace('.',':')

    }
}
export default AudioPlayer