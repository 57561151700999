import { qs, qsa } from "../utils/lib";

export default function() {
    const tabs = qsa('.tab_button')
    const conts = qsa('.user_tabs_content')
    let active = 0
    tabs.forEach((el,i) => {
        el.addEventListener("click", () => {
            active = i
            conts.forEach((con,i) => {
                if ( i == active ) {
                    con.classList.add("active")
                } else {
                    con.classList.remove("active")
                }
            })
            tabs.forEach((con,i) => {
                if ( i == active ) {
                    con.classList.add("active")
                } else {
                    con.classList.remove("active")
                }
            })
        })
    })
}