import { qs, qsa, parameterize } from "../utils/lib";
import _ from "lodash";
import Selectr from "mobius1-selectr";
export default class ProductFilters {
  constructor(el) {
    this.el = el;
    this.open_filt_btn = qs(".js-product-filter-button");
    this.filter_component = qs(".js-filter-component");
    this.open = false;
    this.counter = qs(".js-filter-counter");
    this.count = 0;
    this.pr_count = 0;
    this.clear_btn = qs(".js-clear-filters");
    this.active_filters = [];
    this.select_element = qs(".js-product-sorting");
    this.sort_order = "";
    this.products = qsa(".js-product-index");
    this.close_mobile = qs(".js-filter-component-close")
    this.filters_feats = []
    this.result_counter = qs('.js_results')
    this.select = qs(".js-product-sorting");
    this.select_btns = qsa(".selectr-selected", this.el);
    this.products_cont = qs(".product_grid_container");
  }
  init() {
    const pr_select = qs('.products_select')
    if ( pr_select ) {
      const product_select = new Selectr(pr_select, {
        searchable: false,
      });
      product_select.on("selectr.change", (option) => {
        window.location = option.value
      });
    }
    this.counter.innerHTML = this.count;
    this.open_filt_btn &&
      this.open_filt_btn.addEventListener("click", () => {
        this.open ? this.closeFilterWrapper() : this.openFilterWrapper();
      });

    this.select.addEventListener("change", () => {
      this.sort_order = this.select.value;
      this.applySort();
    });
    this.close_mobile.addEventListener('click', () => {
      this.closeFilterWrapper()
    })
    qs('.js-filter-button-mobile').addEventListener('click', () => {
      qs('.js-filter-component-mobile').classList.remove("open");
      this.open = !this.open;
      this.applyFilters()
    })
    qsa(".filter_button", this.filter_component).forEach((button) => {
      button.addEventListener("click", () => {
        console.log(button)
        if (!button.classList.contains("filter_button_active")) {
          button.classList.add("filter_button_active");
          button.setAttribute('aria-pressed','true')
          this.updateCounter(1);
        } else {
          button.classList.remove("filter_button_active");
          button.setAttribute('aria-pressed','false')
          this.updateCounter(-1);

        }
        this.applyFilters()
      });
    });
    this.clear_btn &&
      this.clear_btn.addEventListener("click", this.clearFilters.bind(this));
    this.select.addEventListener("change", () => {
      this.sort_order = this.select.value;
    });
  }
  openFilterWrapper() {
    this.open_filt_btn.setAttribute('aria-expanded',"true")
    this.filter_component.classList.add("open");
    this.open = !this.open;
  }
  closeFilterWrapper() {
    this.open_filt_btn.setAttribute('aria-expanded',"false")
    this.filter_component.classList.remove("open");
    this.open = !this.open;
  }
  updateCounter(count) {
    this.count += count;
    this.counter.innerHTML = this.count;
  }
  clearFilters() {
    this.count = 0;
    this.counter.innerHTML = this.count;
    qsa("button", this.filter_component).forEach((button) => {
      button.classList.remove("filter_button_active");
    });
  }
  openSort() {
    const options = qsa(".selectr-option");
    options.forEach((opt) => {
      opt.addEventListener("click", () => {
        this.applySort(opt);
      });
    });
    
  }

  applyFilters() {
    this.pr_count = 0
    this.filters_feats = []
    const filtered = qsa('.filter_button_active')
    filtered.forEach((el) => {
      this.filters_feats.push(parseInt(el.getAttribute('data-id')))
    })
    if ( filtered.length == 0 ) { 
        this.products.forEach((p) => {
            p.classList.remove("product_hidden")
        })
        this.result_counter.innerHTML = this.products.length + " "
        return false
    }
    this.products.forEach((p) => {
        if ( 
          this.filters_feats.filter(function(x) {
            if( JSON.parse(p.dataset.features).indexOf(x) != -1)
                return true;
            else
                return false;
          }).length > 0 
        ) {
            p.classList.remove("product_hidden")
        } else {
            p.classList.add("product_hidden")
        }
    })

    this.result_counter.innerHTML = qsa('.product_hidden').length + " "
    
  }
  applySort() {
    const label = document.querySelector('label[for="sorting_products"]')
    if (this.sort_order == "featured") {
      this.products = _.sortBy(this.products, [
        function (o) {
          return parseInt(o.dataset.category);
        },
      ]);
      label.innerHTML = "Sort by featured products"
    }
    if (this.sort_order == "price_asc") {
      this.products = _.sortBy(this.products, [
        function (o) {
          return parseInt(o.dataset.price);
        },
      ]);
      label.innerHTML = "Sort by price ascending"
    }
    if (this.sort_order == "price_desc") {
      this.products = _.sortBy(this.products, [
        function (o) {
          return parseInt(o.dataset.price);
        },
      ]).reverse();
      label.innerHTML = "Sort by price descending"
    }
    this.products_cont.innerHTML = "";
    this.products.forEach((el) => {
      this.products_cont.appendChild(el);
    });
  }
}
