export default class ProductPageUsers {
    constructor() {
      // Inizializza il numero di utenti
      this.numeroUtenti = 20;
  
      // Definisci il numero minimo e massimo di utenti
      this.numeroMinimo = 5;
      this.numeroMassimo = 40;
  
      // Definisci il massimo incremento/decremento
      this.maxVariazione = 5;

      // Defisci il wrapper del testo 
      this.wrapper = document.querySelector('.js-product-page-users')
  
      // Chiama la funzione per la prima volta
      this.aggiornaNumeroUtenti();
  
      // Imposta l'intervallo per chiamare la funzione ogni 10 secondi
      setInterval(() => this.aggiornaNumeroUtenti(), 7000);
    }
  
    aggiornaNumeroUtenti() {
      // Genera una variazione casuale tra -maxVariazione e +maxVariazione
      const variazione = Math.floor(Math.random() * (2 * this.maxVariazione + 1)) - this.maxVariazione;
  
      // Aggiorna il numero di utenti
      this.numeroUtenti += variazione;
  
      // Assicurati che il numero di utenti sia compreso tra il minimo e il massimo
      this.numeroUtenti = Math.max(this.numeroMinimo, Math.min(this.numeroMassimo, this.numeroUtenti));
  
      // Aggiorna la visualizzazione del numero di utenti (sostituisci questo con la tua logica)
      if (this.wrapper) 
        this.wrapper.innerHTML = `${this.numeroUtenti} users are viewing this product`;
    }
  }
  