import { qs, qsa } from "../utils/lib"
const RadioButtons = (el) => {
    const group = el
    const radioButtons = qsa('.js_color_button', group)
    group.addEventListener('keyup', function(e) {
        switch(e.key) {
            case 'ArrowUp':
            case 'ArrowLeft':
                selectPreviousRadioButton(e.target);
                break;
                
            case 'ArrowDown':
            case 'ArrowRight':
                selectNextRadioButton(e.target);
                break;
                
            case ' ':
                selectRadioButton(e.target);
                break;
        }
    });

    const selectPreviousRadioButton = (radioButton) => {
        var index = Array.prototype.slice.call(radioButtons).indexOf(radioButton);
        
        if(index > 0) {
            selectRadioButton(radioButtons[index - 1]);
        } else {
            selectRadioButton(radioButtons[radioButtons.length - 1]);
        }
    }
  
    const selectNextRadioButton = (radioButton) => {
        var index = Array.prototype.slice.call(radioButtons).indexOf(radioButton);
        
        if(index < radioButtons.length - 1) {
            selectRadioButton(radioButtons[index + 1]);
        } else {
            selectRadioButton(radioButtons[0]);
        }
    }
  
    const selectRadioButton = (radioButton) => {
        // Deselect all other radio buttons
        radioButtons.forEach(function(otherRadioButton) {
            otherRadioButton.setAttribute('tabindex', -1);
            otherRadioButton.setAttribute('aria-checked', false);
        });
    
        // Select the provided radio button
        radioButton.setAttribute('tabindex', 0);
        radioButton.setAttribute('aria-checked', true);
        radioButton.focus();
    }
}
export default RadioButtons