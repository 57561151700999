import { qs, qsa } from "../../utils/lib";
import { gsap, Power1 } from "gsap";
import homeHeroSecondSlide from "./home-hero-secondSlide";

export default class homeHeroFirstSlide {
  constructor() {
    this.tl = gsap.timeline();
    this.secondSlideClass = new homeHeroSecondSlide();

    //First Slide
    this.firstText = qs(".firstSlide .text1");
    this.firstCta = this.firstText.querySelector('a.button')
    this.firstImage = qs(".firstSlide .image_container .img1");
    this.svg = qs(".svg");
    this.rect = qs(".rect");

    //Second Slide
    this.secondText = qs(" .secondSlide .text2");
    this.secondImage = qs(".secondSlide .img2");
    this.secondSvg = qs(" .svg2 svg");
    this.vShape = qs(" .svg2 .v-shape");
    this.vShapePL = this.vShape.getTotalLength();
    this.triangle = qs(" .svg2 .triangle");
    this.speed = 1.5;
    this.percent = 1;
    this.links = qsa('a.button', this.el)

    //Third Slide
    this.thirdSlide = qs(".thirdSlide");
  }

  //First Slide In Animation + 2nd Slide preparation
  firstSlideIn(name) {
    this.firstCta.classList.remove('disabled')
    gsap.to(
      [
        ".nextButton svg circle",
        ".nextButton path",
        ".prevButton svg circle",
        ".prevButton path",
      ],
      {
        stroke: "black",
        duration: 1,
      }
    );

    gsap.to(".arrow-button-explore-carousel p", {
      color: "black",
      duration: 1,
    });

    gsap.to(".arw_svg", {
      color: "black",
      duration: 1,
    });

    qs(".navigation_navbar_logo svg g").classList.remove("active");

    gsap.set(".firstSlide", {
      zIndex: 10,
    });

    this.tl.fromTo(
      this.firstText,
      {
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 2,
        ease: Power1.easeIn,
      },
      name
    );

    this.tl.fromTo(
      this.firstImage,
      {
        x: -100,
        y: -100,
        opacity: 1,
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        duration: 2,
        zIndex: 2,
        ease: Power1.easeOut,
      },
      name
    );

    this.tl.fromTo(
      this.svg,
      {
        x: "50%",
        y: "100%",
        opacity: 1,
      },
      {
        y: 0,
        x: 0,
        opacity: 1,
        duration: 2,
        scale: 1,
        zIndex: 0,
        ease: Power1.easeOut,
        onComplete: () => {
          gsap.set([this.secondText, this.secondImage], { clearProps: "all" });
          gsap.to([this.secondText, this.secondImage], {
            opacity: 0,
            duration: 0,
          });
        },
      },
      name
    );
  }

  firstSlideOut(name, name2) {
    gsap.to(
      [
        ".nextButton svg circle",
        ".nextButton path",
        ".prevButton svg circle",
        ".prevButton path",
      ],
      {
        stroke: "white",
        duration: 1,
      }
    );

    gsap.to(".arrow-button-explore-carousel p", {
      color: "white",
      duration: 1,
    });

    gsap.to(".arw_svg", {
      color: "white",
      duration: 1,
    });

    gsap.to([".navigation_navbar_menu svg", ".navigation_navbar_icons svg"], {
      color: window.innerWidth > 768 ? "black" : "white",
      duration: 1,
      onComplete: () => {
        qs(".navigation_navbar_logo svg g").classList.add("active");
      },
    });

    if (window.innerWidth > 1024) {
      this.tl.to(
        this.rect,
        {
          opacity: 1,
          duration: 0,
          delay: 0.5,
        },
        name
      );

      this.tl.to(
        this.firstText,
        {
          x: "-100vw",
          duration: 0.6,
          ease: Power1.easeInOut,
        },
        name2
      );

      this.tl.to(
        this.firstImage,
        {
          x: "100vw",
          y: "-20%",
          duration: 0.7,
          ease: Power1.easeInOut,
        },
        name2
      );

      this.tl.to(
        this.svg,
        {
          scale: 2,
          x: "40vh",
          y: "-60vh",
          duration: 0.7,
          ease: Power1.easeInOut,
          onComplete: () => {
            this.hideFirstSlideElements();
            this.secondSlideClass.setupSecondSlide();
          },
        },
        name
      );

      this.tl.to(this.rect, {
        width: "100vw",
        duration: 1.5,
        delay: -0.9,
        ease: Power1.easeOut,
        onComplete: () => {
          this.secondSlideClass.animateSecondSvg();
        },
      });
    }

    this.firstSlideOutResponsive(name, name2);
  }

  firstSlideOutResponsive(name, name2) {
    if (window.innerWidth <= 1024) {
      this.tl.to(this.svg, {
        duration: 0.7,
      });

      this.tl.to(
        this.firstText,
        {
          x: "-100vw",
          duration: 0.6,
          ease: Power1.easeInOut,
        },
        name
      );

      this.tl.to(
        this.firstImage,
        {
          x: "100vw",
          y: "-20%",
          duration: 0.7,
          ease: Power1.easeInOut,
        },
        name
      );
    }

    if (window.innerWidth <= 1024 && window.innerWidth > 640) {
      this.tl.to(this.svg, {
        scale: 4,
        x: "105vw",
        y: "-100vh",
        duration: 0.9,
        delay: -0.4,
        ease: Power1.easeInOut,
        onComplete: () => {
          this.secondSlideClass.setupSecondSlide();
        },
      });

      this.tl.to(this.rect, {
        opacity: 1,
        duration: 0,
      });

      this.tl.to(this.rect, {
        width: "100%",
        duration: 1,
        delay: -0.2,
        ease: Power1.easeOut,
        onComplete: () => {
          this.secondSlideClass.animateSecondSvg();
        },
      });
    }

    if (window.innerWidth <= 640) {
      this.tl.to(this.svg, {
        scale: 6.5,
        x: "214vw",
        y: "-115vh",
        duration: 1.4,
        delay: -0.4,
        ease: Power1.easeInOut,
        onComplete: () => {
          this.secondSlideClass.setupSecondSlide();
        },
      });

      this.tl.to(this.rect, {
        opacity: 1,
        duration: 0,
        delay: -0.4,
      });

      this.tl.to(this.rect, {
        width: "100%",
        duration: 2.6,
        ease: Power1.easeInOut,
        delay: -1.8,
        onComplete: () => {
          this.secondSlideClass.animateSecondSvg();

          gsap.to(this.secondSvg, {
            opacity: 0,
          });
        },
      });
    }
  }

  hideFirstSlideElements() {
    gsap.to(this.firstText, {
      x: 0,
      duration: 0,
      delay: 1,
      onComplete: () => {
        gsap.to(this.firstText, {
          opacity: 0,
          duration: 0,
        });

        gsap.to(this.svg, {
          opacity: 0,
          duration: 0,
        });

        this.firstCta.classList.add('disabled')
        this.secondSlideClass.animateSecondSvg();
      },
    });
  }
}
