export default class Truncate {
    constructor(el) {
        this.el = el;
        this.btn = this.el.querySelector(".js-truncate-trigger");
        this.texts = Array.from(this.el.querySelectorAll(".mk_text"));
        this.init();
    }
    init() {
        this.btn.addEventListener("click", () => {
            this.texts.forEach((text) => {
                text.classList.contains("hidden")
                    ? text.classList.remove("hidden")
                    : text.classList.add("hidden");
            });
        });
    }
}
