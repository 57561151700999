const BreadcrumbWidth = (el) => {
    const selected_text = el.options[el.selectedIndex].text
    const span = document.createElement('span')
    span.textContent = selected_text
    span.classList.add(el.dataset.size)
    document.body.appendChild(span)
    const width = span.offsetWidth + 4
    span.remove()
    el.style.width = `${width}px`
}
export default BreadcrumbWidth