import gsap from "gsap/all";
import { qs, qsa } from "../utils/lib";

export default class LazyLoading {
  constructor() {
    this.target = qsa("[data-inter]");
    this.options = {
      rootMargin: "0px 0px -10px 0px",
    };
  }

  init() {
    this.target.forEach((el) => {
      this.imgs = qsa("[data-src]", el);
      if (this.imgs.length >= 1) {
          gsap.set(this.imgs, {
            opacity: 0,
            duration: 0,
          });
        }
      gsap.set(el, {
        opacity: 0,
        duration: 0,
      });
    });
    this.initObserver();
  }

  animation(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.showItems(entry.target);
        this.observer.unobserve(entry.target);
      }
    });
  }

    loadImage(el) {
        
        this.imgs.forEach((img) => {
            img.src = img.dataset.src
            gsap.to(img, {
                opacity: 1,
                duration: .5,
                ease: "Expo.easeOut",
                onComplete: () => {
                    this.showComponent(el)
                }
            })    
        })
      }
  showItems(el) {
    this.imgs = qsa("[data-src]", el);
    if (this.imgs.length >= 1) {
      this.loadImage(el);
    } else {
      this.showComponent(el);
    }
  }

  showComponent(el) {
    gsap.to(el, {
      opacity: 1,
      duration: 0.5,
      ease: "Expo.easeOut",
    });
  }

 

  initObserver() {
    this.observer = new IntersectionObserver(
      this.animation.bind(this),
      this.options
    );
    this.target.forEach((el) => {
      this.observer.observe(el);
    });
  }
}
