import React, { Component } from 'react'
import { classes, setState, animate, hasProperty } from 'utils'
import { TweenMax } from 'gsap'
import Store from '../../../store'

import Materials from './materials'
import Designs   from './designs'

import {
	Tabs
} from '../components'

export default class MaterialLayout extends Component {
		
	state = {
		data: null,
		tabs: [],
		activeTab: null,
		designContent: [],
		materialCategory: null,
		materialType:null
	}

	constructor() {
		super(...arguments)

		this.binds = {
			selectTab: this.selectTab.bind(this),
			onOpenInnerSection: this.onOpenInnerSection.bind(this),
			onCloseInnerSection: this.onCloseInnerSection.bind(this),
			onMaterialCategory: this.onMaterialCategory.bind(this),
			watchOptions: this.watchOptions.bind(this),
		}
	}

	componentDidMount() {
		this.setInitialState(this.props)
		Store.watch('options', this.binds.watchOptions)
	}

	componentWillUnmount() {
		Store.watchOff('options', this.binds.watchOptions)
	}

	componentDidUpdate() {
	}


	getMaterialCategory(options, partName) {
		for ( let option of options ) {
			if ( option.component === partName && !option.is_design ) {
				return option.material_type
			}
		}
	}

	getMaterialType(options, partName) {
		for ( let option of options ) {
			if ( option.component === partName && !option.is_design ) {
				return option.category
			}
		}
	}

	watchOptions(options) {
		const category = this.getMaterialCategory(options, this.props.extraName)
	    const type = this.getMaterialType(options, this.props.extraName)		
		
		if ( category === this.state.materialCategory )
			return

		if ( !category ) {
			this.setState({
				materialCategory: category,
				materialType: type,
				activeTab: 0,
			})
		} else {
			this.setState({
				materialCategory: category,
				materialType: type,
			})
		}
	}

	setInitialState(props) {

		const tabs = Object.keys(props.data)
						   .sort((a,b) => (a === "material") ? -1 : b === "material" ? 1 : a.localeCompare(b))
		
		//console.log(tabs,'tabs')
		//console.log(props.data)
		this.setState({
			tabs,
			activeTab: 0,
			data: props.data,
			materialCategory: this.getMaterialCategory(Store.get('options'), props.extraName),
			materialType: this.getMaterialType(Store.get('options'), props.extraName)
		})
	}

	componentWillReceiveProps(newprops) {
		if ( newprops.extraName === this.props.extraName )
			return

		Store.action("resetCamera")
		Store.action("showColorBar")
		this.setInitialState(newprops)
	}

	selectTab(index) {
		if ( index === this.state.activeTab ) 
			return

		this.setState({
			activeTab: index
		})

		Store.action("resetCamera")
		Store.action("showColorBar")
	}

	onCloseInnerSection() {
		Store.action("resetCamera")
		Store.action("showColorBar")
	}

	onOpenInnerSection() {
		if ( this.props.camera ) {
			Store.set('cameraProps', this.props.camera )			
		}
		Store.action("hideColorBar")
	}

	onMaterialCategory(category) {
		this.setState({
			materialCategory: category,
		})
	}

	isDesignEnabled() {
		return this.state.materialCategory
			&& this.state.data.design
			&& (this.state.data.design[this.state.materialCategory] || []).length > 0
	}

	render() {
		const tabs = Object.keys(this.props.data)

		return (
			<div className="conf_sidebar_container" ref={(el) => this.container = el}>

				{ this.state.tabs.length > 0 && (
					<Tabs tabs     ={this.state.tabs}
						  activeTab={this.state.activeTab}
						  onClick  ={this.binds.selectTab}
						  onCloseSidebar={this.props.close}
						  designEnabled={this.isDesignEnabled()}
						  translations={this.props.translations}/>
				)}

				{
					this.state.data ? 

					this.state.tabs[this.state.activeTab] === "material"

					? <Materials
						data={this.state.data.material}
						limited={this.props.limited}
						extraName={this.props.extraName}
						extraLabel={this.props.extraLabel}
						currentColor={this.props.currentColor}
						onOpenInnerSection={this.binds.onOpenInnerSection}
						onCloseInnerSection={this.binds.onCloseInnerSection}
						onMaterialCategory={this.binds.onMaterialCategory}
						translations={this.props.translations}
					/>

					: <Designs
					    translations={this.props.translations}
						materialCategory={this.state.materialCategory}
						materialType={this.state.materialType}
						data={this.state.data.design[this.state.materialCategory]}
						extraName={this.props.extraName}
						extraLabel={this.props.extraLabel}
						onOpenInnerSection={this.binds.onOpenInnerSection}
						onCloseInnerSection={this.binds.onCloseInnerSection}/>


					: null
				}
			</div>
		)
	}
}