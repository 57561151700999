import { qs, qsa } from "../utils/lib";

export default class NewWarranty {
  constructor(el) {
      this.el = el
      this.target = qs("#warranty_address")
      this.autocomplete = ''
      this.fieldsmap = {
        postal_code: "postal_code",
        route: "route",
        street_number: "street_number",
        locality: "city",
        postal_town: "city",
        country: "country",
        administrative_area_level_1: "state",
      }
      this.from_el = qs('#warranty_purchased_from')
      this.frommap = {
        "V-Moda.com": "order_number",
        "Other": "purchase_date",
        "Previous Warranty": "warranty_number"
      }
      this.init()
  }
  init() {
    this.from_el.addEventListener("change", (el) => {
      this.setFrom(this.from_el.value)
    })
    this.target.addEventListener("focus", () => {
      this.target.setAttribute("autocomplete","xyz")
    })
    let autocomplete = new google.maps.places.Autocomplete(this.target, {
      types: ['geocode']
    });
    this.autocomplete = autocomplete
    autocomplete.setFields(['address_component']);
    autocomplete.addListener('place_changed', this.fillAddress.bind(this));
    this.resetFroms()
  }

  setFrom(val) {
    this.resetFroms()
    const from_el = this.frommap[val]
    qs(".warranty_" + from_el).style.display = "block"
  }
  resetFroms() {
    qsa('.from_fields > .input').forEach((i) => {
      i.style.display = "none";
      i.querySelector("input").value = "";
    })
  }
  resetfields() {
  
    const fields = Object.values(this.fieldsmap)
    fields.forEach((e) => {
      console.log(e)
      const el = this.el.querySelector(`[data-google="${e}"]`)
      el.value = ''
    })
  }

  fillAddress() {
    this.resetfields()
    const fields = this.autocomplete.getPlace()
    console.log(fields)
    fields.address_components.forEach((c) => {
      const type = c.types[0]
      if ( this.fieldsmap[type] ) {
        console.log(this.fieldsmap[type])
        const el = this.el.querySelector(`[data-google="${this.fieldsmap[type]}"]`)
        el.value = c.short_name
      }
      
    })
  }

}
