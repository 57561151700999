import { qs, qsa } from "../utils/lib";
export default function responsiveVideo(videos) {
    const windowWidth = window.innerWidth;
    if (windowWidth <= 1024) {
        videos.forEach((video) => {
            const source = video.querySelector("source");
            const responsiveSrc = source.getAttribute("srcset");
            console.log(responsiveSrc);
            video.src = responsiveSrc;
        });
    }
}
