import React, { Component } from "react";
import { classes, setState, animate, hasProperty, GetCDNImage } from "utils";
import { filesToLoadFromEvent } from "./get_files";
import feather from "feather-icons";

const current_url = document
    .querySelector("body")
    .getAttribute("data-currenturl");

const LaserDesignInfo = ({ limits }) => (
    <div className="conf_file_input_section">
        <div
            className="conf_file_input_title c-dark body3"
            dangerouslySetInnerHTML={{ __html: limits }}
        >
            {/* <a href={current_url + "/support/faq"} target="_blank">
                    <img alt="Go to Faq" src="/product_page.svg" />
                </a> */}
        </div>
    </div>
);

const ColorDesignInfo = ({ limits, design_caption, print_caption }) => (
    <div>
        <div className="conf_file_input_section">
            <div
                className="conf_file_input_title c-dark body3"
                dangerouslySetInnerHTML={{ __html: limits }}
            >
                {/* <a href={current_url + "/support/faq"} target="_blank">
                    <img alt="Go to Faq" src="/product_page.svg" />
                </a> */}
            </div>
        </div>

        {/* <div className="conf_file_input_section">
            <span className="conf_file_input_title regular c-dark h6">
                {print_caption}&nbsp;&nbsp;
                <a href={current_url + "/support/faq"} target="_blank">
                    <img alt="Go to Faq" src="/product_page.svg" />
                </a>
            </span>
        </div> */}
    </div>
);

const PrintedDesignInfo = ({ limits, warr }) => (
    <div>
        <div className="conf_file_input_section">
            <div
                className="conf_file_input_title c-dark body3"
                dangerouslySetInnerHTML={{ __html: limits }}
            >
                {/* <a href={current_url + "/support/faq"} target="_blank">
                    <img alt="Go to Faq" src="/product_page.svg" />
                </a> */}
            </div>
        </div>

        <div className="conf_file_input_section">
            <img
                src={GetCDNImage("conf/custom_design_notice.svg")}
                className="conf_custom_design_notice"
            />
        </div>

        <div className="conf_file_input_section">
            <div
                className="conf_file_input_title c-dark body3"
                dangerouslySetInnerHTML={{ __html: limits }}
            >
                {/* <a href={current_url + "/support/faq"} target="_blank">
                    <img alt="Go to Faq" src="/product_page.svg" />
                </a> */}
            </div>
        </div>
    </div>
);

export default class FileInput extends Component {
    state = {
        file: null,
        blob: null,
        accepted: false,
        accepted_2: false,
        materialCategory: null,
        des_limit_modal_open: false,
    };

    async componentDidMount() {
        if (this.props.file) {
            let blob = null;
            const canPreviewFile = this.canPreviewFile(this.props.file);

            if (canPreviewFile) {
                blob = await this.readFile(this.props.file);
            }

            this.setState({
                blob,
                canPreviewFile,
                file: this.props.file,
            });
        }

        if (typeof this.props.accepted !== "undefined") {
            this.setState({
                accepted: this.props.accepted,
            });
        }

        this.checkCurrentCategory(this.props);
    }

    canPreviewFile(file) {
        if (!file) return false;
        const exts = /\.(svg|pdf|png|jpe?g)/i;
        const name = file.name;
        return exts.test(name);
    }

    readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const blob = readerEvent.target.result;
                resolve(blob);
            };
            reader.readAsDataURL(file);
        });
    }

    async setFile(file) {
        let blob = null;
        const canPreviewFile = this.canPreviewFile(file);

        if (canPreviewFile) {
            blob = await this.readFile(file);
        }

        this.setState({
            blob: blob,
            file: file,
            canPreviewFile,
        });
    }

    onChange(e) {
        const file = filesToLoadFromEvent(e)[0];
        this.setFile(file);
        this.props.onFile(file);
    }

    async onCheckboxChange(e) {
        await setState(this, {
            accepted: e.target.checked,
        });
        this.props.onCheckboxChange(this.state.accepted);
    }

    async onCheckboxChangeTwo(e) {
        await setState(this, {
            accepted_2: e.target.checked,
        });
    }

    checkCurrentCategory(props) {
        if (props.materialCategory !== this.state.materialCategory) {
            this.setState({
                materialCategory: props.materialCategory,
            });
        }
    }

    componentWillReceiveProps(newprops) {
        if (newprops.file !== this.state.file) this.setFile(newprops.file);

        this.checkCurrentCategory(newprops);
    }

    render() {
        return (
            <div className="conf_file_input">
                {/color/i.test(this.state.materialCategory) && (
                    <ColorDesignInfo
                        limits={this.props.translations["cp_custom_design_2d"]}
                        design_caption={
                            this.props.translations["cp_color_upload"]
                        }
                        print_caption={this.props.translations["cp_color_disc"]}
                    />
                )}
                {/laser/i.test(this.state.materialCategory) && (
                    <LaserDesignInfo
                        limits={this.props.translations["cp_custom_design_2d"]}
                    />
                )}
                {/3d/i.test(this.state.materialCategory) && (
                    <PrintedDesignInfo
                        warr={this.props.translations["cp_custom_design_2d"]}
                        limits={this.props.translations["cp_custom_design_3d"]}
                    />
                )}

                <div className="conf_design_checkbox c-light">
                    <label for="cp_approval" className="c-dark h12 roman">
                        <input
                            id="cp_approval"
                            type="checkbox"
                            checked={this.state.accepted}
                            onChange={this.onCheckboxChange.bind(this)}
                        />
                        <img src={GetCDNImage("conf/check_black.svg")} />
                        <p>
                            I accept and confirm that my design conforms to the{" "}
                            <button
                                className="underline c-dark"
                                role="button"
                                onClick={() =>
                                    this.setState({
                                        des_limit_modal_open: true,
                                    })
                                }
                            >
                                Design Limitations
                            </button>
                            {", "}and I authorize V-MODA to use my design. I
                            accept full responsibility should my design not
                            adhere to these conditions.
                        </p>
                    </label>
                    {this.state.des_limit_modal_open && (
                        <div className="conf_design_modal">
                            <button
                                className="conf_design_modal_close"
                                role="button"
                                onClick={() =>
                                    this.setState({
                                        des_limit_modal_open: false,
                                    })
                                }
                            >
                                <i
                                    dangerouslySetInnerHTML={{
                                        __html: feather.icons.x.toSvg(),
                                    }}
                                ></i>
                            </button>
                            <p className="c-light roman h12">
                                No copyrighted logos or properties like sports
                                teams or company logos (e.g., NFL, Coca-Cola,
                                etc.) are allowed. More information is available
                                on the{" "}
                                <a
                                    href={current_url + "/support/faq"}
                                    target="_blank"
                                    className="underline c-light"
                                >
                                    FAQ page.
                                </a>
                            </p>
                        </div>
                    )}
                </div>
                <div className="row no_margin y-center gap-24">
                    <div
                        className={classes("conf_file_input_btn", {
                            enabled: this.state.accepted,
                            done: this.state.canPreviewFile && this.state.blob,
                        })}
                    >
                        {this.state.canPreviewFile && this.state.blob ? (
                            <>
                                <i
                                    dangerouslySetInnerHTML={{
                                        __html: feather.icons.check.toSvg(),
                                    }}
                                ></i>
                                <span className="h6 bold upcase">Done!</span>
                            </>
                        ) : (
                            <span className="regular h6 c-dgrey">Upload*</span>
                        )}

                        <input
                            type="file"
                            role="button"
                            aria-label="Upload a Custom Design"
                            onChange={this.onChange.bind(this)}
                        />
                    </div>
                    {this.state.file && (
                        <div className="conf_input_file_preview h11 regular c-grey">
                            <button
                                aria-label="Remove upladed design"
                                className="conf_input_remove"
                                onClick={() =>
                                    this.props.onFileRemove(this.state.file)
                                }
                            >
                                <i
                                    dangerouslySetInnerHTML={{
                                        __html: feather.icons.x.toSvg(),
                                    }}
                                ></i>
                            </button>
                            {this.state.canPreviewFile && this.state.blob ? (
                                <p className="c-darkgray italic">
                                    {this.state.file.name}
                                </p>
                            ) : (
                                <span>Preview Not Available</span>
                            )}
                        </div>
                    )}
                </div>
                <br />
                <div className="conf_design_disclaim">
                    <p className="italic h12 roman">
                        {" "}
                        {this.props.translations["cp_custom_design_disclaim"]}
                        <a
                            href={current_url + "/support/faq"}
                            target="_blank"
                            className="underline c-dark italic"
                        >
                            FAQ page
                        </a>
                    </p>
                </div>
            </div>
        );
    }
}
